import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import MUISnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

import styles from './styles';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const SnackbarContent = ({ classes, variant, message }) => {
    const Icon = variantIcon[variant];

    return (
        <MUISnackbarContent
            className={classes[variant]}
            message={
                <span className={classes.message}>
                    {variant !== 'default' ? <Icon className={classes.icon} /> : ''}
                    {message.includes('<br/>') ? <Trans>{message}</Trans> : message}
                </span>
            }
        />
    );
};

SnackbarContent.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    variant: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
};

export default withStyles(styles)(SnackbarContent);
