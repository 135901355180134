const utils = require('@edf-pkg/app-utils').default;

if (utils.platform.isWeb()) {
    // Web
    // eslint-disable-next-line global-require
    module.exports = require('./core/web');
} else if (utils.platform.isReactNative()) {
    // React-native code
} else {
    // TODO-MAYBE: Throw an error.
}
