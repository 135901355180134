import asSpace from '$app-web/hoces/as-space';

import WebActivitiesContainer from './containers/web-activities-container';
import * as webActivitiesStore from './store';
import webActivityHelper from './utils/web-activity-helper';

export const SPACE_ID = 'web-activities';

webActivitiesStore.inject();

export default asSpace(false)(WebActivitiesContainer);

export { webActivitiesStore, webActivityHelper };
