import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { withInitializer } from '@edf-pkg/app-main';
import utils from '@edf-pkg/app-utils';

import SplashContainer from '$app-web/containers/splash-container';

const AsSpaceHOC =
    (showSplash = true) =>
    (WrappedComponent) => {
        class AsSpace extends Component {
            static WrappedComponent = WrappedComponent;

            componentDidMount() {
                const { initialize } = this.props;
                initialize();
            }

            render() {
                const { initialized } = this.props;
                return (
                    <>
                        {showSplash && !initialized && <SplashContainer initializationStatusFailed={initialized} />}
                        {initialized && <WrappedComponent />}
                    </>
                );
            }
        }

        AsSpace.displayName = `AsSpace(${utils.component.getDisplayName(WrappedComponent)})`;
        AsSpace.propTypes = {
            initialized: PropTypes.bool.isRequired,
            initialize: PropTypes.func.isRequired,
        };

        return withRouter(withInitializer(hoistNonReactStatics(AsSpace, WrappedComponent)));
    };

export default AsSpaceHOC;
