import queryString from 'query-string';
import URLParse from 'url-parse';

import { URLsManagerError } from '@edf-pkg/app-error';
import appUtils from '@edf-pkg/app-utils';

class ServerURLsManager {
    constructor() {
        this.baseURL = null;
        this.endpoints = {};
        this.findServerBaseURL();
    }

    findServerBaseURL() {
        if (appUtils.platform.isWeb()) {
            if (appUtils.env.isDev()) {
                this.baseURL = process.env.REACT_APP_SERVER_BASE_URL;
            } else if (appUtils.env.shouldLoadBaseUrlFromLocalStorage() && localStorage.getItem('baseURL')) {
                this.baseURL = localStorage.getItem('baseURL');
            } else {
                const parsedURL = new URLParse(window.location);
                this.baseURL = parsedURL.origin;
            }
        } else {
            // TODO: This will be used for react-native base URL.
        }
    }

    addEndpoint(endpointId, relativeUrl) {
        this.endpoints[endpointId] = relativeUrl;
    }

    getEndpointURL(endpointId) {
        if (Object.keys(this.endpoints).indexOf(endpointId) >= 0) {
            return `${this.baseURL}${this.endpoints[endpointId]}`;
        }
        throw new URLsManagerError(`Endpoint doesn't exits. endpointId: ${endpointId}`);
    }

    getURLTo(relativePart, endpointId, queryParams) {
        const needInterpreting = appUtils.env.isDev() || !!endpointId;

        if (!needInterpreting) {
            // backend sends interpreted URL so no need to more inspection
            if (queryParams) {
                return queryString.stringifyUrl({
                    url: relativePart,
                    query: queryParams,
                });
            }
            return relativePart;
        }
        // the following interpretation is only for dev or when endpointId is specified
        if (relativePart.startsWith('/')) {
            return this.getURLTo(relativePart.slice(1), endpointId, queryParams);
        }
        const parsedUrl = new URLParse(relativePart, endpointId ? `${this.getEndpointURL(endpointId)}/` : this.baseURL, true);
        if (queryParams) {
            Object.assign(parsedUrl.query, queryParams);
        }
        return parsedUrl.toString();
    }

    // eslint-disable-next-line class-methods-use-this
    redirectTo(url) {
        setTimeout(() => {
            window.location.href = url;
        }, 200);
    }
}

const instance = new ServerURLsManager();
instance.getURLTo = instance.getURLTo.bind(instance);
export default instance;
