import { array, bool, number, object, string } from 'yup';

import { core as appClientCore } from '@edf-pkg/app-client';
import appUtils from '@edf-pkg/app-utils';

const responseSchema = array().of(
    object().shape({
        id: number(),
        label: string().required(),
        description: string(),
        icon: string().required(),
        is_cloud: bool().required(),
        name: string().required(),
        data_sources: array().of(
            object().shape({
                id: number().required(),
                label: string().required(),
                description: string(),
                icon: string().required(),
                has_visualization: bool().required(),
                has_dashboard_template: bool().required(),
                name: string().required(),
                export_formats: array().of(string()).required(),
                unit: string(),
                subsystem: string().required(),
            })
        ),
    })
);

export default async function readDataSourceOptionsEndpoint() {
    try {
        const response = await appClientCore.axiosClientsManager.getClient('api').get('/v1/data_source/options/');
        const validatedData = await responseSchema.validate(response.data, {
            stripUnknown: true,
        });
        return appUtils.object.camelCaseNonTranslationKeys(validatedData);
    } catch (error) {
        appClientCore.errorMapper(error);
    }
    return null;
}
