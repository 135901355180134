import URLParse from 'url-parse';

import { FatalError } from '@edf-pkg/app-error';

class PlatformHelper {
    PLATFORMS = {
        NOT_FOUND: 'NOT_FOUND',
        WEB: 'WEB',
        ANDROID: 'ANDROID',
        IOS: 'IOS',
    };

    platform = '';

    initialize = () => {
        this.findPlatform();
        if (!this.isPlatformFound()) {
            throw new FatalError('Cannot find platform.');
        }
    };

    findPlatform = () => {
        this.findPlatformFromInjectedFunctions();
        if (!this.isPlatformFound()) {
            this.findPlatformFromQueryParams();
        }
    };

    findPlatformFromInjectedFunctions() {
        // TODO: It's not working currently because of edWeb mocking.
        /* if (Object.keys(window.edWeb).length > 0) {
            this.platform = this.PLATFORMS.WEB;
        } else */ if (window.WebActivityRef && Object.keys(window.WebActivityRef).length > 0) {
            this.platform = this.PLATFORMS.ANDROID;
        } else if (window.webkit && window.webkit.messageHandlers && Object.keys(window.webkit.messageHandlers).length > 0) {
            this.platform = this.PLATFORMS.IOS;
        } else {
            this.platform = this.PLATFORMS.NOT_FOUND;
        }
    }

    findPlatformFromQueryParams() {
        const parsedURL = new URLParse(window.location.href, true);

        switch (parsedURL.query.platform) {
            case 'web':
                this.platform = this.PLATFORMS.WEB;
                break;
            case 'android':
                this.platform = this.PLATFORMS.ANDROID;
                break;
            case 'ios':
                this.platform = this.PLATFORMS.IOS;
                break;
            default:
                this.platform = this.PLATFORMS.NOT_FOUND;
                break;
        }
    }

    isPlatformFound = () => {
        return this.platform && this.platform !== this.PLATFORMS.NOT_FOUND;
    };

    isPlatformWeb = () => {
        return this.platform && this.platform === this.PLATFORMS.WEB;
    };

    isPlatformAndroid = () => {
        return this.platform && this.platform === this.PLATFORMS.ANDROID;
    };

    isPlatformIos = () => {
        return this.platform && this.platform === this.PLATFORMS.IOS;
    };
}

const instance = new PlatformHelper();
export default instance;
