import { FatalError } from '@edf-pkg/app-error';

import NativeToJsFunctionHelperAndroid from './native-to-js-function-helper.android';
import NativeToJsFunctionHelperIos from './native-to-js-function-helper.ios';
import NativeToJsFunctionHelperWeb from './native-to-js-function-helper.web';
import platformHelper from './platform-helper';

class NativeToJsHelper {
    NEEDED_INJECTED_FUNCTION_NAMES = [
        'getCurrentUserCredentials',
        'back',
        'exit',
        'getOptions',
        'getSessionInfo',
        'onLoadingCompleted',
        'onLoadingFailed',
        // 'adjustSessionExpiryTime',
        // 'modifyNotifications',
    ];

    injectedBaseObject = null;

    functionsHelper = null;

    initialize = () => {
        if (!platformHelper.isPlatformIos()) {
            this.getInjectedBaseObject();
            this.validateBaseObject();
            this.checkNeededFunctionsInjected();
        }
        this.createFunctionsHelperForPlatform();
    };

    getInjectedBaseObject = () => {
        if (platformHelper.isPlatformWeb()) {
            if (window.edWeb) {
                this.injectedBaseObject = window.edWeb;
            }
        } else if (platformHelper.isPlatformAndroid()) {
            this.injectedBaseObject = window.WebActivityRef;
        }
    };

    validateBaseObject = () => {
        if (!(this.injectedBaseObject && Object.keys(this.injectedBaseObject).length > 0)) {
            throw new FatalError('The injectedBaseObject is not valid.', this.injectedBaseObject);
        }
    };

    checkNeededFunctionsInjected = () => {
        const neededFunctionsInjected = this.NEEDED_INJECTED_FUNCTION_NAMES.reduce(
            (allNeededFunctionsInjectedUntilNow, injectedFunctionName) => {
                if (allNeededFunctionsInjectedUntilNow) {
                    return Object.prototype.hasOwnProperty.call(this.injectedBaseObject, injectedFunctionName);
                }
                return allNeededFunctionsInjectedUntilNow;
            },
            true
        );
        if (!neededFunctionsInjected) {
            throw new FatalError('Needed functions to be injected not found');
        }
    };

    createFunctionsHelperForPlatform = () => {
        if (platformHelper.isPlatformWeb()) {
            this.functionsHelper = new NativeToJsFunctionHelperWeb();
        } else if (platformHelper.isPlatformAndroid()) {
            this.functionsHelper = new NativeToJsFunctionHelperAndroid();
        } else if (platformHelper.isPlatformIos()) {
            this.functionsHelper = new NativeToJsFunctionHelperIos();
        }
    };
}

const instance = new NativeToJsHelper();
export default instance;
