import * as appMain from '@edf-pkg/app-main';
import appURLsManager from '@edf-pkg/app-urls-manager';
import appUtils from '@edf-pkg/app-utils';

import axiosClientsManager from './axios-clients-manager';

const defaultAxiosClients = {
    api: { baseURL: appURLsManager.server.getEndpointURL('api') },
    media: { baseURL: appURLsManager.server.getEndpointURL('media') },
    gql: { baseURL: appURLsManager.server.getEndpointURL('graphql') },
    file: { baseURL: appURLsManager.fileServer.getBaseURL() },
};

Object.keys(defaultAxiosClients).forEach((axiosClientId) => {
    axiosClientsManager.createClient(axiosClientId, defaultAxiosClients[axiosClientId]).interceptors.request.use(
        (config) => {
            const finalConfig = { ...config };
            if (
                !Object.keys(config.headers)
                    .map((key) => key.toLowerCase())
                    .includes('authorization') &&
                !appUtils.object.hasKey(config, 'auth')
            ) {
                const userAuthData = appMain.utils.user.getAuthData();
                if (userAuthData.username && userAuthData.apiKey) {
                    finalConfig.headers.authorization = `ApiKey ${userAuthData.username}:${userAuthData.apiKey}`;
                }
            }
            // TODO-Maybe: Remove the `app` from the location.pathname
            finalConfig.headers['x-ethica-path'] = window.location.pathname;
            return finalConfig;
        },
        (error) => Promise.reject(error)
    );
});
