class JsToNativeHelper {
    NEEDED_JS_TO_NATIVE_FUNCTION_NAMES = ['onBackPressed', 'onUrlChanged'];

    initialize = () => {
        this.registerFunctionsToWindow();
    };

    functionHandlers = {};

    registerFunctionsToWindow = () => {
        this.NEEDED_JS_TO_NATIVE_FUNCTION_NAMES.forEach((functionName) => {
            window[functionName] = (...args) => this.callFunctionHandler(functionName, ...args);
        });
    };

    callFunctionHandler = (functionName, ...args) => {
        if (Object.prototype.hasOwnProperty.call(this.functionHandlers, functionName)) {
            this.functionHandlers[functionName](...args);
        }
    };

    registerFunctionHandler = (functionName, func) => {
        if (this.NEEDED_JS_TO_NATIVE_FUNCTION_NAMES.indexOf(functionName) >= 0) {
            this.functionHandlers[functionName] = func;
        }
    };
}

const instance = new JsToNativeHelper();
export default instance;
