import { END_OF_TIME_MS } from '$app-web/constants';

export const sessionInfo = {
    activity_id: 4574,
    asa24_credential: {
        password: 'jane!774',
        username: 'YcrDem02822',
    },
    device_id: 'W_DESKTOP_CHROME',
    duration: null,
    eating_occasion_count: null,
    expiry_time_ms: END_OF_TIME_MS,
    in_progress_info: {},
    logged_in_once: true,
    p_time_ms: 0,
    r_time_ms: 0,
    recall_count: 0,
    s_time_ms: 1598974184036,
    session_uuid: '650285cd-c5b6-44e9-9654-d856390cb9dc',
    status_id: 3,
    study_id: 874,
    tl_id: 0,
    tl_session_index: -2,
    tl_type_id: 4,
    user_id: 11890,
    uuid: '650285cd-c5b6-44e9-9654-d856390cb9dc',
    version: 0,
};

export const options = {
    button_caption: null,
    button_icon: null,
    capture_location: false,
    criteria: null,
    desc: 'For testing purposes only.',
    end_time_ms: 1690689600000,
    endpoint: 'app/asa24/',
    expiry_duration_ms: 0,
    expiry_time_ms: 0,
    id: 4574,
    is_web_activity: true,
    max_login: 2,
    max_recalls: 3,
    name: 'Nutrition Diary - TEST',
    notification_templates: [],
    notifications: [],
    number_of_consecutive_days: 0,
    number_of_records: 0,
    published: true,
    session_type_id: 1,
    start_time_ms: 1564459200000,
    status_id: 1,
    study_id: 874,
    time_to_complete_recall_id: 2,
    timeframe_id: 1,
    tool_id: 2,
    triggering_logics: [
        {
            id: 0,
            trigger_title: 'ASA24 - TEST',
            type: 'user',
        },
    ],
    type: 'asa24',
    type_id: 2,
    version: 0,
};
