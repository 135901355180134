import { getStore } from '@edf-pkg/app-store';

import { userDuck } from '../store';

export function getData() {
    const state = getStore().getState();
    return userDuck.duckSelectors.userSelector(state);
}

export function getAuthData() {
    const userData = getData();
    return {
        username: userData.username,
        apiKey: userData.apiKey,
    };
}

export function getDeviceId() {
    const userData = getData();
    return userData.deviceId;
}
