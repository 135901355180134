import { call, put, take } from 'redux-saga/effects';

import { errorHandle } from '@edf-pkg/app-error';
import { store as i18nStore } from '@edf-pkg/app-i18n';
import { store as mainAppStore } from '@edf-pkg/app-main';
import appUtils from '@edf-pkg/app-utils';

import URLsManager from '$app-web/utils/urls-manager';

export const SAGA_NAME = 'SPACE_PUBLIC';

function* initialize() {
    if (URLsManager.space('public').inSpace()) {
        try {
            const parsedURL = URLsManager.getParsedURL();
            let language = 'en-US';
            if (appUtils.object.hasKey(parsedURL.query, 'lang')) {
                language = parsedURL.query.lang;
            }
            yield put(
                mainAppStore.userDuck.duckActionCreators.loadUserDataFromSpaceSucceeded({
                    username: 'anonymous',
                    apiKey: 'anonymous',
                    role: 'participant',
                    language,
                })
            );
            yield take(i18nStore.i18nDuck.duckActions.ASK_SPACE_FOR_LANGUAGE_NAMESPACE);
            yield put(i18nStore.i18nDuck.duckActionCreators.askSpaceForLanguageNamespaceSucceeded('public'));
        } catch (error) {
            errorHandle.anError(error);
            yield put(mainAppStore.userDuck.duckActionCreators.loadUserDataFromSpaceFailed());
        }
    }
}

export default function* publicSaga() {
    yield take(mainAppStore.userDuck.duckActions.ASK_SPACE_TO_LOAD_USER_DATA);
    yield call(initialize);
}
