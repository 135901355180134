import camelcaseKeys from 'camelcase-keys';
import { isObject } from 'lodash-es';
import { ValidationError as ValidationErrorYup } from 'yup';

import {
    APIError,
    APIMappedError,
    APIUnauthorizedError,
    ConnectionError,
    UnknownError,
    ValidationError,
} from '@edf-pkg/app-error';
import { t } from '@edf-pkg/app-i18n';

export default function errorMapper(error, customErrorsOrMapper) {
    if (error instanceof ValidationErrorYup) {
        throw new ValidationError(error.message, error);
    }

    if (error.response) {
        // eslint-disable-next-line no-param-reassign
        error.response.data = camelcaseKeys(error.response.data, { deep: true });

        if (customErrorsOrMapper && typeof customErrorsOrMapper === 'function') {
            customErrorsOrMapper(t, error);
        } else if (customErrorsOrMapper && isObject(customErrorsOrMapper) && error.response.data.error) {
            if (customErrorsOrMapper[error.response.data.errorCode]) {
                throw new APIMappedError(
                    customErrorsOrMapper[error.response.data.errorCode](t, error.response.data),
                    error.response.data.errorCode,
                    error.response.status,
                    error.response.data.errorName,
                    error.response.statusText,
                    error.response,
                    error
                );
            }
        }
        switch (error.response.status) {
            case 400:
            case 409:
                if (error.response.data.error) {
                    throw new APIError(
                        error.response.data.errorMessage,
                        error.response.data.errorCode,
                        error.response.status,
                        400,
                        error.response.statusText,
                        error.response,
                        error
                    );
                }
                throw new APIError(
                    'Bad request.',
                    error.response.data.errorCode,
                    error.response.status,
                    400,
                    error.response.statusText,
                    error.response,
                    error
                );
            case 401:
                throw new APIUnauthorizedError(
                    error.response.data.errorMessage,
                    error.response.data.errorCode,
                    401,
                    error.response.data.errorName,
                    error.response.statusText,
                    error.response,
                    error
                );
            case 404:
                throw new APIError(
                    'Not found.',
                    'notFound',
                    error.response.status,
                    404,
                    error.response.statusText,
                    error.response,
                    error
                );
            case 500:
            case 502:
                throw new APIError(
                    'Internal Server Error.',
                    'internalServerError',
                    error.response.status,
                    error.response.data,
                    error.response.statusText,
                    error.response,
                    error
                );
            default:
                throw new APIError(
                    'Unmapped API Error.',
                    'unmappedAPIError',
                    error.response.status,
                    '',
                    error.response.statusText,
                    error.response,
                    error
                );
        }
    } else if (error.request) {
        throw new ConnectionError(t('error_no_internet'), error);
    } else {
        throw new UnknownError(t('generic_error_msg'), error);
    }
}
