import { array, bool, mixed, number, object, string } from 'yup';

import { core as appClientCore } from '@edf-pkg/app-client';
import appDateTime from '@edf-pkg/app-date-time';
import appUtils from '@edf-pkg/app-utils';

const requestSchema = object().shape({
    device_id: string().required(),
    manufacturer: string().ensure(),
    model: string().ensure(),
    gcm_reg_id: string().ensure(),
    olson_tz_id: string().ensure(),
});

const responseSchema = object().shape({
    username: string().required(),
    api_key: string().required(),
    role: string().required(),
    id: number().required(),
    avatar: string().ensure(),
    first_name: string().ensure(),
    last_name: string().ensure(),
    language: string().ensure(),
    org_name: string().ensure(),
    gender_id: number().required(),
    age_id: number().required(),
    education_id: number().required(),
    marital_status_id: number().required(),
    occupation_id: number().required(),
    salary_id: number().required(),
    is_email_verified: bool().default(false),
    is_phone_verified: bool().default(false),
    mfa_enabled: bool().default(false),
    phone: string().ensure(),
    matrix_password: string().ensure(),
    subscription_configs: mixed().required(),
    preferred_notification_medium_ids: array().of(number()).required(),
});

export default async function userReadEndpoint(deviceId) {
    try {
        const params = await requestSchema.validate(
            {
                ...{ device_id: deviceId },
                ...{ olson_tz_id: appDateTime.timezone.getCurrent() },
            },
            {
                stripUnknown: true,
            }
        );
        const response = await appClientCore.axiosClientsManager.getClient('api').get('/study/user/', {
            params,
        });
        const validatedData = await responseSchema.validate(response.data, {
            stripUnknown: true,
        });
        return appUtils.object.camelCaseNonTranslationKeys(validatedData);
    } catch (error) {
        appClientCore.errorMapper(error);
    }
    return null;
}
