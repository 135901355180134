import { combineTrackReducers } from 'retrack';

import { store as i18nStore } from '@edf-pkg/app-i18n';
import { ducksManager, sagasManager } from '@edf-pkg/app-store';

import appReducer, * as appDuck from './app.duck';
import appSaga, { SAGA_NAME as appSagaName } from './app.saga';
import dataSourceOptionsReducer, * as dataSourceOptionsDuck from './data-source-options.duck';
import dataSourceOptionsSaga, { SAGA_NAME as dataSourceOptionsSagaName } from './data-source-options.saga';
import dialogReducer, * as dialogDuck from './dialog.duck';
import dialogSaga, { SAGA_NAME as dialogSagaName } from './dialog.saga';
import snackbarReducer, * as snackbarDuck from './snackbar.duck';
import snackbarSaga, { SAGA_NAME as snackbarSagaName } from './snackbar.saga';
// TODO-MAYBE: Move user into app-user package.
import userReducer, * as userDuck from './user.duck';
import userSaga, { SAGA_NAME as userSagaName } from './user.saga';

export function inject() {
    const i18nStoreAsObject = i18nStore.getStoreAsObject();
    ducksManager.add(
        'main',
        combineTrackReducers({
            [appDuck.DUCK_NAME]: appReducer,
            [snackbarDuck.DUCK_NAME]: snackbarReducer,
            [userDuck.DUCK_NAME]: userReducer,
            [i18nStoreAsObject.duck.DUCK_NAME]: i18nStoreAsObject.reducer,
            [dialogDuck.DUCK_NAME]: dialogReducer,
            [dataSourceOptionsDuck.DUCK_NAME]: dataSourceOptionsReducer,
        })
    );
    sagasManager.add(appSagaName, appSaga);
    sagasManager.add(userSagaName, userSaga);
    sagasManager.add(snackbarSagaName, snackbarSaga);
    sagasManager.add(dialogSagaName, dialogSaga);
    sagasManager.add(dataSourceOptionsSagaName, dataSourceOptionsSaga);
    sagasManager.add(i18nStoreAsObject.SAGA_NAME, i18nStoreAsObject.saga);
}

export { appDuck, snackbarDuck, userDuck, dialogDuck, dataSourceOptionsDuck };
