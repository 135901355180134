import { core as appClientCore } from '@edf-pkg/app-client';

export default async function userLogoutEndpoint() {
    try {
        await appClientCore.axiosClientsManager.getClient('api').post('/study/user/logout/');
        return true;
    } catch (error) {
        appClientCore.errorMapper(error);
    }
    return null;
}
