import { NOTIFICATION_EVENT_IDS } from '$app-web/modules/rdash/constants';

export const VERIFICATION_CODE_LENGTH = 6;

export const FILE_INPUT_MAX_SIZE = 200 * 1024 * 1024; // 200MB

export const STUDY_DEFAULT_BACKGROUND_IMAGE = '/media/study_bg/default_bg.jpg';

// TODO-MAYBE: Move this enum to the DropZone component when all the file inputs replaced with that component.
export const FILE_INPUT_ACCEPTED_MIME_TYPES_AND_EXTENSIONS = {
    IMAGE: {
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/png': ['.png'],
        'image/webp': ['.webp'],
    },
    AUDIO: {
        'audio/mpeg': ['.mp3'],
    },
    VIDEO: {
        'video/mp4': ['.mp4'],
    },
    JSON: {
        'application/json': ['.json'],
    },
    CSV: {
        'text/csv': ['.csv'],
        'application/vnd.ms-excel': ['.csv'],
    },
};

// https://en.wikipedia.org/wiki/List_of_file_signatures
// https://mimesniff.spec.whatwg.org/#matching-an-audio-or-video-type-pattern
export const TYPES_SIGNATURES = {
    'image/jpeg': 'FFD8FF',
    'image/png': '89504E',
    'image/webp': '524946',
    'audio/mpeg': '494433',
};

export const TRANSLATION_BASE_ID = 'base';

export const SURVEY_QUESTION_CONTENT_NON_QUESTION_BASED_PLACEHOLDERS = {
    USER_NAME: '{{user_name}}',
    USER_FIRST_NAME: '{{user_fname}}',
    USER_LAST_NAME: '{{user_lname}}',
    LOOP_VALUE: '{{loop_value}}',
};
export const SURVEY_QUESTION_CONTENT_QUESTION_BASED_PLACEHOLDER_REGEX = /{{Q(\d+)(_(\d+))?}}/i;
export const SURVEY_QUESTION_CONTENT_PLACEHOLDER_REGEX = new RegExp(
    `${Object.values(SURVEY_QUESTION_CONTENT_NON_QUESTION_BASED_PLACEHOLDERS).join('|')}|${
        SURVEY_QUESTION_CONTENT_QUESTION_BASED_PLACEHOLDER_REGEX.source
    }`,
    'gi'
);

export const EMAIL_REGEX =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const URL_REGEX =
    // eslint-disable-next-line no-useless-escape
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
export const PHONE_NUMBER_REGEX =
    /^\+?(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;
export const NUMBER_REGEX = /^\d+(.\d+)?$/;

// STUDY-SPECIFIC-CODE: 805, 874
export const EDC_STUDY_IDS = [805, 874];

// STUDY-SPECIFIC-CODE: 1209 (ACT)
export const ACT_HEALTH_ACTIVITY_IDS = [
    12588, 12584, 12589, 12590, 12562, 12573, 12587, 12572, 12563, 12564, 12565, 12566, 12567, 12568, 12569, 12570, 12571, 12582,
    12576, 12574, 12575, 12583, 12577, 12578, 12579, 12580, 12581, 12594, 12593,
];

export const END_OF_TIME_MS = 31570041600000; // 2970-06-01 00:00:00 UTC

export const SPECIAL_STUDY_IDS = {
    GO_EXCAP: 1404,
    MAAT_GE: 2315,
};

export const STUDY_IDS_WITH_RDASH_SUMMARY_TABLES = [SPECIAL_STUDY_IDS.GO_EXCAP, SPECIAL_STUDY_IDS.MAAT_GE];

export const REACT_QUERY_STATUS_CODES = {
    LOADING: 'loading',
    SUCCESS: 'success',
    ERROR: 'error',
    IDLE: 'idle',
};

export const DIALOG_IDS = {
    ACCOUNT_DELETION_INITIATED: 'ACCOUNT_DELETION_INITIATED',
    ACCOUNT_DELETION_REVOKED: 'ACCOUNT_DELETION_REVOKED',
    SETTINGS_VERIFY_EMAIL: 'settings_verify_email',
    SETTINGS_CHANGE_EMAIL: 'settings_change_email',
    SETTINGS_VERIFY_PHONE_NUMBER: 'settings_verify_phone_number',
    SETTINGS_ADD_CHANGE_PHONE_NUMBER: 'settings_add_change_phone_number',
    VERIFY_PASSWORD: 'verify_password',
};

export const MATRIX_REASONING_TIMED_OUT_ROUND_BEHAVIOR_ENUM = {
    INCORRECT: 'incorrect',
    NOT_ANSWERED: 'not_answered',
};

export const MATRIX_REASONING_SKIP_ROUND_BEHAVIOR_ENUM = {
    DISABLED: 'disabled',
    INCORRECT: 'incorrect',
    NOT_ANSWERED: 'not_answered',
};

export const MATRIX_REASONING_QUESTION_INCLUSION = {
    MAIN: 'main',
    PRACTICE: 'practice',
    NOT_INCLUDED: 'not_included',
};

export const CONSENT_TYPES = {
    PARENTS: 1,
    PARENTS_OF_MINOR: 2,
    MINOR_CHILD: 3,
    ADULT_CHILD: 4,
};

export const FAMILY_MEMBER_TYPES = {
    CHILD: 1,
    MOTHER: 2,
    FATHER: 3,
};

export const NOTIFICATION_EVENT_LABELS = {
    [NOTIFICATION_EVENT_IDS.ON_DEMAND]: (t) => t('rdash:notification_event_on_demand'),
    [NOTIFICATION_EVENT_IDS.REQUEST_LICENSE_UPGRADE]: (t) => t('rdash:notification_event_request_license_upgrade'),
    [NOTIFICATION_EVENT_IDS.TRIAL_STUDY_WIPE_NOTIF_3_DAYS]: (t) =>
        t('rdash:notification_event_trial_study_wipe_notif_three_days'),
    [NOTIFICATION_EVENT_IDS.TRIAL_STUDY_WIPE_NOTIF_1_DAY]: (t) => t('rdash:notification_event_trial_study_wipe_notif_one_day'),
    [NOTIFICATION_EVENT_IDS.TRIAL_STUDY_WIPE_NOTIF_DONE]: (t) => t('rdash:notification_event_trial_study_wipe_notif_done'),
    [NOTIFICATION_EVENT_IDS.TRIAL_STUDY_RM_NOTIF_2_WEEKS]: (t) =>
        t('rdash:notification_event_trial_study_remove_notif_two_weeks'),
    [NOTIFICATION_EVENT_IDS.TRIAL_STUDY_RM_NOTIF_1_WEEK]: (t) => t('rdash:notification_event_trial_study_remove_notif_one_week'),
    [NOTIFICATION_EVENT_IDS.TRIAL_STUDY_RM_NOTIF_1_DAY]: (t) => t('rdash:notification_event_trial_study_remove_notif_one_day'),
    [NOTIFICATION_EVENT_IDS.TRIAL_STUDY_RM_NOTIF_DONE]: (t) => t('rdash:notification_event_trial_study_remove_notif_done'),
    [NOTIFICATION_EVENT_IDS.STUDY_INVITATION]: (t) => t('rdash:notification_event_study_invitation'),
    [NOTIFICATION_EVENT_IDS.PARTICIPANT_JOINED]: (t) => t('rdash:notification_event_participant_joined'),
    [NOTIFICATION_EVENT_IDS.EXPORT_READY]: (t) => t('rdash:notification_event_export_ready'),
    [NOTIFICATION_EVENT_IDS.SESSION_RELEASED]: (t) => t('rdash:notification_event_session_released'),
    [NOTIFICATION_EVENT_IDS.SESSION_COMPLETED]: (t) => t('rdash:notification_event_session_completed'),
    [NOTIFICATION_EVENT_IDS.SESSION_CANCELED]: (t) => t('rdash:notification_event_session_canceled'),
    [NOTIFICATION_EVENT_IDS.SESSION_EXPIRED]: (t) => t('rdash:notification_event_session_expired'),
    [NOTIFICATION_EVENT_IDS.ACCOUNT_DELETION_INITIATED]: (t) => t('rdash:notification_event_account_deletion_initiated'),
    [NOTIFICATION_EVENT_IDS.ACCOUNT_DELETION_REMINDER]: (t) => t('rdash:notification_event_account_deletion_reminder'),
    [NOTIFICATION_EVENT_IDS.ACCOUNT_DELETION_REVOKED]: (t) => t('rdash:notification_event_account_deletion_revoked'),
    [NOTIFICATION_EVENT_IDS.ACCOUNT_DELETION_SUCCEEDED]: (t) => t('rdash:notification_event_account_deletion_succeeded'),
};

export const NOTIFICATION_MEDIUM_IDS = {
    SMS: 0,
    EMAIL: 1,
    IN_APP: 2,
};

export const NOTIFICATION_MEDIUM_IDS_TO_LABELS = {
    [NOTIFICATION_MEDIUM_IDS.SMS]: (t) => t('pdash:sms'),
    [NOTIFICATION_MEDIUM_IDS.IN_APP]: (t) => t('pdash:in_app'),
    [NOTIFICATION_MEDIUM_IDS.EMAIL]: (t) => t('pdash:email'),
};

export const ON_PREMISE_INSTANCES = {
    EDEN: 'eden',
    DGC: 'dgc',
    ACT: 'acthealth',
    MNS: 'mns',
};
