import URLParse from 'url-parse';

import { FatalError } from '@edf-pkg/app-error';
import appUtils from '@edf-pkg/app-utils';

import ModuleURLsManager from './module-urls-manager';

function SpaceURLsManager(spaceId) {
    this.spaceId = spaceId;
    this.modules = {};
}

SpaceURLsManager.prototype.inSpace = function inSpace() {
    const parsedPath = new URLParse(window.location);
    return parsedPath.pathname.includes(this.spaceId);
};

SpaceURLsManager.prototype.addModule = function addModule(moduleId) {
    if (!appUtils.object.hasKey(this.modules, moduleId)) {
        this.modules[moduleId] = new ModuleURLsManager(this.spaceId, moduleId);
    }
    return this.modules[moduleId];
};

SpaceURLsManager.prototype.module = function module(moduleId) {
    if (!appUtils.object.hasKey(this.modules, moduleId)) {
        this.addModule(moduleId);
    }
    return this.modules[moduleId];
};

SpaceURLsManager.prototype.hasModule = function module(moduleId) {
    return appUtils.object.hasKey(this.modules, moduleId);
};

SpaceURLsManager.prototype.getCurrentModuleId = function getCurrentModuleId() {
    const parsedPath = new URLParse(window.location);
    const splitPathname = parsedPath.pathname.split('/');
    let moduleId = '';
    for (let pathPartIndex = 0; pathPartIndex < splitPathname.length; pathPartIndex += 1) {
        if (splitPathname[pathPartIndex] === this.spaceId) {
            moduleId = splitPathname[pathPartIndex + 1];
            break;
        }
    }
    if (Object.keys(this.modules).indexOf(moduleId) >= 0) {
        return moduleId;
    }
    throw new FatalError(`Trying to get moduleId of unknown space module. spaceId: ${this.spaceId} - moduleId: ${moduleId}`);
};

export default SpaceURLsManager;
