const ERROR_CODES = {
    INSUFFICIENT_PERMISSIONS: 2,
    INVALID_PARAMETER: 4,
    UNAUTHORIZED_OR_INACTIVE: 6,
    INTERNAL_ERROR: 7,
    INVALID_TOKEN: 257,
    USER_EXIST: 261,
    UNSUPPORTED_PHONE_REGION_FOR_SMS: 262,
    USER_UPDATE_PASSWORD_INCORRECT_CURRENT_PASSWORD: 268,
    CANNOT_SEND_SMS: 270,
    USER_PREV_REG_EXPIRED: 518,
    ADD_RESEARCHER_ALREADY_MEMBER: 1026,
    ADD_RESEARCHER_USER_NOT_FOUND: 1027,
    ADD_RESEARCHER_USER_NOT_RESEARCHER: 1028,
    ADD_RESEARCHER_USER_EMAIL_ADDRESS_NOT_VERIFIED: 1054,
    STUDY_PARTICIPATION_ENDED: 1035,
    STUDY_REG_ALREADY_REGISTERED: 1041,
    STUDY_REG_CLOSED: 1042,
    STUDY_REG_NOT_INVITED: 1044,
    LICENSE_NOT_TRIAL: 1055,
    NO_ACTIVE_GCM_USER: 1289,
    CORRUPT_SURVEY: 1296,
    BEACON_EXIST: 1796,
    BEACON_DUPLICATE_VALUE: 1803,
    ACTIVITY_CANNOT_MOVE_EXISTING_SESSIONS: 2050,
};

export default ERROR_CODES;
