import Bowser from 'bowser';

import utils from '@edf-pkg/app-utils';

import deviceInfo from './device-info';

let theDeviceInfo = null;

function createDeviceInfo() {
    theDeviceInfo = { ...deviceInfo };
    const userAgentBasedData = Bowser.getParser(window.navigator.userAgent);
    theDeviceInfo.device_id = `W_${userAgentBasedData.getPlatform().type.toUpperCase()}_${userAgentBasedData
        .getBrowserName()
        .toUpperCase()}`;
    theDeviceInfo.manufacturer = `${userAgentBasedData.getBrowserName()} for ${utils.string.capitalizeFirstChar(
        userAgentBasedData.getPlatform().type
    )}`;
    theDeviceInfo.model = window.navigator.userAgent;
    theDeviceInfo.os = `${userAgentBasedData.getOSName()} ${userAgentBasedData.getOSVersion()}`;
}

export default function web() {
    if (theDeviceInfo === null) {
        createDeviceInfo();
    }
    return theDeviceInfo;
}
