export const sessionInfo = {
    study_id: 1091,
    uuid: '40119e5a-2a5b-4819-930b-0b4307cc3172',
    activity_id: 6965,
    s_time_ms: 1597362630498,
    p_time_ms: 1597362630498,
    tl_id: 0,
    tl_type_id: 4,
};

export const options = {
    audio_feedback: false,
    button_caption: 'Stroop Test',
    button_icon: '',
    color_codes: [1, 3, 4, 5],
    congruent_round_count: 8,
    duration: 24,
    duration_type_id: 2,
    endpoint: 'app/stroop/',
    expiry_time_ms: 0,
    id: 6965,
    incongruent_round_count: 8,
    intro_text: 'This is the introduction content. Play a game.',
    is_web_activity: true,
    neutral_round_count: 8,
    neutral_text: '#####',
    notifications: [],
    practice_round_count: 6,
    pre_game_message: 'This is the message shown at the beginning of the game. Lets play the game. Ready?',
    published: true,
    selector_id: 2,
    session_type_id: 1,
    sessions: [],
    show_rounds: true,
    show_score: false,
    show_timer: false,
    status_id: 1,
    visual_feedback: true,
    version: 1,
};
