import URLParse from 'url-parse';

import { EDFError, FatalError } from '@edf-pkg/app-error';
import appUtils from '@edf-pkg/app-utils';

import SpaceURLsManager from '$app-web/utils/space-urls-manager';

function URLsManager() {
    this.baseURL = process.env.PUBLIC_URL || '';
    this.currentSpaceId = '';
    this.spaces = {};
}

URLsManager.prototype.getBaseURL = function getBaseURL() {
    return this.baseURL;
};

URLsManager.prototype.addSpace = function addSpace(spaceId) {
    if (!appUtils.object.hasKey(this.spaces, spaceId)) {
        this.spaces[spaceId] = new SpaceURLsManager(spaceId);
    } else {
        throw new FatalError(`Trying to add space which exists.`, new EDFError(`Space with Id: ${spaceId} already exists.`));
    }
};

URLsManager.prototype.space = function space(spaceId) {
    if (!appUtils.object.hasKey(this.spaces, spaceId)) {
        this.addSpace(spaceId);
    }
    return this.spaces[spaceId];
};

URLsManager.prototype.getParsedURL = function getParsedURL() {
    return new URLParse(window.location.href, true);
};

URLsManager.prototype.to = function to(path, params = {}, includeModulePath) {
    const splitPath = path.split(':');
    const spaceId = splitPath[0];
    let moduleId = splitPath[1];
    const pathId = splitPath[2];
    if (splitPath.length !== 3) {
        throw new FatalError(
            `Trying to get url with bad path. Path should have spaceId, moduleId and pathId. Given Path: ${path}`
        );
    }
    if (!appUtils.object.hasKey(this.spaces, spaceId)) {
        throw new FatalError(`Trying to get missing space.`, new EDFError(`Space with Id: ${spaceId} is missing.`));
    }
    const space = this.spaces[spaceId];

    let subModuleId;
    if (moduleId.includes('.')) {
        [moduleId, subModuleId] = moduleId.split('.');
    }

    if (!space.hasModule(moduleId)) {
        throw new FatalError(`Trying to get missing module.`, new EDFError(`Module with Id: ${moduleId} is missing.`));
    }

    return space.module(moduleId).to(pathId, params, includeModulePath, subModuleId);
};

URLsManager.prototype.replaceParamsInLink = function replaceParamsInLink(URL, params) {
    return Object.keys(params).reduce((finalURL, paramKey) => finalURL.replace(`:${paramKey}`, params[paramKey]), URL);
};

const instance = new URLsManager();
export default instance;
