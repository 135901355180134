// Utils of yup. The transforms can also be exported from here.

import googleLibPhoneNumber from 'google-libphonenumber';
import { addMethod, mixed, number, object, string } from 'yup';

import appDateTime from '@edf-pkg/app-date-time';
import { t } from '@edf-pkg/app-i18n';

const phoneNumberUtil = googleLibPhoneNumber.PhoneNumberUtil.getInstance();

export default function initializeYup() {
    addMethod(number, 'gqlId', function gqlId() {
        return this.transform(function transformer(value, originalValue) {
            if (this.isType(value)) {
                return value;
            }
            return parseInt(originalValue.replace('A_', ''), 10);
        });
    });

    addMethod(string, 'phoneNumber', function phoneNumber(message) {
        return this.test('phoneNumber', message, function testFunc(value) {
            let phone = null;
            try {
                phone = phoneNumberUtil.parse(value);
            } catch (error) {
                return false;
            }
            return phoneNumberUtil.isValidNumber(phone);
        });
    });

    // TODO: I think we should merge this with the previous method somehow. It's a quick implementation.
    addMethod(string, 'nullablePhoneNumber', function nullablePhoneNumber(message) {
        return this.test('nullablePhoneNumber', message, function testFunc(value) {
            if (value === null || value === undefined || value === '') {
                return true;
            }
            let phone = null;
            try {
                phone = phoneNumberUtil.parse(value);
            } catch (error) {
                return false;
            }
            return phoneNumberUtil.isValidNumber(phone);
        });
    });

    addMethod(mixed, 'dateTime', function dateTime(message) {
        return this.test('dateTime', message, function testFunc(value) {
            return appDateTime(value).isValid();
        });
    });

    // TODO-MAYBE: The following method (dateTimeNullable()) is a workaround for nullable `dateTime()`.
    // TODO-MAYBE: It should be removed when we further investigate on how to chain `nullable()` to `dateTime()` method and it's possible.
    addMethod(mixed, 'dateTimeNullable', function dateTime(message) {
        return this.test('dateTimeNullable', message, function testFunc(value) {
            if (value === null) {
                return true;
            }
            return appDateTime(value).isValid();
        });
    });

    addMethod(string, 'newPassword', function newPassword() {
        return this.test('newPassword', '', function testFunc(value) {
            const { path, createError } = this;
            if (!value || value.length < 8) {
                return createError({ path, message: () => t('signup_validation_err_invalid_password') });
            }
            if (!value.match(/^(?=.*[0-9])(?=.*[a-zA-Z])(.+)$/)) {
                return createError({ path, message: () => t('password_value_error_no_digit_or_letter') });
            }
            return true;
        });
    });

    addMethod(string, 'newPasswordConfirmation', function newPasswordConfirmation(newPasswordFieldName) {
        return this.test('newPasswordConfirmation', '', function testFunc(value, context) {
            const { path, createError } = this;
            const newPasswordFieldValue = context?.parent?.[newPasswordFieldName];
            if (newPasswordFieldValue && newPasswordFieldValue.length > 0) {
                if (!value || value !== newPasswordFieldValue) {
                    return createError({ path, message: () => t('signup_validation_err_invalid_confirm_password') });
                }
            }
            return true;
        });
    });

    addMethod(string, 'password', function password() {
        return this.test('password', '', function testFunc(value) {
            const { path, createError } = this;
            if (!value) {
                return createError({ path, message: () => t('password_value_error_empty') });
            }
            return true;
        });
    });

    addMethod(mixed, 'contentTranslation', function contentTranslation() {
        return this.test('contentTranslation', '', function testFunc(contentByLanguage) {
            const schema = contentByLanguage
                ? object().shape(
                      Object.fromEntries(Object.keys(contentByLanguage).map((language) => [language, string().ensure()]))
                  )
                : mixed().default(undefined);
            return schema.isValid(contentByLanguage);
        });
    });
}
