import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

import appUtils from '@edf-pkg/app-utils';

import { appDuck } from '../../store';

const WithInitializerHOC = (WrappedComponent) => {
    class WithInitializer extends Component {
        static WrappedComponent = WrappedComponent;

        render() {
            const { props } = this;
            return <WrappedComponent {...props} />;
        }
    }

    const mapStateToProps = function mapStateToProps(state) {
        return {
            initialized: appDuck.duckSelectors.initializationStatusSucceededSelector(state),
        };
    };

    const mapDispatchToProps = (dispatch) => {
        return {
            initialize: () => dispatch(appDuck.duckActionCreators.initialize()),
        };
    };

    WithInitializer.displayName = `WithInitializer(${appUtils.component.getDisplayName(WrappedComponent)})`;

    WithInitializer.propTypes = {
        initialized: PropTypes.bool.isRequired,
        initialize: PropTypes.func.isRequired,
    };

    return connect(mapStateToProps, mapDispatchToProps)(hoistNonReactStatics(WithInitializer, WrappedComponent));
};

export default WithInitializerHOC;
