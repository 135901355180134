export const CONTENT_MIN_WIDTH = 1050;

export const HTTP_CALL_STATUS = {
    UNKNOWN: 'UNKNOWN',
    LOADING: 'LOADING',
    SUCCEEDED: 'SUCCEEDED',
    FAILED: 'FAILED',
};

export const START_OF_TIME_MS = 1262304000000; // 2010-01-01 00:00:00 UTC

export const EDIT_STATUSES = {
    NO_EDIT: 'NO_EDIT',
    EDITED: 'EDITED',
};

// TODO: we have a similar constant under app-pdash package
export const STUDY_STATUSES = {
    NONE: 'none',
    UPCOMING: 'upcoming',
    ONGOING: 'ongoing',
    PAST: 'past',
};

export const SESSION_STAT_COLUMN_NAMES = {
    COMPLETED: 'session_stats.completed',
    CANCELED: 'session_stats.canceled',
    EXPIRED: 'session_stats.expired',
    IN_PROGRESS: 'session_stats.in_progress',
    BLOCKED: 'session_stats.blocked',
    UNKNOWN: 'session_stats.unknown',
    OTHER: 'session_stats.other',
};
export const SESSION_STAT_COLORS = {
    [SESSION_STAT_COLUMN_NAMES.COMPLETED]: 'green',
    [SESSION_STAT_COLUMN_NAMES.CANCELED]: 'yellow',
    [SESSION_STAT_COLUMN_NAMES.EXPIRED]: 'red',
    [SESSION_STAT_COLUMN_NAMES.IN_PROGRESS]: 'ocean-light',
    [SESSION_STAT_COLUMN_NAMES.BLOCKED]: 'violet',
    [SESSION_STAT_COLUMN_NAMES.UNKNOWN]: 'space-light',
    [SESSION_STAT_COLUMN_NAMES.OTHER]: 'gray-light',
};

export const DIALOG_IDS = {
    BASICS_CONSENT_MATERIALS: 'study_basics_consent_materials',
    BASICS_EDIT_STUDY: 'study_basics_edit_info',
    BASICS_EDIT_BACKGROUND_IMAGE: 'study_basics_change_background',
    BASICS_ADD_LANGUAGE: 'study_basics_add_language',
    BASICS_REMOVE_LANGUAGES_CONFIRMATION: 'study_basics_remove_languages_confirmation',
    BASICS_SITE_ADD: 'BASICS_SITE_ADD',
    BASICS_SITE_EDIT: 'BASICS_SITE_EDIT',
    BASICS_SITE_REMOVE: 'BASICS_SITE_REMOVE',
    ACTIVITY_MOVE: 'activity_move',
    ACTIVITY_RELEASE_OLD: 'activity_release_old',
    ACTIVITY_RELEASE: 'activity_release',
    ACTIVITY_DELETE: 'activity_delete',
    SAVED_FILTER_DELETE: 'saved_filter_delete',
    SAVED_FILTER_DELETE_ALL_CONDITIONS: 'saved_filter_delete_all_conditions',
    SAVED_FILTER_DELETE_ALL_SORTS: 'saved_filter_delete_all_sorts',
    ACTIVITY_DUPLICATE: 'activity_duplicate',
    // TODO: rename all Add to Create for activity (in the Loco and also other namings)
    ACTIVITY_ADD: 'activity_add',
    DATA_SOURCE_ADD: 'data_source_add',
    ACTIVITY_RESPONSES_SESSION_DETAILS: 'activity_responses_session_details',
    ACTIVITY_RESPONSES_RESPONSES_DETAILS: 'activity_responses_responses_details',
    ACTIVITY_RESPONSES_IMAGE: 'activity_responses_image',
    ACTIVITY_RESPONSES_VIDEO: 'activity_responses_video',
    ACTIVITY_RESPONSES_EXPORT_DIALOG: 'activity_responses_export_dialog',
    DATA_SOURCE_DELETE: 'data_source_delete',
    DATA_SOURCE_EDIT: 'data_source_edit',
    DATA_SOURCE_SETTINGS_UPDATE: 'data_source_settings_update',
    PARTICIPATION_INVITE: 'participation_invite',
    PARTICIPATION_RELOAD_DEVICES: 'participation_reload_devices',
    PARTICIPATION_DELETE: 'participation_delete',
    PARTICIPATION_DROP: 'participation_drop',
    PARTICIPATION_EDIT_LABEL: 'participation_edit_label',
    PARTICIPATION_CHANGE_SITE: 'participation_change_site',
    PARTICIPATION_EDIT_PERIOD: 'participation_edit_period',
    PARTICIPATION_VIEW_DEVICES_INFO: 'participation_view_devices_info',
    PARTICIPATION_INVITATION_DELETE: 'participation_invitation_delete',
    PARTICIPATION_INVITATION_RESEND: 'participation_invitation_resend',
    DATA_EXPORT_DELETE: 'data_export_delete',
    BEACONS_DELETE: 'beacons_delete',
    BEACON_ADD: 'beacon_add',
    SURVEY_RESPONSES_EXPORT: 'survey_responses_export',
    SURVEY_RESPONSES_QUESTION_HEADER: 'survey_responses_question_header',
    SURVEY_RESPONSES_SESSION_CELL: 'survey_responses_session_cell',
    SURVEY_RESPONSES_QUESTION_CELL: 'survey_responses_question_cell',
    REQUEST_UPGRADE_INFO_DIALOG: 'request_upgrade_info_dialog',
    SETTINGS_CHANGE_AVATAR: 'settings_change_avatar',
    SETTINGS_SECURITY_CHANGE_PASSWORD: 'settings_security_change_password',
    PHR_EVENT_INFO_DIALOG: 'phr_event_info_dialog',
    FILTERING_DATA_EDIT_QUERY: 'filtering_data_edit_query',
    MANAGE_FILTER_DIALOG: 'manage_filter',
    FILTERING_DATA_EDIT_SORT_BY: 'filtering_data_edit_sort_by',
    FILTERING_DATA_EXPORT: 'filtering_data_export',
    NOTIFICATION_TEMPLATE_CREATE: 'notification_template_create',
    NOTIFICATION_TEMPLATE_DELETE: 'notification_template_delete',
    NOTIFICATION_TEMPLATE_UPDATE: 'notification_template_update',
    NOTIFICATION_TEMPLATE_VIEW_EMAIL: 'notification_template_view_email',
    NOTIFICATION_TEMPLATE_SHOULD_ENABLE_VERIFY_EMAIL: 'notification_template_should_enable_verify_email',
    NOTIFICATION_TEMPLATE_SHOULD_ENABLE_VERIFY_PHONE: 'notification_template_should_enable_verify_phone',
    NOTIFICATION_MEDIUM_CONTENTS: 'notification_medium_contents',
    NOTIFICATION_SEND: 'notification_send',
    STUDY_CHANGE_CONFIRMATION_WHILE_IN_CALL: 'study_change_confirmation_while_in_call',
    TIME_USER_DEF_SESSIONS_CREATE: 'time_user_def_sessions_create',
    TIME_USER_DEF_SESSIONS_DELETE: 'time_user_def_sessions_delete',
    AUDIT_TRAIL_CHANGES: 'audit_trail_changes',
    EDEN_E_CONSENT: 'EDEN_E_CONSENT',
    CALENDAR_VIEW_SHOW_SESSIONS: 'CALENDAR_VIEW_SHOW_SESSIONS',
    CALENDAR_VIEW_DELETE_SESSION: 'CALENDAR_VIEW_DELETE_SESSION',
    CALENDAR_VIEW_EDIT_SESSION: 'CALENDAR_VIEW_EDIT_SESSION',
    CALENDAR_VIEW_CREATE_SESSION: 'CALENDAR_VIEW_CREATE_SESSION',
    RESEARCHER_ADJUST_ROLES_AND_PERMISSIONS: 'RESEARCHER_ADJUST_ROLES_AND_PERMISSIONS',
    RESEARCHER_ADD: 'RESEARCHER_ADD',
    RESEARCHERS_REMOVE: 'RESEARCHERS_REMOVE',
    RESEARCHERS_PROMOTE_DEMOTE: 'RESEARCHERS_PROMOTE_DEMOTE',
    RESEARCHERS_SET_AS_STUDY_OWNER: 'RESEARCHERS_SET_AS_STUDY_OWNER',
    ROLE_CREATE: 'ROLE_CREATE',
    ROLE_EDIT: 'ROLES_EDIT',
    ROLE_DELETE: 'ROLES_DELETE',
    CRITERIA_EDITOR: 'CRITERIA_EDITOR',
    DELETE_CONDITIONS_CONFIRMATION_DIALOG: 'DELETE_CONDITIONS_CONFIRMATION_DIALOG',
};

export const PARTICIPATION_ENROLMENT_TYPES = {
    PUBLIC: 1,
    INVITE_ONLY: 3,
    CLOSED: 4,
};

export const PARTICIPATION_ENROLMENT_TYPE_LABELS = {
    [PARTICIPATION_ENROLMENT_TYPES.PUBLIC]: (t) =>
        `${t('rdash:enrolment_type_public')} (${t('rdash:enrolment_type_public_description')})`,
    [PARTICIPATION_ENROLMENT_TYPES.INVITE_ONLY]: (t) =>
        `${t('rdash:enrolment_type_invite_only')} (${t('rdash:enrolment_type_invite_only_description')})`,
    [PARTICIPATION_ENROLMENT_TYPES.CLOSED]: (t) =>
        `${t('rdash:enrolment_type_closed')} (${t('rdash:enrolment_type_closed_description')})`,
};

export const PARTICIPATION_COLUMN_NAMES = {
    LABEL: 'label',
    START_TIME: 'start_time',
    END_TIME: 'end_time',
    STATUS_ID: 'status_id',
};

export const PARTICIPATION_REGISTRATION_STATUS_ID = {
    NONE: 0,
    ACTIVE: 1,
    PENDING: 2,
    DROPPED: 3,
};

export const SESSION_STATUS_ID = {
    UNKNOWN: 0,
    COMPLETED: 1,
    CANCELED: 2,
    EXPIRED: 3,
    BLOCKED: 4,
    INVALID_CRITERIA: 5,
    IN_PROGRESS: 6,
};

export const PARTICIPATION_REGISTRATION_STATUS_LABELS = {
    [PARTICIPATION_REGISTRATION_STATUS_ID.NONE]: (t) => t('rdash:none'),
    [PARTICIPATION_REGISTRATION_STATUS_ID.ACTIVE]: (t) => t('rdash:active'),
    [PARTICIPATION_REGISTRATION_STATUS_ID.PENDING]: (t) => t('rdash:pending'),
    [PARTICIPATION_REGISTRATION_STATUS_ID.DROPPED]: (t) => t('rdash:dropped'),
};

export const SESSION_STATUS_LABELS = {
    [SESSION_STATUS_ID.COMPLETED]: (t) => t('rdash:completed'),
    [SESSION_STATUS_ID.IN_PROGRESS]: (t) => t('rdash:in_progress'),
    [SESSION_STATUS_ID.UNKNOWN]: (t) => t('rdash:unanswered'),
    [SESSION_STATUS_ID.CANCELED]: (t) => t('rdash:canceled'),
    [SESSION_STATUS_ID.EXPIRED]: (t) => t('rdash:expired'),
    [SESSION_STATUS_ID.BLOCKED]: (t) => t('rdash:blocked'),
    [SESSION_STATUS_ID.INVALID_CRITERIA]: (t) => t('rdash:invalid_criteria'),
};

export const DATA_EXPORT_STATUS_ID = {
    IDLE: 0,
    PENDING: 1,
    FAILED: 2,
    SUCCESS: 3,
    TOO_LARGE: 4,
};

export const DATA_EXPORT_STATUS_LABELS = {
    [DATA_EXPORT_STATUS_ID.IDLE]: (t) => t('rdash:idle'),
    [DATA_EXPORT_STATUS_ID.PENDING]: (t) => t('rdash:pending'),
    [DATA_EXPORT_STATUS_ID.FAILED]: (t) => t('rdash:failed'),
    [DATA_EXPORT_STATUS_ID.TOO_LARGE]: (t) => t('rdash:failed_too_large'),
    [DATA_EXPORT_STATUS_ID.SUCCESS]: (t) => t('rdash:ready'),
};

export const BEACON_FIELDS_VALIDATION = {
    SUBJECT_ID: {
        MAX: 131071,
        MIN: 0,
    },
    ROLE_ID: {
        MAX: 7,
        MIN: 0,
    },
    TEAM_ID: {
        MAX: 4095,
        MIN: 0,
    },
};

export const ACTIVITY_SESSION_DAYS_TYPE = {
    CALENDAR: 'calendar',
    RELATIVE: 'relative',
};

export const SESSIONS_STATUS_IDS = {
    UNKNOWN: 0,
    COMPLETED: 1,
    CANCELED: 2,
    EXPIRED: 3,
    BLOCKED: 4,
    INVALID_CRITERIA: 5,
    IN_PROGRESS: 6,
};

// TODO-MERGE: this is taken from pdash
export const SURVEY_TYPES = {
    UNKNOWN: -1,
    GENERIC: 0,
    TIME_USE: 1,
};

// TODO-MERGE: This is taken from pdash and got extended.
export const ACTIVITY_TYPES = {
    SURVEY: 'survey',
    WEB: 'web',
    TIME_USE: 'time_use',
    PAGE: 'page',
    STROOP: 'stroop',
    MATRIX_REASONING: 'matrix_reasoning',
    TIME_PREF: 'time_pref',
    SUMMARY_PAGE: 'summary_page',
    ASA24: 'asa24',
    WHEEL_OF_FORTUNE: 'wheel_of_fortune',
};

export const ACTIVITY_TYPE_NAME_TRANSLATIONS = {
    [ACTIVITY_TYPES.SURVEY]: (t) => t('rdash:survey'),
    [ACTIVITY_TYPES.TIME_USE]: (t) => t('rdash:time_use'),
    [ACTIVITY_TYPES.PAGE]: (t) => t('rdash:page'),
    [ACTIVITY_TYPES.STROOP]: (t) => t('rdash:stroop'),
    [ACTIVITY_TYPES.MATRIX_REASONING]: (t) => t('rdash:matrix_reasoning'),
    [ACTIVITY_TYPES.TIME_PREF]: (t) => t('rdash:time_pref'),
    [ACTIVITY_TYPES.ASA24]: () => 'ASA24', // This activity type will be deprecated. No need to define a new translation.
};

export const SUPPORTED_ACTIVITY_TYPES = [
    ACTIVITY_TYPES.SURVEY,
    ACTIVITY_TYPES.TIME_USE,
    ACTIVITY_TYPES.STROOP,
    ACTIVITY_TYPES.MATRIX_REASONING,
    ACTIVITY_TYPES.PAGE,
];

// TODO: This should be deprecated or merged with the new icons (You can find the new icons in the activity-sessions page used in Calendar View)
export const ACTIVITY_TYPE_ICONS = {
    [ACTIVITY_TYPES.WEB]: ['fad', 'window-restore'],
    [ACTIVITY_TYPES.PAGE]: ['fad', 'tags'],
    [ACTIVITY_TYPES.STROOP]: ['fad', 'window-restore'],
    [ACTIVITY_TYPES.MATRIX_REASONING]: ['fad', 'puzzle-piece'],
    [ACTIVITY_TYPES.SURVEY]: ['fad', 'ballot-check'],
    [ACTIVITY_TYPES.TIME_USE]: ['fad', 'calendar-alt'],
};

const ELIGIBILITY_PSEUDO_SURVEY = 'ELIGIBILITY_PSEUDO_SURVEY';
const DROPOUT_PSEUDO_SURVEY = 'DROPOUT_PSEUDO_SURVEY';

export const PSEUDO_ACTIVITY_TYPES = [ELIGIBILITY_PSEUDO_SURVEY, DROPOUT_PSEUDO_SURVEY];

export const PSEUDO_INCLUDED_ACTIVITY_TYPES = {
    ELIGIBILITY_PSEUDO_SURVEY,
    ...ACTIVITY_TYPES,
    DROPOUT_PSEUDO_SURVEY,
};

export const PSEUDO_INCLUDED_SUPPORTED_ACTIVITY_TYPES = [
    ELIGIBILITY_PSEUDO_SURVEY,
    ...SUPPORTED_ACTIVITY_TYPES,
    DROPOUT_PSEUDO_SURVEY,
];

export const PSEUDO_INCLUDED_ACTIVITY_TYPE_NAME_TRANSLATIONS = {
    ...ACTIVITY_TYPE_NAME_TRANSLATIONS,
    [PSEUDO_INCLUDED_ACTIVITY_TYPES.ELIGIBILITY_PSEUDO_SURVEY]: (t) => t('rdash:eligibility_survey'),
    [PSEUDO_INCLUDED_ACTIVITY_TYPES.DROPOUT_PSEUDO_SURVEY]: (t) => t('rdash:dropout_survey'),
};

export const SESSIONS_TRIGGERING_LOGIC_IDS = {
    // TODO: Check BE to see if we have any UNKNOWN status. If not, remove from here.
    UNKNOWN: -1,
    MANUAL: 0,
    ELIGIBILITY: 1,
    TIME: 2,
    PROXIMITY: 3,
    USER: 4,
    DROPOUT: 5,
    TIME_USER_DEF: 6,
};

// TODO-MERGE: this is taken from pdash
export const SURVEY_QUESTION_TYPES = {
    INFORMATION: 'information',
    SINGLE_CHOICE: 'single_choice',
    MULTIPLE_CHOICE: 'multiple_choice',
    FREE_FORM_TEXT: 'free_form_text',
    NUMBER: 'number',
    MASS: 'mass',
    LENGTH: 'length',
    VAS: 'vas',
    CALENDAR: 'calendar',
    IMAGE: 'image',
    VIDEO: 'video',
    AUDIO: 'audio',
    AUDIO_TEXT: 'audio_text',
    BARCODE: 'barcode',
};

// TODO-MERGE: this is taken from pdash
export const SURVEY_QUESTION_TYPE_CALENDAR_SELECTOR_TYPES = {
    DATE_TIME: 'date_time',
    DATE: 'date',
    TIME: 'time',
};

export const KIBANA_URL = '/kibana';

export const LEARN_URL = 'https://learn.avicennaresearch.com';

export const SURVEY_QUESTION_TYPE_MAS_UNITS = {
    KG: 'kg',
    LBS: 'lbs',
};

export const SURVEY_QUESTION_TYPE_LENGTH_UNITS = {
    CM: 'cm',
    FT_IN: 'ft_in',
};

export const LICENSE_TYPE_IDS = {
    TRIAL: 1,
    LICENSED: 2,
    PENDING: 3,
};

export const VALIDATION_STATUS = {
    UNKNOWN: 'UNKNOWN',
    LOADING: 'LOADING',
    FAILED: 'FAILED',
    VALID: 'VALID',
    INVALID: 'INVALID',
};

export const BACKEND_MODEL_NAMES = {
    STUDY: 'ethica_view_study',
    DATA_SOURCE: 'ethica_view_stream',
    TIME_USE: 'time_use',
    STROOP: 'stroop',
    MATRIX_REASONING: 'matrix_reasoning',
    PAGE: 'page',
    MY_PROGRESS: 'my_progress',
};

export const ACTIVITY_CREATION_METHOD_ENUM = {
    NEW: 'new',
    IMPORT: 'import',
};

export const STROOP_DURATION_TYPE_ENUM = {
    // Stroop constant
    UNKNOWN: 'unknown',
    TIMED: 'timed',
    ROUND: 'round',
};

export const STROOP_SELECTOR_TYPE_ENUM = {
    // Stroop constant
    UNKNOWN: 'unknown',
    COLORED_BOX: 'colored_box',
    COLORED_TEXT: 'colored_text',
    YES_NO: 'yes_no',
};

export const TRIGGERING_LOGIC_TYPES = {
    UNKNOWN: '',
    RESEARCHER: 'researcher',
    ELIGIBILITY: 'eligibility',
    TIME: 'time',
    PROXIMITY: 'proximity',
    USER: 'user',
    DROPOUT: 'dropout',
    TIME_USER_DEF: 'time_user_def',
    PUBLIC: 'public',
};

export const DEFAULT_NEW_ACTIVITY_NAME = (activityName, t) => `${t('rdash:new')} ${activityName}`;

export const DEFAULT_NEW_TRIGGERING_LOGIC_NAME = (triggeringLogicName, t) =>
    `${t('rdash:new')} ${triggeringLogicName} ${t('rdash:trigger')}`;

export const DEFAULT_TRIGGERING_LOGICS = (triggerTitle, t, type = TRIGGERING_LOGIC_TYPES.USER) => [
    {
        id: 0,
        type,
        trigger_title: DEFAULT_NEW_TRIGGERING_LOGIC_NAME(triggerTitle, t),
    },
];

export const DEFAULT_ACTIVITY_DATA = {
    [ACTIVITY_TYPES.SURVEY]: (t) => ({
        version: 0,
        name: DEFAULT_NEW_ACTIVITY_NAME(t('rdash:survey'), t),
        desc: t('rdash:new_survey_default_description'),
        expiryDurationMs: 0,
        captureLocation: false,
        triggeringLogics: DEFAULT_TRIGGERING_LOGICS(t('rdash:survey'), t),
        showProgress: true,
        displayResponses: {
            where: 'none',
        },
        subSurveys: [
            {
                criteria: '',
                subSurveyId: 1,
                subSurveyContent: [
                    {
                        questions: [
                            {
                                questionId: 1,
                                questionType: SURVEY_QUESTION_TYPES.INFORMATION,
                                label: 'Question 1',
                                hasPlaceholder: false,
                                isMandatory: false,
                                enabled: true,
                                persisted: false,
                                questionContent: 'Replace this question with your question ...',
                            },
                        ],
                        criteria: '',
                        nextSection: -1,
                    },
                ],
            },
        ],
    }),
    [PSEUDO_INCLUDED_ACTIVITY_TYPES.ELIGIBILITY_PSEUDO_SURVEY]: (t) => ({
        version: 0,
        name: DEFAULT_NEW_ACTIVITY_NAME(t('rdash:eligibility_survey'), t),
        desc: t('rdash:eligibility_survey_description'),
        expiryDurationMs: 0,
        captureLocation: false,
        triggeringLogics: DEFAULT_TRIGGERING_LOGICS(t('rdash:eligibility_survey'), t, TRIGGERING_LOGIC_TYPES.ELIGIBILITY),
        showProgress: true,
        displayResponses: {
            where: 'none',
        },
        subSurveys: [
            {
                criteria: '',
                subSurveyId: 1,
                subSurveyContent: [
                    {
                        questions: [
                            {
                                questionId: 1,
                                questionType: SURVEY_QUESTION_TYPES.INFORMATION,
                                label: 'Question 1',
                                hasPlaceholder: false,
                                isMandatory: false,
                                enabled: true,
                                persisted: false,
                                questionContent: 'Replace this question with your question ...',
                            },
                        ],
                        criteria: '',
                        nextSection: -1,
                    },
                ],
            },
        ],
    }),
    [PSEUDO_INCLUDED_ACTIVITY_TYPES.DROPOUT_PSEUDO_SURVEY]: (t) => ({
        version: 0,
        name: DEFAULT_NEW_ACTIVITY_NAME(t('rdash:dropout_survey'), t),
        desc: t('rdash:dropout_survey_description'),
        expiryDurationMs: 0,
        captureLocation: false,
        triggeringLogics: DEFAULT_TRIGGERING_LOGICS(t('rdash:dropout_survey'), t, TRIGGERING_LOGIC_TYPES.DROPOUT),
        showProgress: true,
        displayResponses: {
            where: 'none',
        },
        subSurveys: [
            {
                criteria: '',
                subSurveyId: 1,
                subSurveyContent: [
                    {
                        questions: [
                            {
                                questionId: 1,
                                questionType: SURVEY_QUESTION_TYPES.INFORMATION,
                                label: 'Question 1',
                                hasPlaceholder: false,
                                isMandatory: false,
                                enabled: true,
                                persisted: false,
                                questionContent: 'Replace this question with your question ...',
                            },
                        ],
                        criteria: '',
                        nextSection: -1,
                    },
                ],
            },
        ],
    }),
    [ACTIVITY_TYPES.STROOP]: (t) => ({
        triggeringLogics: JSON.stringify(DEFAULT_TRIGGERING_LOGICS(t('rdash:stroop'), t)),
        durationType: STROOP_DURATION_TYPE_ENUM.TIMED,
        duration: 60000,
        selector: STROOP_SELECTOR_TYPE_ENUM.YES_NO,
        incongruentRoundCount: 1,
        congruentRoundCount: 1,
        neutralRoundCount: 1,
    }),
    [ACTIVITY_TYPES.MATRIX_REASONING]: (t) => ({
        triggeringLogics: JSON.stringify(DEFAULT_TRIGGERING_LOGICS(t('rdash:matrix_reasoning'), t)),
    }),
    [ACTIVITY_TYPES.PAGE]: (t) => ({
        triggeringLogics: JSON.stringify(DEFAULT_TRIGGERING_LOGICS(t('rdash:page'), t)),
        url: 'https://avicennaresearch.com/',
    }),
    [ACTIVITY_TYPES.TIME_USE]: (t) => ({
        triggeringLogics: JSON.stringify(DEFAULT_TRIGGERING_LOGICS(t('rdash:time_use'), t)),
    }),
};

export const NOTIFICATION_EVENT_IDS = {
    ON_DEMAND: 0,
    REQUEST_LICENSE_UPGRADE: 1,
    TRIAL_STUDY_WIPE_NOTIF_3_DAYS: 2,
    TRIAL_STUDY_WIPE_NOTIF_1_DAY: 3,
    TRIAL_STUDY_WIPE_NOTIF_DONE: 4,
    TRIAL_STUDY_RM_NOTIF_2_WEEKS: 5,
    TRIAL_STUDY_RM_NOTIF_1_WEEK: 6,
    TRIAL_STUDY_RM_NOTIF_1_DAY: 7,
    TRIAL_STUDY_RM_NOTIF_DONE: 8,
    STUDY_INVITATION: 9,
    PARTICIPANT_JOINED: 10,
    EXPORT_READY: 11,
    SESSION_RELEASED: 12,
    SESSION_COMPLETED: 13,
    SESSION_CANCELED: 14,
    SESSION_EXPIRED: 15,
    ACCOUNT_DELETION_INITIATED: 16,
    ACCOUNT_DELETION_REMINDER: 17,
    ACCOUNT_DELETION_REVOKED: 18,
    ACCOUNT_DELETION_SUCCEEDED: 19,
};

export const NOTIFICATION_EVENT_LABELS = {
    [NOTIFICATION_EVENT_IDS.ON_DEMAND]: (t) => t('rdash:notification_event_on_demand'),
    [NOTIFICATION_EVENT_IDS.REQUEST_LICENSE_UPGRADE]: (t) => t('rdash:notification_event_request_license_upgrade'),
    [NOTIFICATION_EVENT_IDS.TRIAL_STUDY_WIPE_NOTIF_3_DAYS]: (t) =>
        t('rdash:notification_event_trial_study_wipe_notif_three_days'),
    [NOTIFICATION_EVENT_IDS.TRIAL_STUDY_WIPE_NOTIF_1_DAY]: (t) => t('rdash:notification_event_trial_study_wipe_notif_one_day'),
    [NOTIFICATION_EVENT_IDS.TRIAL_STUDY_WIPE_NOTIF_DONE]: (t) => t('rdash:notification_event_trial_study_wipe_notif_done'),
    [NOTIFICATION_EVENT_IDS.TRIAL_STUDY_RM_NOTIF_2_WEEKS]: (t) =>
        t('rdash:notification_event_trial_study_remove_notif_two_weeks'),
    [NOTIFICATION_EVENT_IDS.TRIAL_STUDY_RM_NOTIF_1_WEEK]: (t) => t('rdash:notification_event_trial_study_remove_notif_one_week'),
    [NOTIFICATION_EVENT_IDS.TRIAL_STUDY_RM_NOTIF_1_DAY]: (t) => t('rdash:notification_event_trial_study_remove_notif_one_day'),
    [NOTIFICATION_EVENT_IDS.TRIAL_STUDY_RM_NOTIF_DONE]: (t) => t('rdash:notification_event_trial_study_remove_notif_done'),
    [NOTIFICATION_EVENT_IDS.STUDY_INVITATION]: (t) => t('rdash:notification_event_study_invitation'),
    [NOTIFICATION_EVENT_IDS.PARTICIPANT_JOINED]: (t) => t('rdash:notification_event_participant_joined'),
    [NOTIFICATION_EVENT_IDS.EXPORT_READY]: (t) => t('rdash:notification_event_export_ready'),
    [NOTIFICATION_EVENT_IDS.SESSION_RELEASED]: (t) => t('rdash:notification_event_session_released'),
    [NOTIFICATION_EVENT_IDS.SESSION_COMPLETED]: (t) => t('rdash:notification_event_session_completed'),
    [NOTIFICATION_EVENT_IDS.SESSION_CANCELED]: (t) => t('rdash:notification_event_session_canceled'),
    [NOTIFICATION_EVENT_IDS.SESSION_EXPIRED]: (t) => t('rdash:notification_event_session_expired'),
    [NOTIFICATION_EVENT_IDS.ACCOUNT_DELETION_INITIATED]: (t) => t('rdash:notification_event_account_deletion_initiated'),
    [NOTIFICATION_EVENT_IDS.ACCOUNT_DELETION_REMINDER]: (t) => t('rdash:notification_event_account_deletion_reminder'),
    [NOTIFICATION_EVENT_IDS.ACCOUNT_DELETION_REVOKED]: (t) => t('rdash:notification_event_account_deletion_revoked'),
    [NOTIFICATION_EVENT_IDS.ACCOUNT_DELETION_SUCCEEDED]: (t) => t('rdash:notification_event_account_deletion_succeeded'),
};

export const NOTIFICATION_SESSION_RELATED_EVENT_IDS = [
    NOTIFICATION_EVENT_IDS.SESSION_RELEASED,
    NOTIFICATION_EVENT_IDS.SESSION_COMPLETED,
    NOTIFICATION_EVENT_IDS.SESSION_CANCELED,
    NOTIFICATION_EVENT_IDS.SESSION_EXPIRED,
];

export const NOTIFICATION_EVENT_IDS_WITH_VERIFIED_EMAIL_AND_PHONE = [
    NOTIFICATION_EVENT_IDS.SESSION_RELEASED,
    NOTIFICATION_EVENT_IDS.SESSION_COMPLETED,
    NOTIFICATION_EVENT_IDS.SESSION_CANCELED,
    NOTIFICATION_EVENT_IDS.SESSION_EXPIRED,
];

export const NOTIFICATION_MEDIUM_IDS = {
    SMS: 0,
    EMAIL: 1,
    IN_APP: 2,
};

export const NOTIFICATION_MEDIUM_TYPES = {
    IN_APP: 'inApp',
    SMS: 'sms',
    EMAIL: 'email',
};

export const NOTIFICATION_MEDIUM_IDS_TO_LABELS = {
    [NOTIFICATION_MEDIUM_IDS.SMS]: (t) => t('rdash:sms'),
    [NOTIFICATION_MEDIUM_IDS.IN_APP]: (t) => t('rdash:in_app'),
    [NOTIFICATION_MEDIUM_IDS.EMAIL]: (t) => t('rdash:email'),
};

export const NOTIFICATION_MEDIUM_ICONS = {
    // TODO: replace 'mobile' with 'mobile-screen-button' after FontAwesome is upgraded to v6
    [NOTIFICATION_MEDIUM_TYPES.IN_APP]: ['far', 'mobile'],
    [NOTIFICATION_MEDIUM_TYPES.SMS]: ['far', 'comment-dots'],
    [NOTIFICATION_MEDIUM_TYPES.EMAIL]: ['far', 'envelope'],
};

export const NOTIFICATION_FORM_RECIPIENT_TYPES = {
    PARTICIPANT: 'PARTICIPANT',
    RESEARCHERS_LIST: 'RESEARCHERS_LIST',
};

export const NOTIFICATION_STATUS_IDS = {
    NONE: 0,
    PENDING: 1,
    INVALID_CRITERIA: 4,
    DISABLED: 5,
    STALE: 6,
    SUCCESS: 7,
    PARTIALLY_FAILED: 8,
    FAILED: 9,
    SENDING: 10,
};

export const NOTIFICATION_STATUS_LABELS = {
    [NOTIFICATION_STATUS_IDS.NONE]: (t) => t('rdash:none'),
    [NOTIFICATION_STATUS_IDS.PENDING]: (t) => t('rdash:pending'),
    [NOTIFICATION_STATUS_IDS.INVALID_CRITERIA]: (t) => t('rdash:invalid_criteria'),
    [NOTIFICATION_STATUS_IDS.DISABLED]: (t) => t('rdash:disabled'),
    [NOTIFICATION_STATUS_IDS.STALE]: (t) => t('rdash:stale'),
    [NOTIFICATION_STATUS_IDS.SUCCESS]: (t) => t('rdash:success'),
    [NOTIFICATION_STATUS_IDS.PARTIALLY_FAILED]: (t) => t('rdash:partially_failed'),
    [NOTIFICATION_STATUS_IDS.FAILED]: (t) => t('rdash:failed'),
    [NOTIFICATION_STATUS_IDS.SENDING]: (t) => t('rdash:sending'),
};

export const NOTIFICATION_TEMPLATE_LINKED_ACTIVITIES_IDS_BY_TYPE = {
    survey: [],
    stroop: [],
    matrixReasoning: [],
    timeUse: [],
};

export const NOTIFICATION_MEDIUMS_STATUS_IDS = {
    UNKNOWN: 0,
    SENDING: 1,
    SUCCESS: 2,
    FAILED: 3,
    SCHEDULED_BY_AVICENNA: 4,
    SCHEDULED_BY_OS: 5,
    PROMPTED: 6,
    BLOCKED: 7,
    TAPPED: 8,
    DISMISSED: 9,
    INVALID_CRITERIA: 10,
};

export const NOTIFICATION_MEDIUMS_STATUS_LABELS = {
    [NOTIFICATION_MEDIUMS_STATUS_IDS.UNKNOWN]: (t) => t('rdash:unknown'),
    [NOTIFICATION_MEDIUMS_STATUS_IDS.SENDING]: (t) => t('rdash:sending'),
    [NOTIFICATION_MEDIUMS_STATUS_IDS.SUCCESS]: (t) => t('rdash:success'),
    [NOTIFICATION_MEDIUMS_STATUS_IDS.FAILED]: (t) => t('rdash:failed'),
    [NOTIFICATION_MEDIUMS_STATUS_IDS.SCHEDULED_BY_AVICENNA]: (t) => t('rdash:scheduled_by_avicenna'),
    [NOTIFICATION_MEDIUMS_STATUS_IDS.SCHEDULED_BY_OS]: (t) => t('rdash:scheduled_by_os'),
    [NOTIFICATION_MEDIUMS_STATUS_IDS.PROMPTED]: (t) => t('rdash:prompted'),
    [NOTIFICATION_MEDIUMS_STATUS_IDS.BLOCKED]: (t) => t('rdash:blocked'),
    [NOTIFICATION_MEDIUMS_STATUS_IDS.TAPPED]: (t) => t('rdash:tapped'),
    [NOTIFICATION_MEDIUMS_STATUS_IDS.DISMISSED]: (t) => t('rdash:dismissed'),
    [NOTIFICATION_MEDIUMS_STATUS_IDS.INVALID_CRITERIA]: (t) => t('rdash:invalid_criteria'),
};

export const FILTERING_DATA_FILTER_NAMES = {
    PARTICIPATION: 'PARTICIPATION',
    ACTIVITY_SESSION: 'SESSION',
    SURVEY_RESPONSE: 'RESPONSE',
    ACTIVITY_RESPONSE: 'ACTIVITY_RESPONSE',
    AUDIT_TRAIL: 'AUDIT_TRAIL',
    NOTIFICATION: 'NOTIFICATION',
};

export const FILTERING_DATA_FILTER_URLS = {
    [FILTERING_DATA_FILTER_NAMES.PARTICIPATION]: 'dashboard:rdash:PARTICIPATION',
    [FILTERING_DATA_FILTER_NAMES.ACTIVITY_SESSION]: 'dashboard:rdash:ACTIVITY_SESSIONS',
    [FILTERING_DATA_FILTER_NAMES.SURVEY_RESPONSE]: 'dashboard:rdash:ACTIVITY_RESPONSES',
    [FILTERING_DATA_FILTER_NAMES.ACTIVITY_RESPONSE]: 'dashboard:rdash:ACTIVITY_RESPONSES',
    [FILTERING_DATA_FILTER_NAMES.AUDIT_TRAIL]: 'dashboard:rdash:AUDIT_TRAIL',
    [FILTERING_DATA_FILTER_NAMES.NOTIFICATION]: 'dashboard:rdash:NOTIFICATIONS',
};

export const SORT_ORDER = {
    ASC: 'asc',
    DESC: 'desc',
};

export const NOTIFICATION_EVENT_IDS_SUPPORTED_ON_RDASH = [
    NOTIFICATION_EVENT_IDS.STUDY_INVITATION,
    NOTIFICATION_EVENT_IDS.PARTICIPANT_JOINED,
    NOTIFICATION_EVENT_IDS.SESSION_RELEASED,
    NOTIFICATION_EVENT_IDS.SESSION_CANCELED,
    NOTIFICATION_EVENT_IDS.SESSION_EXPIRED,
    NOTIFICATION_EVENT_IDS.SESSION_COMPLETED,
];

export const NOTIFICATIONS_SUPPORTED_ACTIVITY_TYPES = [
    ACTIVITY_TYPES.SURVEY,
    ACTIVITY_TYPES.TIME_USE,
    ACTIVITY_TYPES.STROOP,
    ACTIVITY_TYPES.MATRIX_REASONING,
    ACTIVITY_TYPES.TIME_PREF,
    ACTIVITY_TYPES.ASA24,
];

export const NOTIFICATIONS_NEGATIVE_OFFSET_SUPPORTING_EVENT_IDS = [
    NOTIFICATION_EVENT_IDS.SESSION_EXPIRED,
    NOTIFICATION_EVENT_IDS.SESSION_RELEASED,
];

export const CHOICE_BASED_QUESTIONS_SUGGESTED_ANSWERS = {
    DISAGREE_AGREE: {
        title: (t) => t('rdash:suggested_answers_disagree_agree'),
        optionsKey: 'suggested_answers_disagree_agree_options',
    },
    DISSATISFIED_SATISFIED: {
        title: (t) => t('rdash:suggested_answers_dissatisfied_satisfied'),
        optionsKey: 'suggested_answers_dissatisfied_satisfied_options',
    },
    INAPPROPRIATE_APPROPRIATE: {
        title: (t) => t('rdash:suggested_answers_inappropriate_appropriate'),
        optionsKey: 'suggested_answers_inappropriate_appropriate_options',
    },
    MALE_FEMALE_NON_BINARY: {
        title: (t) => t('rdash:suggested_answers_male_female_non_binary'),
        optionsKey: 'suggested_answers_male_female_non_binary_options',
    },
    TRUE_FALSE: {
        title: (t) => t('rdash:suggested_answers_true_false'),
        optionsKey: 'suggested_answers_true_false_options',
    },
    YES_NO_INDETERMINATE: {
        title: (t) => t('rdash:suggested_answers_yes_no_indeterminate'),
        optionsKey: 'suggested_answers_yes_no_indeterminate_options',
    },
    YES_NO_DETERMINATE: {
        title: (t) => t('rdash:suggested_answers_yes_no_determinate'),
        optionsKey: 'suggested_answers_yes_no_determinate_options',
    },
    BELOW_AVG_ABOVE_AVG: {
        title: (t) => t('rdash:suggested_answers_below_avg_above_avg'),
        optionsKey: 'suggested_answers_below_avg_above_avg_options',
    },
    WORSE_BETTER: {
        title: (t) => t('rdash:suggested_answers_worse_better'),
        optionsKey: 'suggested_answers_worse_better_options',
    },
    DIFFICULT_EASY: {
        title: (t) => t('rdash:suggested_answers_difficult_easy'),
        optionsKey: 'suggested_answers_difficult_easy_options',
    },
    INEFFECTIVE_EFFECTIVE: {
        title: (t) => t('rdash:suggested_answers_ineffective_effective'),
        optionsKey: 'suggested_answers_ineffective_effective_options',
    },
    BAD_GOOD: {
        title: (t) => t('rdash:suggested_answers_bad_good'),
        optionsKey: 'suggested_answers_bad_good_options',
    },
    REGRETFUL_DELIGHTFUL: {
        title: (t) => t('rdash:suggested_answers_regretful_delightful'),
        optionsKey: 'suggested_answers_regretful_delightful_options',
    },
    BELIEVABILITY: {
        title: (t) => t('rdash:suggested_answers_believability'),
        optionsKey: 'suggested_answers_believability_options',
    },
    SLOW_FAST: {
        title: (t) => t('rdash:suggested_answers_slow_fast'),
        optionsKey: 'suggested_answers_slow_fast_options',
    },
    UNLIKELY_LIKELY: {
        title: (t) => t('rdash:suggested_answers_unlikely_likely'),
        optionsKey: 'suggested_answers_unlikely_likely_options',
    },
    UNHAPPY_HAPPY: {
        title: (t) => t('rdash:suggested_answers_unhappy_happy'),
        optionsKey: 'suggested_answers_unhappy_happy_options',
    },
    USELESS_USEFUL: {
        title: (t) => t('rdash:suggested_answers_useless_useful'),
        optionsKey: 'suggested_answers_useless_useful_options',
    },
    UNIMPORTANT_IMPORTANT: {
        title: (t) => t('rdash:suggested_answers_unimportant_important'),
        optionsKey: 'suggested_answers_unimportant_important_options',
    },
    DESCRIBES_MY_FEELINGS: {
        title: (t) => t('rdash:suggested_answers_describes_my_feelings'),
        optionsKey: 'suggested_answers_describes_my_feelings_options',
    },
    DESCRIBES_ME: {
        title: (t) => t('rdash:suggested_answers_describes_me'),
        optionsKey: 'suggested_answers_describes_me_options',
    },
    DISPLEASED_PLEASED: {
        title: (t) => t('rdash:suggested_answers_displeased_pleased'),
        optionsKey: 'suggested_answers_displeased_pleased_options',
    },
    NO_PREFERENCE_PREFER: {
        title: (t) => t('rdash:suggested_answers_no_preference_prefer'),
        optionsKey: 'suggested_answers_no_preference_prefer_options',
    },
    DISLIKE_LIKE: {
        title: (t) => t('rdash:suggested_answers_dislike_like'),
        optionsKey: 'suggested_answers_dislike_like_options',
    },
    INTERVALS: {
        title: (t) => t('rdash:suggested_answers_intervals'),
        optionsKey: 'suggested_answers_intervals_options',
    },
    QUANTITY: {
        title: (t) => t('rdash:suggested_answers_quantity'),
        optionsKey: 'suggested_answers_quantity_options',
    },
    EXPECTATIONS: {
        title: (t) => t('rdash:suggested_answers_expectations'),
        optionsKey: 'suggested_answers_expectations_options',
    },
    IDEALS: {
        title: (t) => t('rdash:suggested_answers_ideals'),
        optionsKey: 'suggested_answers_ideals_options',
    },
    NEVER_ALWAYS: {
        title: (t) => t('rdash:suggested_answers_never_always'),
        optionsKey: 'suggested_answers_never_always_options',
    },
    NONE_ALL: {
        title: (t) => t('rdash:suggested_answers_none_all'),
        optionsKey: 'suggested_answers_none_all_options',
    },
    TOO_LITTLE_TOO_MUCH: {
        title: (t) => t('rdash:suggested_answers_too_little_too_much'),
        optionsKey: 'suggested_answers_too_little_too_much_options',
    },
    LOWER_HIGHER: {
        title: (t) => t('rdash:suggested_answers_lower_higher'),
        optionsKey: 'suggested_answers_lower_higher_options',
    },
    WORST_BEST: {
        title: (t) => t('rdash:suggested_answers_worst_best'),
        optionsKey: 'suggested_answers_worst_best_options',
    },
    POOR_EXCELLENT: {
        title: (t) => t('rdash:suggested_answers_poor_excellent'),
        optionsKey: 'suggested_answers_poor_excellent_options',
    },
    CHANCE: {
        title: (t) => t('rdash:suggested_answers_chance'),
        optionsKey: 'suggested_answers_chance_options',
    },
    WEAK_STRONG: {
        title: (t) => t('rdash:suggested_answers_weak_strong'),
        optionsKey: 'suggested_answers_weak_strong_options',
    },
    CLEAR_UNCLEAR: {
        title: (t) => t('rdash:suggested_answers_clear_unclear'),
        optionsKey: 'suggested_answers_clear_unclear_options',
    },
    REASONABLE_UNREASONABLE: {
        title: (t) => t('rdash:suggested_answers_reasonable_unreasonable'),
        optionsKey: 'suggested_answers_reasonable_unreasonable_options',
    },
    COMFORTABLE_UNCOMFORTABLE: {
        title: (t) => t('rdash:suggested_answers_comfortable_uncomfortable'),
        optionsKey: 'suggested_answers_comfortable_uncomfortable_options',
    },
    POSITIVE_NEGATIVE: {
        title: (t) => t('rdash:suggested_answers_positive_negative'),
        optionsKey: 'suggested_answers_positive_negative_options',
    },
    COMPETENT_INCOMPETENT: {
        title: (t) => t('rdash:suggested_answers_competent_incompetent'),
        optionsKey: 'suggested_answers_competent_incompetent_options',
    },
    PROFESSIONAL_UNPROFESSIONAL: {
        title: (t) => t('rdash:suggested_answers_professional_unprofessional'),
        optionsKey: 'suggested_answers_professional_unprofessional_options',
    },
    ADEQUATE_INADEQUATE: {
        title: (t) => t('rdash:suggested_answers_adequate_inadequate'),
        optionsKey: 'suggested_answers_adequate_inadequate_options',
    },
    INTERESTING: {
        title: (t) => t('rdash:suggested_answers_interesting'),
        optionsKey: 'suggested_answers_interesting_options',
    },
    CHALLENGING: {
        title: (t) => t('rdash:suggested_answers_challenging'),
        optionsKey: 'suggested_answers_challenging_options',
    },
    HOW_WELL: {
        title: (t) => t('rdash:suggested_answers_how_well'),
        optionsKey: 'suggested_answers_how_well_options',
    },
    HOW_ACCURATELY: {
        title: (t) => t('rdash:suggested_answers_how_accurately'),
        optionsKey: 'suggested_answers_how_accurately_options',
    },
    KNOWLEDGEABLE: {
        title: (t) => t('rdash:suggested_answers_knowledgeable'),
        optionsKey: 'suggested_answers_knowledgeable_options',
    },
    FAMILIAR: {
        title: (t) => t('rdash:suggested_answers_familiar'),
        optionsKey: 'suggested_answers_familiar_options',
    },
    ETHNICITY: {
        title: (t) => t('rdash:suggested_answers_ethnicity'),
        optionsKey: 'suggested_answers_ethnicity_options',
    },
    MARITAL_STATUS: {
        title: (t) => t('rdash:suggested_answers_marital_status'),
        optionsKey: 'suggested_answers_marital_status_options',
    },
    SEXUAL_ORIENTATION: {
        title: (t) => t('rdash:suggested_answers_sexual_orientation'),
        optionsKey: 'suggested_answers_sexual_orientation_options',
    },
    INCOME_USD: {
        title: (t) => t('rdash:suggested_answers_income_usd'),
        optionsKey: 'suggested_answers_income_usd_options',
    },
    INCOME_EURO: {
        title: (t) => t('rdash:suggested_answers_income_euro'),
        optionsKey: 'suggested_answers_income_euro_options',
    },
    INCOME_YEN_MONTHLY: {
        title: (t) => t('rdash:suggested_answers_income_yen_monthly'),
        optionsKey: 'suggested_answers_income_yen_monthly_options',
    },
    AGE: {
        title: (t) => t('rdash:suggested_answers_age'),
        optionsKey: 'suggested_answers_age_options',
    },
    EMPLOYMENT: {
        title: (t) => t('rdash:suggested_answers_employment'),
        optionsKey: 'suggested_answers_employment_options',
    },
    EDUCATION: {
        title: (t) => t('rdash:suggested_answers_education'),
        optionsKey: 'suggested_answers_education_options',
    },
    INTENT_TO_STAY: {
        title: (t) => t('rdash:suggested_answers_intent_to_stay'),
        optionsKey: 'suggested_answers_intent_to_stay_options',
    },
    LIMIT: {
        title: (t) => t('rdash:suggested_answers_limit'),
        optionsKey: 'suggested_answers_limit_options',
    },
    NONE_VERY_SEVERE: {
        title: (t) => t('rdash:suggested_answers_none_very_severe'),
        optionsKey: 'suggested_answers_none_very_severe_options',
    },
};

// If these IDs are general and not specific to My Progress, we should change the name.
// Also, we should make the enum values a constant.
export const MY_PROGRESS_GROUPING_ID_TO_ENUM = {
    0: 'daily',
    1: 'monthly',
    2: 'weekly',
};

// If these IDs are general and not specific to My Progress, we should change the name.
export const MY_PROGRESS_SORT_ORDER_ID_TO_ENUM = {
    0: SORT_ORDER.ASC,
    1: SORT_ORDER.DESC,
};

export const MAIN_SITE_SSID = 1;

export const AVATAR_COLORS = ['ocean', 'space', 'gray', 'green', 'red', 'yellow', 'orange', 'turquoise', 'violet', 'snow'];

/**
 * All defined user permissions.
 * > If ever needed to be updated, please make sure you'll update the related constant(s) as well.
 */
export const PERMISSION = {
    STUDY: {
        // Protocol
        BASICS: {
            FULL: 'study.basics.full',
            VIEW: 'study.basics.view',
        },
    },
    ACTIVITY: {
        // Protocol
        ACTIVITIES: {
            FULL: 'activity.activities.full',
            VIEW: 'activity.activities.view',
        },
        // Data
        SESSIONS: {
            FULL: 'activity.sessions.full',
            VIEW: 'activity.sessions.view',
        },
        // Data
        RESPONSES: {
            FULL: 'activity.responses.full',
            VIEW: 'activity.responses.view',
        },
    },
    DATA_SOURCE: {
        // Protocol
        DATA_SOURCES: {
            FULL: 'data_source.data_sources.full',
            VIEW: 'data_source.data_sources.view',
        },
        // Data
        SENSOR_DATA: {
            VIEW: 'data_source.sensor_data.view',
        },
    },
    NOTIFICATION: {
        // Data
        NOTIFICATION: {
            FULL: 'notification.notification.full',
            VIEW: 'notification.notification.view',
        },
        // Protocol
        NOTIFICATION_TEMPLATES: {
            FULL: 'notification.notification_templates.full',
            VIEW: 'notification.notification_templates.view',
        },
    },
    REGISTRATION: {
        // Data
        PARTICIPATION: {
            FULL: 'registration.participation.full',
            VIEW: 'registration.participation.view',
        },
    },
};

export const PROTOCOL_VIEW_PERMISSIONS = {
    BASICS: PERMISSION.STUDY.BASICS.VIEW,
    ACTIVITIES: PERMISSION.ACTIVITY.ACTIVITIES.VIEW,
    DATA_SOURCES: PERMISSION.DATA_SOURCE.DATA_SOURCES.VIEW,
    NOTIFICATION_TEMPLATES: PERMISSION.NOTIFICATION.NOTIFICATION_TEMPLATES.VIEW,
};

export const DATA_VIEW_PERMISSIONS = {
    NOTIFICATION: PERMISSION.NOTIFICATION.NOTIFICATION.VIEW,
    PARTICIPATION: PERMISSION.REGISTRATION.PARTICIPATION.VIEW,
    SESSIONS: PERMISSION.ACTIVITY.SESSIONS.VIEW,
    RESPONSES: PERMISSION.ACTIVITY.RESPONSES.VIEW,
    SENSOR_DATA: PERMISSION.DATA_SOURCE.SENSOR_DATA.VIEW,
};

export const PERMISSIONS_TO_VIEW_A_PAGE = {
    ACTIVITIES: [PERMISSION.ACTIVITY.ACTIVITIES.VIEW],
    DATA_SOURCES: [PERMISSION.DATA_SOURCE.DATA_SOURCES.VIEW],
    NOTIFICATIONS: [PERMISSION.NOTIFICATION.NOTIFICATION.VIEW],
    NOTIFICATION_TEMPLATES: [PERMISSION.NOTIFICATION.NOTIFICATION_TEMPLATES.VIEW],
    PARTICIPATION: [PERMISSION.REGISTRATION.PARTICIPATION.VIEW],
    ACTIVITY_SESSIONS: [PERMISSION.ACTIVITY.SESSIONS.VIEW],
    ACTIVITY_RESPONSES: [PERMISSION.ACTIVITY.RESPONSES.VIEW],
    DATA_EXPORT: [PERMISSION.NOTIFICATION.NOTIFICATION.VIEW, PERMISSION.REGISTRATION.PARTICIPATION.VIEW],
};
