/*
  This file is created to prevent a bug that running the Sentry.init function happened after the
  import * as Sentry from '@sentry/browser'; statement in configure-store.* in app-store package.
 */
import omitDeep from 'omit-deep-lodash';
import * as Sentry from '@sentry/browser';

import appUtils from '@edf-pkg/app-utils';

let appLogger = null;

function getErrorDataFromSentryEvent(sentryException) {
    const errorData = {
        message: '',
        lineno: -1,
        funcName: '',
        pathname: '',
    };
    if (sentryException.values && sentryException.values.length > 0 && sentryException.values[0].stacktrace) {
        const stackFrames = sentryException.values[0].stacktrace.frames;
        const latestStackFrame = stackFrames[stackFrames.length - 1];
        errorData.message = sentryException.values[0].value;
        errorData.lineno = latestStackFrame.lineno;
        errorData.funcName = latestStackFrame.function;
        errorData.pathname = latestStackFrame.filename;
    }
    return errorData;
}

function mapSentryEventToBackendLog(event, catchIn) {
    const payload = {
        message: '',
        name: event.extra.name,
        level: event.level,
        userId: event.user.id,
        deviceId: event.user.deviceId,
        stackTrace: '',
        extraInfo: {
            release: event.release,
            logId: event.event_id,
            environment: event.environment,
            userData: {
                username: event.user.username,
                role: event.user.role,
            },
            redux: {
                lastAction: event.extra.lastAction,
                currentState: JSON.stringify(event.extra.state),
            },
            breadcrumbs: omitDeep(event.breadcrumbs, ['data']),
            url: '',
            error: {},
        },
    };

    if (appUtils.object.hasKey(event, 'request')) {
        payload.extraInfo.url = event.request.url;
    }

    if (catchIn === 'AppErrorBoundary') {
        payload.stackTrace = event.extra.errorInfo.componentStack;
    } else {
        payload.stackTrace = event.extra.stackTrace;
    }

    payload.extraInfo.error = getErrorDataFromSentryEvent(event.exception);
    payload.message = payload.extraInfo.error.message;
    return payload;
}

if (!appUtils.env.isDev() && process.env.REACT_APP_RELEASE_FOR !== 'local') {
    Sentry.init({
        dsn: 'https://1e6c96b5779648f6bc055c5c247cae49@sentry.avicennaresearch.com/15',
        tracesSampleRate: 1.0,
        release: `${process.env.REACT_APP_RELEASE_TAG}`,
        environment: process.env.REACT_APP_RELEASE_FOR,
        debug: true,
        attachStacktrace: true,
        normalizeDepth: 10,
        ignoreErrors: ['ResizeObserver loop limit exceeded'],
        beforeBreadcrumb(breadcrumb, hint) {
            try {
                if (breadcrumb.category.startsWith('ui')) {
                    return {
                        ...breadcrumb,
                        // To trace issues easier, we can't simply rely on CSS selectors Sentry uses by default since they're mostly generated automatically in our app.
                        message: `${breadcrumb.message}: ${hint.event.target.innerText.substring(0, 100)}`,
                    };
                }
                // eslint-disable-next-line no-empty
            } catch (e) {}
            return breadcrumb;
        },
        beforeSend(event) {
            // Any error that's handled by our errorHandler will also be logged to backend.
            if (event?.tags?.catch_in === 'errorHandler') {
                if (appLogger) {
                    appLogger.logToBackend(mapSentryEventToBackendLog(event, event.tags.catch_in));
                }
            }

            // Handle `ChunkLoadError` -> Ignore and don't log to neither the backend or Sentry.
            let skipEvent = false;
            if (event?.exception?.values?.length > 0) {
                for (let index = 0; index < event.exception.values.length; index += 1) {
                    const exceptionValue = event.exception.values[index];
                    if (
                        (exceptionValue?.type?.includes('SyntaxError') && exceptionValue?.value?.includes('<')) ||
                        exceptionValue?.type?.includes('ChunkLoadError')
                    ) {
                        skipEvent = true;
                        break;
                    }
                }
            }

            if (skipEvent) {
                return null;
            }

            return event;
        },
    });
}

// eslint-disable-next-line import/prefer-default-export
export function setAppLogger(logger) {
    appLogger = logger;
}
