const deviceInfo = {
    device_id: '',
    manufacturer: '',
    model: '',
    gcm_reg_id: '',
    bt_mac: '',
    wifi_mac: '',
    os: '',
};

export default deviceInfo;
