import { call, put, select, take } from 'redux-saga/effects';
import URLParse from 'url-parse';

import { errorHandle } from '@edf-pkg/app-error';
import { store as i18nStore } from '@edf-pkg/app-i18n';
import { store as mainAppStore } from '@edf-pkg/app-main';
import appUtils from '@edf-pkg/app-utils';

import { duckActionCreators as dashboardActionCreators } from '$app-web/spaces/dashboard/store/dashboard.duck';
import URLsManager from '$app-web/utils/urls-manager';

export const SAGA_NAME = 'SPACE_DASHBOARD';

function loadUserDataFromLocalStorage() {
    let userData = null;
    if (window.localStorage) {
        const localStorageDataRaw = localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_KEY_USER);
        if (localStorageDataRaw !== null) {
            const localStorageData = JSON.parse(localStorageDataRaw);
            if (appUtils.object.hasKey(localStorageData, 'username') && appUtils.object.hasKey(localStorageData, 'apiKey')) {
                userData = {
                    username: localStorageData.username,
                    apiKey: localStorageData.apiKey,
                };
            }
        }
    }
    return userData;
}

function loadUserDataFromQueryParams() {
    let userData = null;
    const parsedURL = new URLParse(window.location.href, true);
    if (
        appUtils.object.hasKey(parsedURL.query, 'username') &&
        parsedURL.query.username !== '' &&
        appUtils.object.hasKey(parsedURL.query, 'api_key') &&
        parsedURL.query.api_key !== ''
    ) {
        userData = {
            username: parsedURL.query.username,
            apiKey: parsedURL.query.api_key,
        };
    }
    return userData;
}

function loadUserDataFromEnv() {
    let userData = null;
    if (appUtils.user.isParticipant(process.env.REACT_APP_USER_ROLE)) {
        userData = {
            username: process.env.REACT_APP_USER_PARTICIPANT_USERNAME,
            apiKey: process.env.REACT_APP_USER_PARTICIPANT_API_KEY,
        };
    } else if (appUtils.user.isResearcher(process.env.REACT_APP_USER_ROLE)) {
        userData = {
            username: process.env.REACT_APP_USER_RESEARCHER_USERNAME,
            apiKey: process.env.REACT_APP_USER_RESEARCHER_API_KEY,
        };
    }
    return userData;
}

function* initialize() {
    if (URLsManager.space('dashboard').inSpace()) {
        try {
            let userDataFromQueryParams = null;
            let userDataFromEnv = null;
            let userDataFromLocalStorage = null;
            if (appUtils.env.isLoadUserDataFromQueryParamsEnabled()) {
                userDataFromQueryParams = yield call(loadUserDataFromQueryParams);
            }
            if (appUtils.env.isLoadUserDataFromEnvEnabled()) {
                userDataFromEnv = yield call(loadUserDataFromEnv);
            }
            if (appUtils.env.isLoadUserDataFromLocalStorageEnabled() || appUtils.env.isTest()) {
                userDataFromLocalStorage = yield call(loadUserDataFromLocalStorage);
            }

            const userData = userDataFromEnv || userDataFromQueryParams || userDataFromLocalStorage;

            yield put(mainAppStore.userDuck.duckActionCreators.loadUserDataFromSpaceSucceeded(userData, false, true, true));
            yield take(i18nStore.i18nDuck.duckActions.ASK_SPACE_FOR_LANGUAGE_NAMESPACE);
            const userRole = yield select(mainAppStore.userDuck.duckSelectors.roleSelector);
            yield put(
                i18nStore.i18nDuck.duckActionCreators.askSpaceForLanguageNamespaceSucceeded(
                    userRole === 'researcher' ? 'rdash' : 'pdash'
                )
            );
        } catch (error) {
            errorHandle.anError(error);
            yield put(mainAppStore.userDuck.duckActionCreators.loadUserDataFromSpaceFailed());
        }
    }
}

function* handleAuthAction() {
    const parsedURL = URLsManager.getParsedURL();
    if (Object.keys(parsedURL.query).length > 0 && appUtils.object.hasKey(parsedURL.query, 'authAction')) {
        const { query } = parsedURL;
        const actionType = query.authAction;
        const actionParams = { ...query };
        delete actionParams.authAction;
        yield put(
            dashboardActionCreators.setAuthAction({
                type: actionType,
                params: actionParams,
            })
        );
    }
}

export default function* dashboardSaga() {
    yield call(handleAuthAction);
    yield take(mainAppStore.userDuck.duckActions.ASK_SPACE_TO_LOAD_USER_DATA);
    yield call(initialize);
}
