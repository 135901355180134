import asModule from '$app-web/hoces/as-module';
import lazyLoad, { withTimeoutAndDelay } from '$app-web/utils/lazy-load';
import URLsManager from '$app-web/utils/urls-manager';

URLsManager.space('public').addModule('public').setURLs({
    AUTH_ACTION: '/:action',
    ACTIVITY_BY_SESSION: '/activity/:activityType/:sessionUUID',
    ACTIVITY_BY_TL_ID: '/activity/:activityType/:activityId/tl/:tlId',
    ANDROID_APP_TERMINATION: '/android-app-termination/:appName/:manufacturer/:instruction',
    CDS_GRANTED: '/cds_access_granted/',
    EDEN: '/eden/:token',
});

const PublicModule = lazyLoad(() => withTimeoutAndDelay(() => import('./components/public-module')));

function shouldRender() {
    return URLsManager.space('public').inSpace();
}

export default asModule(shouldRender, 'public.public')(PublicModule);
