import { call, put, select, take } from 'redux-saga/effects';

import { store as i18nStore } from '@edf-pkg/app-i18n';

import { INITIALIZATION_STATUS } from '../constants';
import initializeYup from '../utils/yup.util';
import { duckActionCreators as mainActionCreators, duckActions as mainActions } from './app.duck';
import {
    duckActionCreators as userActionCreators,
    duckActions as userActions,
    duckSelectors as userSelectors,
} from './user.duck';

export const SAGA_NAME = 'APP';

function* initialize() {
    yield put(mainActionCreators.initializationStarted());
    yield call(initializeYup);
    yield put(userActionCreators.initialize());
    let userInitializationAction = yield take(userActions.SET_INITIALIZATION_STATUS);
    if (userInitializationAction.payload.status === INITIALIZATION_STATUS.STARTED) {
        userInitializationAction = yield take(userActions.SET_INITIALIZATION_STATUS);
        if (userInitializationAction.payload.status !== INITIALIZATION_STATUS.SUCCEEDED) {
            yield put(mainActionCreators.initializationFailed());
            return;
        }
    } else if (userInitializationAction.payload.status !== INITIALIZATION_STATUS.SUCCEEDED) {
        yield put(mainActionCreators.initializationFailed());
        return;
    }

    const userLanguage = yield select((state) => userSelectors.languageSelector(state));
    yield put(i18nStore.i18nDuck.duckActionCreators.initialize(userLanguage));
    const i18nInitializationAction = yield take(i18nStore.i18nDuck.duckActions.SET_INITIALIZATION_STATUS);
    if (i18nInitializationAction.payload.status !== INITIALIZATION_STATUS.SUCCEEDED) {
        yield put(mainActionCreators.initializationFailed());
        return;
    }

    yield put(mainActionCreators.initializationSucceeded());
}

export default function* appSaga() {
    yield take(mainActions.INITIALIZE);
    yield call(initialize);
}
