import { pick } from 'lodash-es';
import { delay } from 'redux-saga';
import { call, put, race, select, take, takeEvery } from 'redux-saga/effects';

import getDeviceInfo from '@edf-pkg/app-device-info';
import { errorHandle, FatalError } from '@edf-pkg/app-error';
import { store as i18nStore } from '@edf-pkg/app-i18n';
import appURLsManager from '@edf-pkg/app-urls-manager';
import appUtils from '@edf-pkg/app-utils';

import client from '../client';
import {
    duckActionCreators as userActionCreators,
    duckActions as userActions,
    duckSelectors as userSelectors,
} from './user.duck';

export const SAGA_NAME = 'USER';

function* logout(action) {
    const { onSuccess } = action.payload;
    try {
        yield put(userActionCreators.logoutLoading());
        yield call(client.api.userLogout);
        yield put(userActionCreators.setUserData({}));
        yield put(userActionCreators.resetInitializationStatus());
        if (window.localStorage) {
            yield call(() => localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_KEY_USER));
        }
        yield put(userActionCreators.logoutSucceeded());
        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (error) {
        errorHandle.anError(error);
        yield put(userActionCreators.logoutFailed());
    }
}

function normalizeUserDataFromAPI(userData) {
    const { avatar, orgName, mfaEnabled, phone } = userData;

    return {
        ...pick(userData, [
            'username',
            'apiKey',
            'role',
            'id',
            'firstName',
            'lastName',
            'language',
            'ageId',
            'genderId',
            'educationId',
            'maritalStatusId',
            'occupationId',
            'salaryId',
            'isEmailVerified',
            'isPhoneVerified',
            'matrixPassword',
            'subscriptionConfigs',
            'preferredNotificationMediumIds',
        ]),
        isMFAEnabled: mfaEnabled,
        phoneNumber: phone,
        avatar: appURLsManager.server.getURLTo(avatar),
        organization: orgName,
    };
}

function persistUserData(userData) {
    if (window.localStorage) {
        localStorage.setItem(
            process.env.REACT_APP_LOCAL_STORAGE_KEY_USER,
            JSON.stringify({
                username: userData.username,
                apiKey: userData.apiKey,
            })
        );
    }
}

function* handleUserDeviceIdFromSpace(userDataFromSpace) {
    if (appUtils.object.hasKey(userDataFromSpace, 'deviceId')) {
        const { deviceId } = userDataFromSpace;
        if (window.localStorage) {
            localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_KEY_DEVICE_ID, deviceId);
        }
        yield put(userActionCreators.setDeviceId(deviceId));
    }
}

function* initializeUserDeviceId() {
    let deviceId = '';

    // Check if a previous deviceId was set in local storage or not, if so use it
    if (window.localStorage) {
        const localStorageDeviceId = localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_KEY_DEVICE_ID);
        if (localStorageDeviceId) {
            deviceId = localStorageDeviceId;
        }
    }

    // No device info provided. So create new one
    if (deviceId === '') {
        const deviceInfo = getDeviceInfo();
        deviceId = deviceInfo.device_id;

        // Save the deviceId in local storage for future usage
        if (window.localStorage) {
            localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_KEY_DEVICE_ID, deviceId);
        }
    }

    yield put(userActionCreators.setDeviceId(deviceId));
}

function* setUserData(userData, shouldPersistUserData, shouldLoadFromAPI, shouldSetInitializationStatus = false) {
    if (shouldPersistUserData) {
        yield call(persistUserData, userData);
    }

    if (userData.language) {
        yield put(i18nStore.i18nDuck.duckActionCreators.changeLanguage(userData.language));
    }

    yield put(userActionCreators.setUserData(userData));
    if (shouldLoadFromAPI) {
        // eslint-disable-next-line no-use-before-define
        yield call(loadUserDataFromAPI, { payload: { shouldSetInitializationStatus } });
    } else if (shouldSetInitializationStatus) {
        yield put(userActionCreators.initializationSucceeded());
    }
}

function* loadUserDataFromAPI(action = { payload: { shouldPersistUserData: false, shouldSetInitializationStatus: false } }) {
    const { shouldSetInitializationStatus, shouldPersistUserData } = action.payload;

    if (shouldSetInitializationStatus) {
        yield put(userActionCreators.initializationStarted());
    }
    const username = yield select((state) => userSelectors.usernameSelector(state));
    const apiKey = yield select((state) => userSelectors.apiKeySelector(state));
    const deviceId = yield select((state) => userSelectors.deviceIdSelector(state));
    if (username && username !== '' && apiKey && apiKey !== '') {
        try {
            const userData = yield call(client.api.userRead, deviceId);
            const normalizedUserData = normalizeUserDataFromAPI(userData);
            yield call(setUserData, normalizedUserData, shouldPersistUserData);
            if (shouldSetInitializationStatus) {
                yield put(userActionCreators.initializationSucceeded());
            }
        } catch (error) {
            errorHandle.anError(error);
            yield call(setUserData, { username: '', password: '' });
            if (shouldSetInitializationStatus) {
                yield put(userActionCreators.initializationFailed());
            }
        }
    }
    if (shouldSetInitializationStatus) {
        yield put(userActionCreators.initializationSucceeded());
    }
}

function* setUserDataExternal(action) {
    const {
        payload: { userData, actionSource, shouldLoadFromAPI, shouldPersistUserData, shouldSetInitializationStatus },
    } = action;
    if (userData) {
        const normalizedUserData = normalizeUserDataFromAPI(userData);
        yield put(userActionCreators.setUserDataExternalActionSource(actionSource));
        yield call(setUserData, normalizedUserData, shouldPersistUserData, shouldLoadFromAPI, shouldSetInitializationStatus);
    }
}

function* askSpaceToLoadUserData() {
    yield put(userActionCreators.askSpaceToLoadUserData());
    const result = yield race({
        success: take(userActions.LOAD_USER_DATA_FROM_SPACE_SUCCEEDED),
        error: take(userActions.LOAD_USER_DATA_FROM_SPACE_FAILED),
        timeout: delay(60000),
    });
    if (result.error) {
        throw new FatalError('An error occurred during askSpaceToSetUserData.');
    } else if (result.timeout) {
        throw new FatalError('Timeout occurred during askSpaceToSetUserData.');
    } else {
        const {
            success: {
                payload: { userData, shouldLoadFromAPI, shouldPersistUserData },
            },
        } = result;
        if (userData) {
            yield call(handleUserDeviceIdFromSpace, userData);
            yield call(setUserData, userData, shouldPersistUserData, shouldLoadFromAPI, true);
        } else {
            yield put(userActionCreators.initializationSucceeded());
        }
    }
}

function* initialize() {
    try {
        yield put(userActionCreators.reset());
        yield call(initializeUserDeviceId);
        yield call(askSpaceToLoadUserData);
    } catch (error) {
        errorHandle.anError(error);
        if (appUtils.object.hasKey(error, 'status') && error.status < 500) {
            yield put(userActionCreators.initializationSucceeded());
        } else {
            yield put(userActionCreators.initializationFailed());
        }
    }
}

export default function* userSaga() {
    yield take(userActions.INITIALIZE);
    yield takeEvery(userActions.SET_USER_DATA_EXTERNAL, setUserDataExternal);
    yield takeEvery(userActions.LOAD_USER_DATA_FROM_API, loadUserDataFromAPI);
    yield takeEvery(userActions.LOGOUT, logout);
    yield call(initialize);
}
