import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

import mainBackgroundPattern from '@edf-pkg/assets/images/backgrounds/main-background-pattern.png';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        textAlign: 'center',
        width: '100%',
        height: '100%',
    },
    background: {
        background: `url(${mainBackgroundPattern})`,
        backgroundSize: 'cover',
    },
    mainWrapper: { height: '100%', flexGrow: 0 },
    logoImage: {
        height: 80,
        marginBottom: theme.spacing(4.5),
        [theme.breakpoints.down('sm')]: {
            marginBottom: '40vh',
        },
    },
    button: {
        width: 180,
        marginTop: theme.spacing(3),
    },
});

const loadingIndicator = (theme) => ({
    root: {
        height: theme.spacing(0.5),
        borderRadius: 5,
        width: 80,
        display: 'inline-block',
    },
    indeterminate: {
        backgroundColor: theme.palette.logo.blue.main,
    },
    bar1Indeterminate: {
        backgroundColor: theme.palette.logo.yellow.main,
    },
    bar2Indeterminate: {
        backgroundColor: theme.palette.logo.green.main,
    },
});

export const LoadingIndicator = withStyles(loadingIndicator)(LinearProgress);

export default styles;
