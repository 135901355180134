import PromiseWithTimeout from './promise-with-timeout';

export default class NativeToJsFunctionHelperAndroid {
    back = () => {
        // eslint-disable-next-line no-console
        console.log('Calling injected back');
        return PromiseWithTimeout((resolve, reject) => {
            try {
                window.WebActivityRef.back();
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };

    exit = () => {
        // eslint-disable-next-line no-console
        console.log('Calling injected exit');
        return PromiseWithTimeout((resolve, reject) => {
            try {
                window.WebActivityRef.exit();
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };

    onLoadingCompleted = () => {
        // eslint-disable-next-line no-console
        console.log('Calling injected onLoadingCompleted');
        return PromiseWithTimeout((resolve, reject) => {
            try {
                window.WebActivityRef.onLoadingCompleted();
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };

    onLoadingFailed = () => {
        // eslint-disable-next-line no-console
        console.log('Calling injected onLoadingFailed');
        return PromiseWithTimeout((resolve, reject) => {
            try {
                window.WebActivityRef.onLoadingFailed();
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };

    getCurrentUserCredentials = () => {
        // eslint-disable-next-line no-console
        console.log('Calling injected getCurrentUserCredentials');
        return PromiseWithTimeout((resolve, reject) => {
            try {
                const data = window.WebActivityRef.getCurrentUserCredentials();
                if (data && typeof data === 'string') {
                    resolve(JSON.parse(data));
                } else {
                    reject(new Error('Returned value not supported.'));
                }
            } catch (error) {
                reject(error);
            }
        });
    };

    getOptions = () => {
        // eslint-disable-next-line no-console
        console.log('Calling injected getOptions');
        return PromiseWithTimeout((resolve, reject) => {
            try {
                const data = window.WebActivityRef.getOptions();
                if (data && typeof data === 'string') {
                    resolve(JSON.parse(data));
                } else {
                    reject(new Error('Returned value not supported.'));
                }
            } catch (error) {
                reject(error);
            }
        });
    };

    getSessionInfo = () => {
        // eslint-disable-next-line no-console
        console.log('Calling injected getSessionInfo');
        return PromiseWithTimeout((resolve, reject) => {
            try {
                const data = window.WebActivityRef.getSessionInfo();
                if (data && typeof data === 'string') {
                    resolve(JSON.parse(data));
                } else {
                    reject(new Error('Returned value not supported.'));
                }
            } catch (error) {
                reject(error);
            }
        });
    };
}
