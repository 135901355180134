import omitDeep from 'omit-deep-lodash';
import { applyMiddleware, createStore } from 'redux';
import createReduxPromiseListener from 'redux-promise-listener';
import createSagaMiddleware from 'redux-saga';
import createSentryMiddleware from 'redux-sentry-middleware';
import * as Sentry from '@sentry/browser';

import appUtils from '@edf-pkg/app-utils';

import ducksManager from '../ducks-manager';
import sagasManager from '../sagas-manager';

const reduxPromiseListener = createReduxPromiseListener();
let store = null;
export default function configureStore(initialState = {}) {
    // eslint-disable-next-line no-underscore-dangle
    const sagaMiddleware = createSagaMiddleware();
    const sentryMiddleware = createSentryMiddleware(Sentry, {
        stateTransformer(state) {
            return omitDeep(state, [
                'initializationStatus',
                'app',
                'snackbar',
                'user',
                'dialog',
                'phr',
                'app-web',
                'data',
                'studies',
                'join-study',
                'study',
                'settings-user',
                'survey',
                'answer-indicator',
            ]);
        },
        getUserContext(state) {
            if (appUtils.object.hasKey(state, 'main') && appUtils.object.hasKey(state.main, 'user')) {
                const { id, username, role, deviceId } = state.main.user;
                return { id, username, role, deviceId };
            }
            return {};
        },
    });

    store = createStore(
        ducksManager.combine(),
        initialState,
        applyMiddleware(sagaMiddleware, sentryMiddleware, reduxPromiseListener.middleware)
    );

    ducksManager.setChangeListener(() => {
        store.replaceReducer(ducksManager.combine());
    });

    sagasManager.setStore(store);
    sagasManager.setSagaMiddleware(sagaMiddleware);

    sagasManager.setChangeListener(() => {
        sagasManager.startSagas();
    });

    return { store };
}

export function getStore() {
    return store;
}

export const promiseListener = reduxPromiseListener;
