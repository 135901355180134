import PromiseWithTimeout from './promise-with-timeout';

export default class NativeToJsFunctionHelperIos {
    back = () => {
        // eslint-disable-next-line no-console
        console.log('Calling injected back');
        return PromiseWithTimeout((resolve, reject) => {
            try {
                window.webkit.messageHandlers.back.postMessage('');
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };

    exit = () => {
        // eslint-disable-next-line no-console
        console.log('Calling injected exit');
        return PromiseWithTimeout((resolve, reject) => {
            try {
                window.webkit.messageHandlers.exit.postMessage('');
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };

    onLoadingCompleted = () => {
        // eslint-disable-next-line no-console
        console.log('Calling injected onLoadingCompleted');
        return PromiseWithTimeout((resolve, reject) => {
            try {
                window.webkit.messageHandlers.onLoadingCompleted.postMessage('');
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };

    onLoadingFailed = () => {
        // eslint-disable-next-line no-console
        console.log('Calling injected onLoadingFailed');
        return PromiseWithTimeout((resolve, reject) => {
            try {
                window.webkit.messageHandlers.onLoadingFailed.postMessage('');
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };

    getCurrentUserCredentials = () => {
        // eslint-disable-next-line no-console
        console.log('Calling injected getCurrentUserCredentials');
        return PromiseWithTimeout((resolve, reject) => {
            this.registerGetCurrentUserCredentialsCallback(resolve, reject);
            try {
                window.webkit.messageHandlers.getCurrentUserCredentials.postMessage('');
            } catch (error) {
                reject(error);
            }
        });
    };

    getOptions = () => {
        // eslint-disable-next-line no-console
        console.log('Calling injected getOptions');
        return PromiseWithTimeout((resolve, reject) => {
            this.registerGetOptionsCallback(resolve, reject);
            try {
                window.webkit.messageHandlers.getOptions.postMessage('');
            } catch (error) {
                reject(error);
            }
        });
    };

    getSessionInfo = () => {
        // eslint-disable-next-line no-console
        console.log('Calling injected getSessionInfo');

        return PromiseWithTimeout((resolve, reject) => {
            this.registerGetSessionInfoCallback(resolve, reject);
            try {
                window.webkit.messageHandlers.getSessionInfo.postMessage('');
            } catch (error) {
                reject(error);
            }
        });
    };

    registerGetCurrentUserCredentialsCallback = (resolve, reject) => {
        window.getCurrentUserCredentialsCallback = function getCurrentUserCredentialsCallback(data) {
            if (data && typeof data === 'string') {
                window.getCurrentUserCredentialsCallback = null;
                resolve(JSON.parse(data));
            } else {
                window.getCurrentUserCredentialsCallback = null;
                reject(new Error('Returned value not supported.'));
            }
        };
    };

    registerGetOptionsCallback = (resolve, reject) => {
        window.getOptionsCallback = function getOptionsCallback(data) {
            if (data && typeof data === 'string') {
                window.getOptionsCallback = null;
                resolve(JSON.parse(data));
            } else {
                window.getOptionsCallback = null;
                reject(new Error('Returned value not supported.'));
            }
        };
    };

    registerGetSessionInfoCallback = (resolve, reject) => {
        window.getSessionInfoCallback = function getSessionInfoCallback(data) {
            if (data && typeof data === 'string') {
                window.getSessionInfoCallback = null;
                resolve(JSON.parse(data));
            } else {
                window.getSessionInfoCallback = null;
                reject(new Error('Returned value not supported.'));
            }
        };
    };
}
