import snakecaseKeys from 'snakecase-keys';
import { object, string } from 'yup';

import { getStore } from '@edf-pkg/app-store';

import { activityDuck } from '../store';

export const getData = (activityUUID, snakeCase) => {
    const state = getStore().getState();
    const sessionUUID = activityDuck.duckSelectors.sessionUUIDSelector(state)(activityUUID);

    const authData = {
        sessionUuid: sessionUUID || undefined,
    };

    return snakeCase ? snakecaseKeys(authData) : authData;
};

export const getSchema = () =>
    object().shape({
        session_uuid: string().optional(),
    });
