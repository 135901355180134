import React from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle, withStyles } from '@material-ui/core';

import { asActivity } from '@edf-pkg/app-activities';

import Asa24Activity from '$app-web/activities/asa24';
import MatrixReasoningActivity from '$app-web/activities/matrix-reasoning';
import MyProgressActivity from '$app-web/activities/my-progress';
import PageActivity from '$app-web/activities/page';
import ProgressRewardActivity from '$app-web/activities/progress-reward';
import StepsActivity from '$app-web/activities/steps';
import StroopActivity from '$app-web/activities/stroop';
import SummaryPageActivity from '$app-web/activities/summary-page';
import SurveyActivity from '$app-web/activities/survey';
import TimePrefActivity from '$app-web/activities/time-pref';
import TimeUseActivity from '$app-web/activities/time-use';
import WheelOfFortune from '$app-web/activities/wheel-of-fortune';
import Loading from '$app-web/components/loading';
import BigButton from '$app-web/components/web-activities-big-button';

import styles from './styles';

const Activity = (props) => {
    const {
        type,
        isActivityLoadDataStatusLoading,
        isActivityLoadDataStatusSucceeded,
        isActivityLoadDataStatusFailed,
        isActivityStatusAnswerable,
        isActivityStatusUpcoming,
        isActivityStatusPast,
        isActivityStatusFinished,
        closingInProgress,
        onClose,
        classes,
        showError,
        showFinishedBack,
    } = props;

    const { t } = useTranslation();
    return (
        <div className={classes.root}>
            <Loading
                variant="parent-overlay"
                loading={isActivityLoadDataStatusLoading || closingInProgress}
                error={showError && isActivityLoadDataStatusFailed}
                showErrorText
                errorText={t('generic_error_msg')}
                errorHasButton
                errorButtonText={t('back')}
                onErrorButtonClick={onClose}
            />
            {(isActivityStatusUpcoming || isActivityStatusPast) && (
                <Dialog open fullScreen>
                    <DialogTitle>
                        {isActivityStatusUpcoming && t('webActivities:activity_upcoming')}
                        {isActivityStatusPast && t('webActivities:activity_past')}
                    </DialogTitle>
                    <DialogContent />
                    {showFinishedBack && (
                        <DialogActions className={classes.dialogActionsWrapper}>
                            <BigButton handleClick={() => onClose()} text={t('back')} />
                        </DialogActions>
                    )}
                </Dialog>
            )}
            {isActivityStatusFinished && (
                <Dialog open fullScreen>
                    <DialogTitle>{t('webActivities:activity_completed')}</DialogTitle>
                    <DialogContent />
                    {showFinishedBack && (
                        <DialogActions className={classes.dialogActionsWrapper}>
                            <BigButton handleClick={() => onClose(true)} text={t('back')} />
                        </DialogActions>
                    )}
                </Dialog>
            )}
            {isActivityLoadDataStatusSucceeded && isActivityStatusAnswerable && (
                <span className={clsx({ [classes.hiddenActivity]: closingInProgress })}>
                    {type === 'survey' && <SurveyActivity {...props} />}
                    {type === 'time-use' && <TimeUseActivity {...props} />}
                    {type === 'stroop' && <StroopActivity {...props} />}
                    {type === 'matrix-reasoning' && <MatrixReasoningActivity {...props} />}
                    {type === 'wheel-of-fortune' && <WheelOfFortune {...props} />}
                    {type === 'time-pref' && <TimePrefActivity {...props} />}
                    {type === 'asa24' && <Asa24Activity {...props} />}
                    {type === 'page' && <PageActivity {...props} />}
                    {type === 'summary-page' && <SummaryPageActivity {...props} />}
                    {type === 'progress-reward' && <ProgressRewardActivity {...props} />}
                    {(type === 'steps-leaderboard' || type === 'steps-performance') && <StepsActivity {...props} />}
                    {type === 'my-progress' && <MyProgressActivity {...props} />}
                </span>
            )}
        </div>
    );
};

Activity.defaultProps = {
    onClose: () => {},
    ensurePHRSent: false,
    showError: true,
    showFinishedBack: true,
};

Activity.propTypes = {
    type: PropTypes.string.isRequired,
    isActivityLoadDataStatusLoading: PropTypes.bool.isRequired,
    isActivityLoadDataStatusSucceeded: PropTypes.bool.isRequired,
    isActivityLoadDataStatusFailed: PropTypes.bool.isRequired,
    isActivityStatusAnswerable: PropTypes.bool.isRequired,
    isActivityStatusUpcoming: PropTypes.bool.isRequired,
    isActivityStatusPast: PropTypes.bool.isRequired,
    isActivityStatusFinished: PropTypes.bool.isRequired,
    closingInProgress: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    ensurePHRSent: PropTypes.bool,
    showError: PropTypes.bool,
    showFinishedBack: PropTypes.bool,
};

export default asActivity(withStyles(styles)(Activity));
