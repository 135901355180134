import produce from 'immer';
import { createSelector } from 'reselect';
import { getSelector, namespaceReducerActions } from 'retrack';

import { HTTP_CALL_STATUS, INITIALIZATION_STATUS } from '../constants';

export const DUCK_NAME = 'user';

// TODO: Refactor the state tree.
const initialState = {
    initializationStatus: INITIALIZATION_STATUS.UNKNOWN,
    username: '',
    apiKey: '',
    role: '',
    id: 0,
    firstName: '',
    lastName: '',
    avatar: '',
    // TODO: replace 'base' with TRANSLATION_BASE_ID
    language: 'base',
    deviceId: '',
    organization: '',
    externalUserDataActionSource: '',
    ageId: 0,
    genderId: 0,
    educationId: 0,
    maritalStatusId: 0,
    occupationId: 0,
    salaryId: 0,
    isEmailVerified: false,
    isPhoneVerified: false,
    isMFAEnabled: false,
    phoneNumber: '',
    logoutStatus: HTTP_CALL_STATUS.UNKNOWN,
    matrixPassword: '',
    subscriptionConfigs: {},
    preferredNotificationMediumIds: [],
};

export const duckActions = {
    RESET: 'INITIALIZE',
    INITIALIZE: 'INITIALIZE',
    SET_INITIALIZATION_STATUS: 'SET_INITIALIZATION_STATUS',
    ASK_SPACE_TO_LOAD_USER_DATA: 'ASK_SPACE_TO_LOAD_USER_DATA',
    LOAD_USER_DATA_FROM_SPACE_SUCCEEDED: 'LOAD_USER_DATA_FROM_SPACE_SUCCEEDED',
    LOAD_USER_DATA_FROM_SPACE_FAILED: 'LOAD_USER_DATA_FROM_SPACE_FAILED',
    LOAD_USER_DATA_FROM_API: 'LOAD_USER_DATA_FROM_API',
    SET_USER_DATA: 'SET_USER_DATA',
    SET_USERNAME: 'SET_USERNAME',
    SET_DEVICE_ID: 'SET_DEVICE_ID',
    SET_PHONE_NUMBER: 'SET_PHONE_NUMBER',
    SET_IS_EMAIL_VERIFIED: 'SET_IS_EMAIL_VERIFIED',
    SET_IS_PHONE_VERIFIED: 'SET_IS_PHONE_VERIFIED',
    SET_USER_DATA_EXTERNAL: 'SET_USER_DATA_EXTERNAL',
    LOGOUT: 'LOGOUT',
    SET_USER_DATA_EXTERNAL_ACTION_SOURCE: 'SET_USER_DATA_EXTERNAL_ACTION_SOURCE',
    SET_LOGOUT_STATUS: 'SET_LOGOUT_STATUS',
};

const reducer = produce((draft, action) => {
    switch (action.type) {
        case duckActions.RESET:
            return initialState;
        case duckActions.SET_INITIALIZATION_STATUS:
            draft.initializationStatus = action.payload.status;
            break;
        case duckActions.SET_USER_DATA:
            draft.username = action.payload.userData.username || '';
            draft.apiKey = action.payload.userData.apiKey || '';
            draft.role = action.payload.userData.role || '';
            draft.id = action.payload.userData.id ?? 0;
            draft.firstName = action.payload.userData.firstName || '';
            draft.lastName = action.payload.userData.lastName || '';
            draft.avatar = action.payload.userData.avatar || '';
            // TODO: replace 'base' with TRANSLATION_BASE_ID
            draft.language = action.payload.userData.language || 'base';
            draft.organization = action.payload.userData.organization || '';
            draft.ageId = action.payload.userData.ageId || 0;
            draft.genderId = action.payload.userData.genderId || 0;
            draft.educationId = action.payload.userData.educationId || 0;
            draft.maritalStatusId = action.payload.userData.maritalStatusId || 0;
            draft.occupationId = action.payload.userData.occupationId || 0;
            draft.salaryId = action.payload.userData.salaryId || 0;
            draft.isEmailVerified = action.payload.userData.isEmailVerified || false;
            draft.isPhoneVerified = action.payload.userData.isPhoneVerified || false;
            draft.isMFAEnabled = action.payload.userData.isMFAEnabled || false;
            draft.phoneNumber = action.payload.userData.phoneNumber || '';
            draft.matrixPassword = action.payload.userData.matrixPassword;
            draft.subscriptionConfigs = action.payload.userData.subscriptionConfigs;
            draft.preferredNotificationMediumIds = action.payload.userData.preferredNotificationMediumIds;
            break;
        case duckActions.SET_USERNAME:
            draft.username = action.payload.username;
            break;
        case duckActions.SET_USER_DATA_EXTERNAL_ACTION_SOURCE:
            draft.externalUserDataActionSource = action.payload.actionSource;
            break;
        case duckActions.SET_DEVICE_ID:
            draft.deviceId = action.payload.deviceId;
            break;
        case duckActions.SET_PHONE_NUMBER:
            draft.phoneNumber = action.payload.phoneNumber;
            break;
        case duckActions.SET_IS_PHONE_VERIFIED:
            draft.isPhoneVerified = action.payload.verified;
            break;
        case duckActions.SET_IS_EMAIL_VERIFIED:
            draft.isEmailVerified = action.payload.verified;
            break;
        case duckActions.SET_LOGOUT_STATUS:
            draft.logoutStatus = action.payload.status;
            break;
        // no default
    }
    return draft;
}, initialState);

namespaceReducerActions(reducer, duckActions);

export default reducer;

export const duckActionCreators = {
    reset: function reset() {
        return { type: duckActions.RESET };
    },
    initialize: function initialize() {
        return { type: duckActions.INITIALIZE };
    },
    initializationSucceeded: function initializationSucceeded() {
        return {
            type: duckActions.SET_INITIALIZATION_STATUS,
            payload: { status: INITIALIZATION_STATUS.SUCCEEDED },
        };
    },
    initializationFailed: function initializationFailed() {
        return {
            type: duckActions.SET_INITIALIZATION_STATUS,
            payload: { status: INITIALIZATION_STATUS.FAILED },
        };
    },
    initializationStarted: function initializationStarted() {
        return {
            type: duckActions.SET_INITIALIZATION_STATUS,
            payload: { status: INITIALIZATION_STATUS.STARTED },
        };
    },
    resetInitializationStatus: function resetInitializationStatus() {
        return {
            type: duckActions.SET_INITIALIZATION_STATUS,
            payload: { status: INITIALIZATION_STATUS.UNKNOWN },
        };
    },
    loadUserDataFromAPI: function loadUserDataFromAPI(options = { shouldPersistUserData: false }) {
        return {
            type: duckActions.LOAD_USER_DATA_FROM_API,
            payload: { shouldPersistUserData: options.shouldPersistUserData },
        };
    },
    setUserData: function setUserData(userData) {
        return { type: duckActions.SET_USER_DATA, payload: { userData } };
    },
    setUserDataExternal: function setUserDataExternal(
        userData,
        actionSource,
        shouldPersistUserData = false,
        shouldLoadFromAPI = false,
        shouldSetInitializationStatus = false
    ) {
        return {
            type: duckActions.SET_USER_DATA_EXTERNAL,
            payload: {
                userData,
                actionSource,
                shouldLoadFromAPI,
                shouldPersistUserData,
                shouldSetInitializationStatus,
            },
        };
    },
    setUserDataExternalActionSource: function setUserDataExternalActionSource(actionSource) {
        return {
            type: duckActions.SET_USER_DATA_EXTERNAL_ACTION_SOURCE,
            payload: {
                actionSource,
            },
        };
    },
    askSpaceToLoadUserData: function setUserDataFromAPI() {
        return {
            type: duckActions.ASK_SPACE_TO_LOAD_USER_DATA,
        };
    },
    loadUserDataFromSpaceSucceeded: function loadUserDataFromSpaceSucceeded(
        userData,
        shouldPersistUserData = false,
        shouldLoadFromAPI = false,
        shouldRedirectToOldDashboard = false
    ) {
        return {
            type: duckActions.LOAD_USER_DATA_FROM_SPACE_SUCCEEDED,
            payload: {
                userData,
                shouldPersistUserData,
                shouldLoadFromAPI,
                shouldRedirectToOldDashboard,
            },
        };
    },
    loadUserDataFromSpaceFailed: function loadUserDataFromSpaceFailed() {
        return {
            type: duckActions.LOAD_USER_DATA_FROM_SPACE_FAILED,
        };
    },
    setUsername(username) {
        return {
            type: duckActions.SET_USERNAME,
            payload: { username },
        };
    },
    setDeviceId(deviceId) {
        return {
            type: duckActions.SET_DEVICE_ID,
            payload: { deviceId },
        };
    },
    setPhoneNumber(phoneNumber) {
        return {
            type: duckActions.SET_PHONE_NUMBER,
            payload: { phoneNumber },
        };
    },
    setEmailVerified() {
        return {
            type: duckActions.SET_IS_EMAIL_VERIFIED,
            payload: { verified: true },
        };
    },
    setPhoneNumberNotVerified() {
        return {
            type: duckActions.SET_IS_PHONE_VERIFIED,
            payload: { verified: false },
        };
    },
    setPhoneNumberVerified() {
        return {
            type: duckActions.SET_IS_PHONE_VERIFIED,
            payload: { verified: true },
        };
    },
    logout: function logout(onSuccess) {
        return {
            type: duckActions.LOGOUT,
            payload: { onSuccess },
        };
    },
    logoutLoading() {
        return {
            type: duckActions.SET_LOGOUT_STATUS,
            payload: {
                status: HTTP_CALL_STATUS.LOADING,
            },
        };
    },
    logoutSucceeded() {
        return {
            type: duckActions.SET_LOGOUT_STATUS,
            payload: {
                status: HTTP_CALL_STATUS.SUCCEEDED,
            },
        };
    },
    logoutFailed() {
        return {
            type: duckActions.SET_LOGOUT_STATUS,
            payload: {
                status: HTTP_CALL_STATUS.FAILED,
            },
        };
    },
};

const userSelector = getSelector(reducer);

const apiKeySelector = createSelector(userSelector, (userState) => userState.apiKey);

const usernameSelector = createSelector(userSelector, (userState) => userState.username);

const roleSelector = createSelector(userSelector, (userState) => userState.role);

const idSelector = createSelector(userSelector, (userState) => userState.id);

const avatarSelector = createSelector(userSelector, (userState) => userState.avatar);

const firstNameSelector = createSelector(userSelector, (userState) => userState.firstName);

const lastNameSelector = createSelector(userSelector, (userState) => userState.lastName);

const fullNameSelector = createSelector(userSelector, (userState) => `${userState.firstName} ${userState.lastName}`);

const languageSelector = createSelector(userSelector, (userState) => userState.language);

const deviceIdSelector = createSelector(userSelector, (userState) => userState.deviceId);

const organizationSelector = createSelector(userSelector, (userState) => userState.organization);

const isAuthenticatedSelector = createSelector(
    userSelector,
    (userState) =>
        userState.apiKey !== '' &&
        userState.apiKey !== 'anonymous' &&
        userState.username !== '' &&
        userState.username !== 'anonymous' &&
        userState.role !== ''
);

const userDataExternalActionSourceSelector = createSelector(userSelector, (userState) => userState.externalUserDataActionSource);

const isAnonymousUserSelector = createSelector(userSelector, (userState) => userState.username === 'anonymous');

const emailVerifiedSelector = createSelector(userSelector, (userState) => userState.isEmailVerified);

const phoneVerifiedSelector = createSelector(userSelector, (userState) => userState.isPhoneVerified);

const phoneNumberSelector = createSelector(userSelector, (userState) => userState.phoneNumber);

const isMFAEnabledSelector = createSelector(userSelector, (userState) => userState.isMFAEnabled);

const logoutStatusLoadingSelector = createSelector(
    userSelector,
    (settingsState) => settingsState.logoutStatus === HTTP_CALL_STATUS.LOADING
);

const logoutStatusSucceededSelector = createSelector(
    userSelector,
    (settingsState) => settingsState.logoutStatus === HTTP_CALL_STATUS.SUCCEEDED
);

const logoutStatusFailedSelector = createSelector(
    userSelector,
    (settingsState) => settingsState.logoutStatus === HTTP_CALL_STATUS.FAILED
);

const initializationStatusSucceededSelector = createSelector(
    userSelector,
    (userState) => userState.initializationStatus === INITIALIZATION_STATUS.SUCCEEDED
);

const initializationStatusFailedSelector = createSelector(
    userSelector,
    (userState) => userState.initializationStatus === INITIALIZATION_STATUS.FAILED
);

const initializationStatusStartedSelector = createSelector(
    userSelector,
    (userState) => userState.initializationStatus === INITIALIZATION_STATUS.STARTED
);

const matrixUsernameSelector = createSelector(userSelector, (userState) => `@u${userState.id}:ethicadata.com`);
const matrixPasswordSelector = createSelector(userSelector, (userState) => userState.matrixPassword);

const notificationSettingsSelector = createSelector(userSelector, (userState) => ({
    phoneNumber: userState.phoneNumber,
    isPhoneVerified: userState.isPhoneVerified,
    email: userState.username,
    isEmailVerified: userState.isEmailVerified,
    subscriptionConfigs: userState.subscriptionConfigs,
    preferredNotificationMediumIds: userState.preferredNotificationMediumIds,
}));

export const duckSelectors = {
    userSelector,
    apiKeySelector,
    usernameSelector,
    roleSelector,
    idSelector,
    avatarSelector,
    firstNameSelector,
    lastNameSelector,
    fullNameSelector,
    languageSelector,
    deviceIdSelector,
    organizationSelector,
    isAuthenticatedSelector,
    userDataExternalActionSourceSelector,
    isAnonymousUserSelector,
    emailVerifiedSelector,
    phoneVerifiedSelector,
    phoneNumberSelector,
    isMFAEnabledSelector,
    logoutStatusLoadingSelector,
    logoutStatusSucceededSelector,
    logoutStatusFailedSelector,
    initializationStatusSucceededSelector,
    initializationStatusFailedSelector,
    initializationStatusStartedSelector,
    matrixUsernameSelector,
    matrixPasswordSelector,
    notificationSettingsSelector,
};
