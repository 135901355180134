import { CancelToken } from 'axios';

import { ClientError } from '@edf-pkg/app-error';

import client from '.';

function ReactQueryCaller(endpoint, data, pageParam, cancelable = true, extraAxiosConfig = {}) {
    if (typeof endpoint !== 'string') {
        throw new ClientError(`Provided endpoint is not valid. It should be string. endpoint: ${endpoint}`);
    }

    if (cancelable) {
        const cancelSource = CancelToken.source();
        this.cancel = () => {
            cancelSource.cancel();
        };
        return client.callEndpoint(endpoint, data, pageParam, { cancelToken: cancelSource.token, ...extraAxiosConfig });
    }

    return client.callEndpoint(endpoint, data, undefined, extraAxiosConfig);
}

export default ReactQueryCaller;
