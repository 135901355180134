import asModule from '$app-web/hoces/as-module';
import lazyLoad, { withTimeoutAndDelay } from '$app-web/utils/lazy-load';
import URLsManager from '$app-web/utils/urls-manager';

import { AUTH_ROUTES } from './routes/constants';

URLsManager.space('dashboard').addModule('auth').setURLs(AUTH_ROUTES);

const AuthModule = lazyLoad(() => withTimeoutAndDelay(() => import('./routes')));

function shouldRender({ user }) {
    return !user.isAuthenticated;
}

export default asModule(shouldRender, 'dashboard.auth')(AuthModule);
