/* eslint-disable import/no-unresolved,global-require */
import URLParse from 'url-parse';

import appUtils from '@edf-pkg/app-utils';

const parsedURL = new URLParse(window.location.href, true);
if (
    (appUtils.env.isDev() || process.env.REACT_APP_RELEASE_FOR === 'staging' || process.env.REACT_APP_RELEASE_FOR === 'local') &&
    appUtils.object.hasKey(parsedURL.query, 'platform') &&
    parsedURL.query.platform === 'web'
) {
    window.injectEDWeb = (initialData) => {
        if (appUtils.object.hasKey(parsedURL.query, 'mock') && parsedURL.query.mock !== '') {
            const stroopV1Mock = require('./stroop-v1');
            const matrixReasoning = require('./matrix-reasoning');
            const asa24Mock = require('./asa24');
            const summaryPageMock = require('./summary-page');
            const stepsMock = require('./steps');
            const timePref = require('./time-pref');
            const webActivityInitialData = { sessionInfo: {}, options: {}, ...(initialData || {}) };
            let mocked = { sessionInfo: {}, options: {} };
            switch (parsedURL.query.mock) {
                case 'stroop-v1':
                    mocked = stroopV1Mock;
                    break;
                case 'matrix-reasoning':
                    mocked = matrixReasoning;
                    break;
                case 'asa24':
                    mocked = asa24Mock;
                    break;
                case 'summary-page':
                    mocked = summaryPageMock;
                    break;
                case 'steps-leaderboard':
                    mocked = stepsMock;
                    break;
                case 'time-pref':
                    mocked = timePref;
                    break;
                // no default
            }
            window.edWeb = {
                getCurrentUserCredentials: () => {
                    // eslint-disable-next-line no-console
                    console.log('Injected getCurrentUserCredentials called.');
                    window.getCurrentUserCredentialsCallback(
                        JSON.stringify({
                            username: process.env.REACT_APP_USER_PARTICIPANT_USERNAME || parsedURL.query.username,
                            api_key: process.env.REACT_APP_USER_PARTICIPANT_API_KEY || parsedURL.query.api_key,
                            id: 12,
                            device_id: 'W_DESKTOP_CHROME',
                            language: 'en-US',
                        })
                    );
                },
                getSessionInfo: () => {
                    // eslint-disable-next-line no-console
                    console.log('Injected getSessionInfo called.');
                    window.getSessionInfoCallback(
                        JSON.stringify({ ...mocked.sessionInfo, ...webActivityInitialData.sessionInfo })
                    );
                },
                getOptions: () => {
                    // eslint-disable-next-line no-console
                    console.log('Injected getOptions called.');
                    window.getOptionsCallback(JSON.stringify({ ...mocked.options, ...webActivityInitialData.options }));
                },
                back: () => {
                    // eslint-disable-next-line no-console
                    console.log('Injected back called.');
                },
                exit: () => {
                    // eslint-disable-next-line no-console
                    console.log('Injected exit called.');
                },
                onLoadingCompleted: () => {
                    // eslint-disable-next-line no-console
                    console.log('Injected onLoadingCompleted called.');
                },
                onLoadingFailed: () => {
                    // eslint-disable-next-line no-console
                    console.log('Injected onLoadingFailed called.');
                },
            };
        }
    };
}
