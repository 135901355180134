import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { SnackbarProvider } from '@edf-pkg/app-main';

import SnackbarHandler from '$app-web/components/snackbar-handler';
import AppErrorBoundary from '$app-web/containers/app-error-boundary';
import Spaces from '$app-web/spaces';

import '$app-web/ui-kit/styles/main.scss';
import '$app-web/ui-kit/styles/web-app.scss';

const WebApp = () => {
    return (
        <>
            <AppErrorBoundary name="web-app">
                <Router basename={process.env.PUBLIC_URL}>
                    <SnackbarProvider render={(providerProps) => <SnackbarHandler {...providerProps} />} />
                    <Spaces />
                </Router>
            </AppErrorBoundary>
        </>
    );
};

WebApp.propTypes = {};

export default WebApp;
