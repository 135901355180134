import React from 'react';
import * as PropTypes from 'prop-types';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import ReactQueryCaller from './react-query-caller';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            queryFn: ({ queryKey: [queryKey, data], pageParam }) => new ReactQueryCaller(queryKey, data, pageParam),
            refetchOnWindowFocus: false,
            retry: 0,
        },
        mutations: {
            mutationFn: ({ queryKey, extraAxiosConfig, ...rest }) =>
                new ReactQueryCaller(queryKey, rest, undefined, false, extraAxiosConfig),
        },
    },
});

const ClientProvider = (props) => {
    const { children, showDevtools } = props;

    return (
        <QueryClientProvider client={queryClient}>
            {/* React Query DevTools put some elements (e.g. input) in the page which will make our Cypress tests fail. */}
            {showDevtools && !window.Cypress && <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />}
            {children}
        </QueryClientProvider>
    );
};

ClientProvider.defaultProps = {
    showDevtools: true,
};

ClientProvider.propTypes = {
    children: PropTypes.node.isRequired,
    showDevtools: PropTypes.bool,
};

export default ClientProvider;
