import { errorHandle } from '@edf-pkg/app-error';
import appUtils from '@edf-pkg/app-utils';

const placeholdersRegex = /%([0-9]+)\$([d|s])/gi;

class AndroidFormat {
    constructor(options) {
        this.type = 'i18nFormat';
        this.init(null, options);
    }

    init(i18next, options) {
        const i18nextOptions = (i18next && i18next.options && i18next.options.i18nFormat) || {};
        this.options = appUtils.object.mergeDeep(i18nextOptions, options, this.options || {}, {});

        if (i18next) {
            // eslint-disable-next-line no-param-reassign
            i18next.AndroidFormat = AndroidFormat;
        }
    }

    /*
        eslint-disable class-methods-use-this
     */
    // noinspection JSMethodCanBeStatic
    parse(text, options) {
        const placeholders = text.match(placeholdersRegex);
        let parsedText = text;
        if (placeholders && placeholders.length > 0) {
            for (let placeholderIndex = 0; placeholderIndex < placeholders.length; placeholderIndex += 1) {
                const placeholder = placeholders[placeholderIndex];
                placeholdersRegex.lastIndex = 0;
                const placeholderData = placeholdersRegex.exec(placeholder);
                const placeholderPosition = placeholderData[1];
                /*
                   Currently we have $s (string) and $d (double) for format of placeholders, but we treat both of them the same;
                   as strings. Also, there is no guarantee that the components of app, didn't passed a string instead of double
                   for $d format, and treating them as double (applying parseFloat on them before replace) will break the app.
                 */
                // const placeholderFormat = placeholderData[2];
                if (appUtils.object.hasKey(options, placeholderPosition - 1)) {
                    try {
                        // Here we should check placeholderFormat and based on that we can treat different formats.
                        const placeholderValue = options[placeholderPosition - 1].toString();
                        parsedText = parsedText.replace(placeholder, placeholderValue);
                    } catch (error) {
                        errorHandle.anError(error);
                        parsedText = parsedText.replace(placeholder, '');
                    }
                }
            }
        }

        return parsedText;
    }
    /*
        eslint-enable class-methods-use-this
     */

    /*
        eslint-disable class-methods-use-this
     */
    // noinspection JSMethodCanBeStatic
    addLookupKeys(finalKeys) {
        return finalKeys;
    }
    /*
        eslint-enable class-methods-use-this
     */
}

AndroidFormat.type = 'i18nFormat';

export default AndroidFormat;
