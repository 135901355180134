import React from 'react';
import PropTypes from 'prop-types';
import MUISnackbar from '@material-ui/core/Snackbar';

import SnackbarContent from '$app-web/components/snackbar-content';

const Snackbar = ({ variant, open, message, id, onOpen, onClose, onExited }) => {
    return (
        <MUISnackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            autoHideDuration={4000}
            open={open}
            onEnter={() => onOpen(id)}
            onClose={() => onClose(id)}
            onExited={onExited}
        >
            <SnackbarContent variant={variant} message={message} />
        </MUISnackbar>
    );
};

Snackbar.defaultProps = {
    variant: 'default',
    message: '',
    id: '',
};

Snackbar.propTypes = {
    variant: PropTypes.string,
    message: PropTypes.string,
    id: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onExited: PropTypes.func.isRequired,
};

export default Snackbar;
