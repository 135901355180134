import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

import { useDialog } from '@edf-pkg/app-main';

import Dialog from '$app-web/components/dialog';
import { DIALOG_IDS } from '$app-web/constants';

const AccountDeletionInitiatedDialog = () => {
    const { t } = useTranslation();
    const deleteAccountInitiatedDialog = useDialog(DIALOG_IDS.ACCOUNT_DELETION_INITIATED);

    return (
        <Dialog
            title={t('pdash:delete_account_initiated_title')}
            content={
                <>
                    <Trans t={t} i18nKey="pdash:delete_account_description" />
                </>
            }
            id={DIALOG_IDS.ACCOUNT_DELETION_INITIATED}
            actions={() => (
                <Button color="secondary" variant="contained" onClick={deleteAccountInitiatedDialog.close}>
                    {t('ok')}
                </Button>
            )}
        />
    );
};

export default AccountDeletionInitiatedDialog;
