import URLParse from 'url-parse';

import { EDFError, FatalError } from '@edf-pkg/app-error';
import appUtils from '@edf-pkg/app-utils';

function ModuleURLsManager(spaceId, moduleId) {
    this.spaceId = spaceId;
    this.moduleId = moduleId;
    this.subModules = {};
    this.URLs = {};
}

ModuleURLsManager.prototype.inModule = function inModule() {
    const parsedPath = new URLParse(window.location);
    return parsedPath.pathname.includes(this.moduleId);
};

ModuleURLsManager.prototype.addSubModule = function addSubModule(subModuleId, baseUrl, URLs) {
    if (!appUtils.object.hasKey(this.subModules, subModuleId)) {
        this.subModules[subModuleId] = { baseUrl, URLs };
    } else {
        throw new FatalError(
            `Trying to sub module which exists.`,
            new EDFError(`Sub module with Id: ${subModuleId} already exists.`)
        );
    }
};

ModuleURLsManager.prototype.setURLs = function setURLs(URLs) {
    this.URLs = Object.assign(this.URLs, URLs);
};

ModuleURLsManager.prototype.replaceParamsInLink = function replaceParamsInLink(URL, params) {
    return Object.keys(params).reduce((finalURL, paramKey) => finalURL.replace(`:${paramKey}`, params[paramKey]), URL);
};

ModuleURLsManager.prototype.to = function to(id, params = {}, includeModulePath = true, subModuleId) {
    if (appUtils.object.hasKey(this.subModules, subModuleId) || appUtils.object.hasKey(this.URLs, id)) {
        return this.replaceParamsInLink(
            `/${this.spaceId}${includeModulePath ? `/${this.moduleId}` : ''}${
                subModuleId !== undefined ? `/${this.subModules[subModuleId].baseUrl}` : ''
            }${subModuleId !== undefined ? this.subModules[subModuleId].URLs[id] : this.URLs[id]}`,
            params
        );
    }
    throw new FatalError(`Trying to get url to not existing path. Path ${id} doesn't exist.`);
};

export default ModuleURLsManager;
