import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { delay } from 'redux-saga';
import { call, put, race, select, take, takeEvery } from 'redux-saga/effects';

import { core as appDateTimeCore } from '@edf-pkg/app-date-time';
import { errorHandle, FatalError } from '@edf-pkg/app-error';
import appUtils from '@edf-pkg/app-utils';

import AndroidFormat from '../core/android.i18nformat';
import languages from '../languages';
import {
    duckActionCreators as i18nActionCreators,
    duckActions as i18nActions,
    duckSelectors as i18nSelectors,
} from './i18n.duck';

export const SAGA_NAME = 'I18N';

function* setI18nextLanguage() {
    const languageCode = yield select((state) => i18nSelectors.languageSelector(state));
    const namespace = yield select((state) => i18nSelectors.namespaceSelector(state));
    if (!i18next.hasResourceBundle(languageCode, namespace)) {
        const namespaceTranslations = yield languages.get(languageCode).getNamespaceTranslations(namespace);
        i18next.addResources(languageCode, namespace, namespaceTranslations);
    }
    i18next.setDefaultNamespace(namespace);
    yield call(appDateTimeCore.changeLocale, languageCode);
    yield i18next.changeLanguage(languageCode);
}

function* checkAndSetLanguage(language) {
    const namespace = yield select(i18nSelectors.namespaceSelector);

    let finalLanguage = language;
    if (languages.languageExist(language)) {
        const normalizedFinalLanguage = languages.normalizeSupportedLanguage(language);
        if (normalizedFinalLanguage === null) {
            finalLanguage = 'en-US';
        } else if (namespace !== 'public' && !languages.get(normalizedFinalLanguage).namespaceHasTranslations(namespace)) {
            /*
                Currently we don't have translations in public namespace and if we don't protect the check from public space
                the urls app/public/activity/ACTIVITY_TYPE/SESSION_UUID?lang=languageCode will not work. This is a fix for it.
             */
            finalLanguage = 'en-US';
        }
    } else {
        finalLanguage = 'en-US';
    }

    yield put(i18nActionCreators.setLanguage(languages.normalizeSupportedLanguage(finalLanguage)));
    yield call(setI18nextLanguage);
}

function* changeNamespace(action) {
    const { namespace } = action.payload;
    const languageCode = yield select((state) => i18nSelectors.languageSelector(state));
    if (i18next.options.ns.indexOf(namespace) >= 0) {
        yield put(i18nActionCreators.setNamespace(namespace));
        yield call(checkAndSetLanguage, languageCode);
    } else {
        throw new FatalError(`Given namespace not exist. Namespace: ${namespace}`);
    }
}

function* changeLanguage(action) {
    const { languageCode } = action.payload;
    yield call(checkAndSetLanguage, languageCode);
}

function* initializeI18next() {
    yield i18next
        .use(AndroidFormat)
        .use(initReactI18next)
        .init({
            debug: appUtils.env.isDev(),
            fallbackLng: 'en-US',
            whitelist: [
                'en-US',
                'de-DE',
                'fa-IR',
                'fr-CA',
                'it-IT',
                'nl-NL',
                'ur-PK',
                'es-ES',
                'es-CO',
                'es-US',
                'ca-ES',
                'sl-SI',
                'zu-ZU',
            ],
            load: 'currentOnly',
            ns: ['pdash', 'rdash', 'webActivities'],
            keySeparator: false,
        });
}

function* askSpaceForLanguageNamespace() {
    yield put(i18nActionCreators.askSpaceForLanguageNamespace());
    const result = yield race({
        success: take(i18nActions.ASK_SPACE_FOR_LANGUAGE_NAMESPACE_SUCCEEDED),
        timeout: delay(4000),
    });
    if (result.timeout) {
        throw new FatalError('Timeout occurred during askSpaceForLanguageNamespace.');
    } else {
        const {
            success: {
                payload: { namespace },
            },
        } = result;
        yield put(i18nActionCreators.setNamespace(namespace));
    }
}

function* initialize(userLanguage) {
    try {
        yield call(initializeI18next);
        yield call(askSpaceForLanguageNamespace);
        yield call(appDateTimeCore.initialize);
        yield call(checkAndSetLanguage, userLanguage);
        yield put(i18nActionCreators.initializationSucceeded());
    } catch (error) {
        errorHandle.anError(error);
        yield put(i18nActionCreators.initializationFailed());
    }
}

export default function* i18nSaga() {
    try {
        const {
            payload: { userLanguage },
        } = yield take(i18nActions.INITIALIZE);
        yield call(initialize, userLanguage);
        yield takeEvery(i18nActions.CHANGE_LANGUAGE, changeLanguage);
        yield takeEvery(i18nActions.CHANGE_NAMESPACE, changeNamespace);
    } catch (error) {
        errorHandle.anError(error);
    }
}
