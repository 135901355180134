import camelCaseKeys from 'camelcase-keys';
import { call, fork, put, take, takeEvery } from 'redux-saga/effects';
import URLParse from 'url-parse';

import { errorHandle } from '@edf-pkg/app-error';
import { store as i18nStore } from '@edf-pkg/app-i18n';
import { store as mainAppStore } from '@edf-pkg/app-main';
import { getStore } from '@edf-pkg/app-store';
import appUtils from '@edf-pkg/app-utils';

import URLsManager from '$app-web/utils/urls-manager';

import webActivityHelper from '../utils/web-activity-helper';
import * as webActivitiesDuck from './web-activities.duck';

const asa24CustomSagaImporter = import('$app-web/activities/asa24/store/web-activity-custom-saga');

export const SAGA_NAME = 'SPACE_WEB_ACTIVITIES';

function* callInjectedBack() {
    // eslint-disable-next-line no-console
    console.log('Trying to call injected back.');
    try {
        yield webActivityHelper.nativeToJsHelper.functionsHelper.back();
    } catch (error) {
        errorHandle.anError(error);
    }
}

function* callInjectedExit() {
    // eslint-disable-next-line no-console
    console.log('Trying to call injected exit.');
    try {
        yield webActivityHelper.nativeToJsHelper.functionsHelper.exit();
    } catch (error) {
        errorHandle.anError(error);
    }
}

function* callInjectedOnLoadingCompleted() {
    // eslint-disable-next-line no-console
    console.log('Trying to call injected onLoadingCompleted.');
    try {
        yield webActivityHelper.nativeToJsHelper.functionsHelper.onLoadingCompleted();
    } catch (error) {
        errorHandle.anError(error);
    }
}

function* callInjectedOnLoadingFailed() {
    // eslint-disable-next-line no-console
    console.log('Trying to call injected onLoadingFailed.');
    try {
        yield webActivityHelper.nativeToJsHelper.functionsHelper.onLoadingFailed();
    } catch (error) {
        errorHandle.anError(error);
    }
}

function* getNeededData() {
    const session = yield webActivityHelper.nativeToJsHelper.functionsHelper.getSessionInfo();
    yield put(webActivitiesDuck.duckActionCreators.setSessionInfo(camelCaseKeys(session)));
    const options = yield webActivityHelper.nativeToJsHelper.functionsHelper.getOptions();
    yield put(webActivitiesDuck.duckActionCreators.setOptions(camelCaseKeys(options)));
    const userData = yield webActivityHelper.nativeToJsHelper.functionsHelper.getCurrentUserCredentials();
    const parsedURL = new URLParse(window.location.href, true);
    if (
        (appUtils.env.isDev() ||
            process.env.REACT_APP_RELEASE_FOR === 'staging' ||
            process.env.REACT_APP_RELEASE_FOR === 'local') &&
        appUtils.object.hasKey(parsedURL.query, 'lang')
    ) {
        userData.language = parsedURL.query.lang;
    }
    yield put(webActivitiesDuck.duckActionCreators.setUserCredentials(camelCaseKeys(userData)));
    yield put(mainAppStore.userDuck.duckActionCreators.loadUserDataFromSpaceSucceeded(camelCaseKeys(userData)));
}

function registerJsToNativeFunctions() {
    webActivityHelper.jsToNativeHelper.registerFunctionHandler('onBackPressed', async function onBackPressedHandler() {
        // eslint-disable-next-line no-console
        console.log('onBackPressed function handler called.');
        await webActivityHelper.nativeToJsHelper.functionsHelper.back();
    });
}

function* initializeWebActivitiesI18n() {
    yield take(i18nStore.i18nDuck.duckActions.ASK_SPACE_FOR_LANGUAGE_NAMESPACE);
    yield put(i18nStore.i18nDuck.duckActionCreators.askSpaceForLanguageNamespaceSucceeded('webActivities'));
}

function* runActivityCustomSagas() {
    const parsedURL = new URLParse();
    const activityType = parsedURL.pathname.replace(process.env.PUBLIC_URL, '').replace('/web-activities/', '');
    if (activityType === 'asa24') {
        const asa24CustomSaga = yield asa24CustomSagaImporter;
        yield fork(asa24CustomSaga.default, webActivitiesDuck, webActivityHelper);
    }
}

function* initialize() {
    if (URLsManager.space('web-activities').inSpace()) {
        try {
            webActivityHelper.initialize();
            registerJsToNativeFunctions();
            yield call(getNeededData);
            yield call(initializeWebActivitiesI18n);
            yield call(runActivityCustomSagas);
        } catch (error) {
            errorHandle.anError(error);
            yield put(mainAppStore.userDuck.duckActionCreators.loadUserDataFromSpaceFailed());
            if (webActivityHelper.initialized) {
                yield call(callInjectedOnLoadingFailed);
            }
        }
    }
}

function* handleInitialize() {
    const parsedURL = new URLParse(window.location.href, true);
    if (
        (appUtils.env.isDev() ||
            process.env.REACT_APP_RELEASE_FOR === 'staging' ||
            process.env.REACT_APP_RELEASE_FOR === 'local') &&
        appUtils.object.hasKey(parsedURL.query, 'hold')
    ) {
        const store = getStore();
        window.initializeOnHold = (data) => {
            window.injectEDWeb(data);
            store.dispatch(webActivitiesDuck.duckActionCreators.initializeOnHold());
        };
        yield take(webActivitiesDuck.duckActions.INITIALIZE_ON_HOLD);
        yield call(initialize);
    } else {
        if (window.injectEDWeb) {
            window.injectEDWeb();
        }
        yield call(initialize);
    }
}

export default function* webActivitiesSaga() {
    yield takeEvery(webActivitiesDuck.duckActions.CALL_INJECTED_BACK, callInjectedBack);
    yield takeEvery(webActivitiesDuck.duckActions.CALL_INJECTED_EXIT, callInjectedExit);
    yield takeEvery(webActivitiesDuck.duckActions.CALL_INJECTED_ON_LOADING_COMPLETED, callInjectedOnLoadingCompleted);
    yield take(mainAppStore.userDuck.duckActions.ASK_SPACE_TO_LOAD_USER_DATA);
    yield call(handleInitialize);
}
