import { callPromiseWithTimeout } from '$app-web/utils/promise-timeout';

export default function promiseWithTimeout(callback) {
    return callPromiseWithTimeout(
        new Promise((resolve, reject) => {
            callback(resolve, reject);
        }),
        3000
    );
}
