/* eslint-disable */
// This scope eslint is disabled to prevent auto sort of import statements as they are important here.
import 'react-app-polyfill/stable';
import 'core-js/proposals/string-replace-all';

import { setAppLogger } from './sentry';

import React from 'react';
import * as ReactDOM from 'react-dom';

import appLogger from '@edf-pkg/app-logger';
import { MainApp } from '@edf-pkg/app-main';
import utils from '@edf-pkg/app-utils';

import WebAppContainer from './containers/web-app-container';
import { StoredUrlProvider } from './context/stored-url.context';
/* eslint-enable */

setAppLogger(appLogger);

const ROOT_ELEMENT = document.getElementById('root');

// TODO-MAYBE: Component seems not a good name.
const render = (Component) => {
    if (utils.env.isProd() || utils.env.isOnPremiseInstance()) {
        // eslint-disable-next-line no-console
        console.log(`ENV: ${utils.env.getEnvironmentName()}`);
        // eslint-disable-next-line no-console
        console.log(
            '%cUsing this console may allow attackers to impersonate you and steal your information. \nDo not enter or paste code that you do not understand.',
            'font-size: 20px; font-weight: bold'
        );
    }

    ReactDOM.render(
        <MainApp>
            <StoredUrlProvider>
                <Component />
            </StoredUrlProvider>
        </MainApp>,
        ROOT_ELEMENT
    );
};
render(WebAppContainer);

if (utils.env.isDev()) {
    if (utils.hmr.enabled()) {
        module.hot.accept('./containers/web-app-container', () => {
            render(WebAppContainer);
        });
    }
}
