import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';

import { keyExists, t } from '@edf-pkg/app-i18n';
import logotypeVertical from '@edf-pkg/assets/images/logo/logotype-vertical.png';

import ButtonContained from '$app-web/components/button-contained';
import LayoutFull from '$app-web/layouts/full';

import styles, { LoadingIndicator } from './styles';

const FullLoadingIndicator = (props) => {
    const { classes, hasError, errorButtonClick, errorButtonText, hasBackground, hideLogo } = props;
    return (
        <LayoutFull
            content={
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justify="center"
                    className={clsx(classes.root, {
                        [classes.background]: hasBackground,
                    })}
                >
                    <Grid container direction="column" spacing={0} justify="center" wrap="nowrap" className={classes.mainWrapper}>
                        {!hideLogo && (
                            <Grid item>
                                <img src={logotypeVertical} alt="Avicenna Research logo" className={classes.logoImage} />
                            </Grid>
                        )}
                        {!hasError && (
                            <Grid item>
                                <LoadingIndicator className={hasError ? classes.hide : null} />
                            </Grid>
                        )}
                        {hasError && (
                            <Grid item>
                                <Typography variant="body2" color="error">
                                    {keyExists('generic_error_msg') ? t('generic_error_msg') : 'Sorry, something went wrong.'}
                                </Typography>
                                <ButtonContained
                                    text={errorButtonText}
                                    color="error"
                                    handleClick={errorButtonClick}
                                    className={classes.button}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            }
        />
    );
};
FullLoadingIndicator.defaultProps = {
    errorButtonClick: () => {
        window.location.reload();
    },
    errorButtonText: 'Reload',
    hasBackground: true,
    hideLogo: false,
    hasError: false,
};
FullLoadingIndicator.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    hasError: PropTypes.bool,
    errorButtonText: PropTypes.string,
    errorButtonClick: PropTypes.func,
    hasBackground: PropTypes.bool,
    hideLogo: PropTypes.bool,
};

export default withStyles(styles)(FullLoadingIndicator);
