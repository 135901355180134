export const sessionInfo = {
    activity_id: 13088,
    device_id: 'W_DESKTOP_CHROME',
    p_time_ms: 0,
    r_time_ms: 0,
    s_time_ms: 1598974184036,
    session_uuid: '174b93ef-8d93-4228-9bdd-452585de995a',
    status_id: 3,
    study_id: 874,
    tl_id: 0,
    tl_session_index: -2,
    tl_type_id: 4,
    user_id: 11890,
    uuid: '174b93ef-8d93-4228-9bdd-452585de995a',
    version: 0,
};

export const options = {
    id: 13088,
};
