export const sessionInfo = {
    activity_id: 4525,
    device_id: null,
    duration: null,
    expiry_time_ms: 1630382400000,
    in_progress_info: {},
    p_time_ms: 0,
    r_time_ms: 0,
    s_time_ms: 1627790400000,
    session_uuid: 'f59c94d7-18a4-455d-8261-0c00644eca8f',
    status_id: 0,
    study_id: 805,
    tl_id: 0,
    tl_session_index: 4,
    tl_type_id: 6,
    user_id: 26976,
    uuid: 'f59c94d7-18a4-455d-8261-0c00644eca8f',
    version: 0,
};

export const options = {
    activity: {
        button_caption: null,
        button_icon: null,
        capture_location: false,
        criteria: null,
        desc: 'Simulate the actual activity to be used in the YCR study.',
        endpoint: 'app/time-pref/',
        expiry_duration_ms: 0,
        id: 4525,
        is_web_activity: true,
        name: 'Sooner or Later',
        name_translation: { 'es-US': 'Tarde o Temprano' },
        notification_templates: [
            {
                criteria: '',
                custom_context_func: '',
                custom_criteria_func: '',
                event_id: 12,
                id: 18,
                label: '',
                medium: {
                    email: {
                        body: 'Hi {{user_first_name}},<br/><br/>Final reminder that you have only 2 more days to complete the Sooner or Later activity either through the Every Dollar Counts app or by clicking on <a href="{{access_url}}">this link.<br/><br/>You need to complete this activity in the next 2 days in order to receive your $5 reward.<br/><br/>Thank you,<br/>The Every Dollar Counts program evaluation team',
                        body_translation: {
                            'es-us':
                                'Hola, {{user_first_name}},<br/><br/>Recordatorio final de que solo tiene 2 días más para completar la actividad Tarde o temprano, ya sea a través de la aplicación Every Dollar Counts o haciendo clic en <a href="{{access_url}}">este enlace.<br/><br/>Debe completar esta actividad en los próximos 2 días para recibir su recompensa de $5.<br/><br/>Gracias,<br/>El Equipo de evaluación del programa Every Dollar Counts',
                        },
                        subject: 'Final reminder for Sooner or Later',
                        subject_translation: { 'es-us': 'Recordatorio final para Tarde o Temprano' },
                    },
                    sms: {
                        text_message:
                            'Hi {{user_first_name}}. Final reminder that you have only 2 more days to complete the Sooner or Later in your Every Dollar Counts app and receive your $5 reward.',
                        text_message_translation: {
                            'es-us':
                                'Hola, {{user_first_name}}. Recordatorio final de que solo tiene 2 días más para completar el Tarde o temprano en su aplicación Every Dollar Counts y recibir su recompensa de $5.',
                        },
                    },
                },
                medium_contents: {
                    email: {
                        body: 'Hi {{user_first_name}},<br/><br/>Final reminder that you have only 2 more days to complete the Sooner or Later activity either through the Every Dollar Counts app or by clicking on <a href="{{access_url}}">this link.<br/><br/>You need to complete this activity in the next 2 days in order to receive your $5 reward.<br/><br/>Thank you,<br/>The Every Dollar Counts program evaluation team',
                        body_translation: {
                            'es-us':
                                'Hola, {{user_first_name}},<br/><br/>Recordatorio final de que solo tiene 2 días más para completar la actividad Tarde o temprano, ya sea a través de la aplicación Every Dollar Counts o haciendo clic en <a href="{{access_url}}">este enlace.<br/><br/>Debe completar esta actividad en los próximos 2 días para recibir su recompensa de $5.<br/><br/>Gracias,<br/>El Equipo de evaluación del programa Every Dollar Counts',
                        },
                        subject: 'Final reminder for Sooner or Later',
                        subject_translation: { 'es-us': 'Recordatorio final para Tarde o Temprano' },
                    },
                    sms: {
                        text_message:
                            'Hi {{user_first_name}}. Final reminder that you have only 2 more days to complete the Sooner or Later in your Every Dollar Counts app and receive your $5 reward.',
                        text_message_translation: {
                            'es-us':
                                'Hola, {{user_first_name}}. Recordatorio final de que solo tiene 2 días más para completar el Tarde o temprano en su aplicación Every Dollar Counts y recibir su recompensa de $5.',
                        },
                    },
                },
                offset_ms: 2311200000,
                recipient_ids: [],
                ssid: 18,
                status_id: 1,
                study_id: 805,
            },
            {
                criteria: '',
                custom_context_func: '',
                custom_criteria_func: '',
                event_id: 12,
                id: 19,
                label: '',
                medium: {
                    email: {
                        body: 'Reminder for Sooner or Later',
                        body_translation: {
                            'es-us':
                                'Hola, {{user_first_name}}: <br/> <br/> Solo un recordatorio, su actividad de Tarde o Temprano aún no se completó. Puede completarlo en cualquier momento de este mes haciendo clic en <a href="{{access_url}}"> este enlace </a>. <br/> <br/> Una vez que termine la actividad, se le enviará una recompensa de $5. <br/> <br/> Gracias, <br/> El equipo de evaluación del programa Every Dollar Counts',
                        },
                        subject_translation: { 'es-us': 'Recordatorio para Tarde o Temprano' },
                    },
                    sms: {
                        text_message:
                            'Hi {{user_first_name}}. Just a reminder, your Sooner or Later activity is not completed yet. You can complete it anytime this month via the Every Dollar Counts app. Once you finish the activity you will be sent a $5 reward. Thank you.',
                        text_message_translation: {
                            'es-us':
                                'Hola, {{user_first_name}}. Solo un recordatorio, su actividad Tarde o temprano aún no ha finalizado. Puede completarlo en cualquier momento de este mes a través de la aplicación Every Dollar Counts. Una vez que termine la actividad, se le enviará una recompensa de $5. Gracias.',
                        },
                    },
                },
                medium_contents: {
                    email: {
                        body: 'Reminder for Sooner or Later',
                        body_translation: {
                            'es-us':
                                'Hola, {{user_first_name}}: <br/> <br/> Solo un recordatorio, su actividad de Tarde o Temprano aún no se completó. Puede completarlo en cualquier momento de este mes haciendo clic en <a href="{{access_url}}"> este enlace </a>. <br/> <br/> Una vez que termine la actividad, se le enviará una recompensa de $5. <br/> <br/> Gracias, <br/> El equipo de evaluación del programa Every Dollar Counts',
                        },
                        subject_translation: { 'es-us': 'Recordatorio para Tarde o Temprano' },
                    },
                    sms: {
                        text_message:
                            'Hi {{user_first_name}}. Just a reminder, your Sooner or Later activity is not completed yet. You can complete it anytime this month via the Every Dollar Counts app. Once you finish the activity you will be sent a $5 reward. Thank you.',
                        text_message_translation: {
                            'es-us':
                                'Hola, {{user_first_name}}. Solo un recordatorio, su actividad Tarde o temprano aún no ha finalizado. Puede completarlo en cualquier momento de este mes a través de la aplicación Every Dollar Counts. Una vez que termine la actividad, se le enviará una recompensa de $5. Gracias.',
                        },
                    },
                },
                offset_ms: 842400000,
                recipient_ids: [],
                ssid: 19,
                status_id: 1,
                study_id: 805,
            },
            {
                criteria: '',
                custom_context_func: '',
                custom_criteria_func: '',
                event_id: 12,
                id: 16,
                label: '',
                medium: {
                    email: {
                        body: 'Hi {{user_first_name}},<br/><br/>Please complete the Sooner or Later activity via the Every Dollar Counts app or by clicking on <a href="{{access_url}}">this link</a>.<br/><br/>Once you complete this activity you will be sent a $5 reward.<br/><br/>Thank you,<br/>The Every Dollar Counts program evaluation team',
                        body_translation: {
                            'es-us':
                                'Hola, {{user_first_name}},<br/><br/>Por favor complete la actividad Sooner or Later a través de la aplicación Every Dollar Counts o haciendo clic en <a href="{{access_url}}">este enlace</a>.<br/><br/>Una vez que complete esta actividad, se le enviará una recompensa de $5.<br/><br/>Gracias,<br/>El equipo de evaluación del programa Every Dollar Counts',
                        },
                        subject: 'Complete the Sooner or Later',
                        subject_translation: { 'es-us': 'Complete la tarde o temprano' },
                    },
                    sms: {
                        text_message:
                            'Hi {{user_first_name}}. Please complete the Sooner or Later activity in your Every Dollar Counts app. Once you complete this activity you will be sent a $5 reward.',
                        text_message_translation: {
                            'es-us':
                                'Hola, {{user_first_name}}. Complete la actividad Tarde o temprano en su aplicación Every Dollar Counts. Una vez que complete esta actividad, se le enviará una recompensa de $5.',
                        },
                    },
                },
                medium_contents: {
                    email: {
                        body: 'Hi {{user_first_name}},<br/><br/>Please complete the Sooner or Later activity via the Every Dollar Counts app or by clicking on <a href="{{access_url}}">this link</a>.<br/><br/>Once you complete this activity you will be sent a $5 reward.<br/><br/>Thank you,<br/>The Every Dollar Counts program evaluation team',
                        body_translation: {
                            'es-us':
                                'Hola, {{user_first_name}},<br/><br/>Por favor complete la actividad Sooner or Later a través de la aplicación Every Dollar Counts o haciendo clic en <a href="{{access_url}}">este enlace</a>.<br/><br/>Una vez que complete esta actividad, se le enviará una recompensa de $5.<br/><br/>Gracias,<br/>El equipo de evaluación del programa Every Dollar Counts',
                        },
                        subject: 'Complete the Sooner or Later',
                        subject_translation: { 'es-us': 'Complete la tarde o temprano' },
                    },
                    sms: {
                        text_message:
                            'Hi {{user_first_name}}. Please complete the Sooner or Later activity in your Every Dollar Counts app. Once you complete this activity you will be sent a $5 reward.',
                        text_message_translation: {
                            'es-us':
                                'Hola, {{user_first_name}}. Complete la actividad Tarde o temprano en su aplicación Every Dollar Counts. Una vez que complete esta actividad, se le enviará una recompensa de $5.',
                        },
                    },
                },
                offset_ms: 64800000,
                recipient_ids: [],
                ssid: 16,
                status_id: 1,
                study_id: 805,
            },
            {
                criteria: '',
                custom_context_func: '',
                custom_criteria_func: '',
                event_id: 12,
                id: 17,
                label: '',
                medium: {
                    email: {
                        body: 'Hi {{user_first_name}}.<br/><br/>Have you completed the Sooner or Later activity? Start now by opening the Every Dollar Counts app or clicking on <a href="{{access_url}}">this link</a>.<br/><br/>You only have until the end of the month to complete it. Once you complete this activity you will be sent a $5 reward.<br/><br/>Thank you,<br/>The Every Dollar Counts program evaluation team',
                        body_translation: {
                            'es-us':
                                'Hola, {{user_first_name}},<br/><br/>¿Has completado la actividad Tarde o temprano? Empiece ahora abriendo la aplicación Every Dollar Counts o haciendo clic en <a href="{{access_url}}">este enlace</a>.<br/><br/>Solo tiene hasta fin de mes para completar eso. Una vez que complete esta actividad, se le enviará una recompensa de $5.<br/><br/>Gracias,<br/>El equipo de evaluación del programa Every Dollar Counts',
                        },
                        subject: 'Reminder for Sooner or Later',
                        subject_translation: { 'es-us': 'Recordatorio para Tarde o Temprano' },
                    },
                    sms: {
                        text_message:
                            'Hi {{user_first_name}}. Have you completed the Sooner or Later in your Every Dollar Counts app? You only have until the end of the month to complete it. Once you complete this activity you will be sent a $5 reward.',
                        text_message_translation: {
                            'es-us':
                                'Hola, {{user_first_name}}. ¿Ha completado el Tarde o temprano en su aplicación Every Dollar Counts? Solo tienes hasta fin de mes para completarlo. Una vez que complete esta actividad, se le enviará una recompensa de $5.',
                        },
                    },
                },
                medium_contents: {
                    email: {
                        body: 'Hi {{user_first_name}}.<br/><br/>Have you completed the Sooner or Later activity? Start now by opening the Every Dollar Counts app or clicking on <a href="{{access_url}}">this link</a>.<br/><br/>You only have until the end of the month to complete it. Once you complete this activity you will be sent a $5 reward.<br/><br/>Thank you,<br/>The Every Dollar Counts program evaluation team',
                        body_translation: {
                            'es-us':
                                'Hola, {{user_first_name}},<br/><br/>¿Has completado la actividad Tarde o temprano? Empiece ahora abriendo la aplicación Every Dollar Counts o haciendo clic en <a href="{{access_url}}">este enlace</a>.<br/><br/>Solo tiene hasta fin de mes para completar eso. Una vez que complete esta actividad, se le enviará una recompensa de $5.<br/><br/>Gracias,<br/>El equipo de evaluación del programa Every Dollar Counts',
                        },
                        subject: 'Reminder for Sooner or Later',
                        subject_translation: { 'es-us': 'Recordatorio para Tarde o Temprano' },
                    },
                    sms: {
                        text_message:
                            'Hi {{user_first_name}}. Have you completed the Sooner or Later in your Every Dollar Counts app? You only have until the end of the month to complete it. Once you complete this activity you will be sent a $5 reward.',
                        text_message_translation: {
                            'es-us':
                                'Hola, {{user_first_name}}. ¿Ha completado el Tarde o temprano en su aplicación Every Dollar Counts? Solo tienes hasta fin de mes para completarlo. Una vez que complete esta actividad, se le enviará una recompensa de $5.',
                        },
                    },
                },
                offset_ms: 2138400000,
                recipient_ids: [],
                ssid: 17,
                status_id: 1,
                study_id: 805,
            },
        ],
        publish_note: null,
        published: true,
        selection_chance: 0.01,
        session_type_id: 1,
        status_id: 1,
        study_id: 805,
        triggering_logics: [{ criteria: '', id: 0, notifications: [16, 17, 18, 19], type: 'time_user_def' }],
        type: 'time_pref',
        version: 0,
    },
};
