import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import snakecaseKeys from 'snakecase-keys';
import { array, number, object, string } from 'yup';

import appDateTime from '@edf-pkg/app-date-time';
import { errorHandle, ValidationError } from '@edf-pkg/app-error';
import { store as appMainStore } from '@edf-pkg/app-main';

import client from '../client';
import { PHR_EVENT_TYPES } from '../constants';
import { duckActionCreators as PHRActionCreators, duckActions as PHRActions } from './phr.duck';

export const SAGA_NAME = 'PHR';

const PHRMessageBaseJSON = {
    studyId: [],
    eventType: -1,
    timeMs: 0,
};

const activityOpenedAndClosedReportSchema = object().shape({
    studyId: array(),
    activityId: number().required(),
    sessionUuid: string(),
});

const activityFinishedReportSchema = object().shape({
    studyId: array(),
    activityId: number().required(),
    sessionUuid: string(),
    sTimeMs: number().required(),
    pTimeMs: number().required(),
    rTimeMs: number().required(),
    statusId: number().required(),
});

function* sendPHR(message, sessionUUID = null) {
    try {
        yield call(
            client.api.PHCreate,
            snakecaseKeys({ ...message, timeMs: appDateTime().toMillis() }, { deep: true }),
            sessionUUID
        );
        yield put(PHRActionCreators.setSendStatusSucceeded());
    } catch (error) {
        errorHandle.anError(error);
        yield put(PHRActionCreators.setSendStatusFailed());
    }
}

function* handleActivityEvents(action) {
    try {
        // PHR for activity can be invalid currently as we are moving into web-based activities, so we should check them.
        switch (action.payload.eventType) {
            case PHR_EVENT_TYPES.ACTIVITY_OPENED:
            case PHR_EVENT_TYPES.ACTIVITY_CLOSED:
                yield activityOpenedAndClosedReportSchema.validate(action.payload);
                break;
            case PHR_EVENT_TYPES.ACTIVITY_FINISHED:
                yield activityFinishedReportSchema.validate(action.payload);
                break;
            // no default
        }
        yield call(sendPHR, { ...PHRMessageBaseJSON, ...action.payload }, action.payload.sessionUuid);
    } catch (error) {
        errorHandle.anError(
            new ValidationError(`Payload for sending PHR is not valid. payload: ${JSON.stringify(action.payload)}`, error)
        );
    }
}

function* handleUserAction(action) {
    const userDataSource = yield select(appMainStore.userDuck.duckSelectors.userDataExternalActionSourceSelector);
    if (userDataSource === 'sign-up') {
        yield call(sendPHR, { ...PHRMessageBaseJSON, eventType: PHR_EVENT_TYPES.USER_SIGN_UP });
    } else if (userDataSource === 'sign-in' || userDataSource === 'reset-password') {
        const {
            payload: { studies },
        } = action;
        yield call(sendPHR, { ...PHRMessageBaseJSON, eventType: PHR_EVENT_TYPES.USER_SIGN_IN, studyId: Object.keys(studies) });
    }
}

function* handleUserLogout(action) {
    const {
        payload: { studies },
    } = action;
    yield call(sendPHR, { ...PHRMessageBaseJSON, eventType: PHR_EVENT_TYPES.USER_LOGOUT, studyId: Object.keys(studies) });
}

function* handleApplicationOpened(action) {
    const {
        payload: { studies },
    } = action;
    yield call(sendPHR, { ...PHRMessageBaseJSON, eventType: PHR_EVENT_TYPES.APPLICATION_OPENED, studyId: Object.keys(studies) });
}

function* handleRouteVisited(action) {
    const {
        payload: { eventType, studyId },
    } = action;
    yield call(sendPHR, { ...PHRMessageBaseJSON, eventType, studyId: [studyId] });
}

function* handleEdenPHREvent(action) {
    const {
        payload: { ethicaId, eventType, studyId, actionType, consentType },
    } = action;

    const payload = { ...PHRMessageBaseJSON, eventType, studyId: [studyId], ethicaId };
    if (consentType) {
        payload.consentType = consentType;
    }
    if (actionType) {
        payload.action = actionType;
    }

    yield call(sendPHR, payload);
}

export default function* PHRSaga() {
    yield takeEvery(PHRActions.APPLICATION_OPENED, handleApplicationOpened);
    yield takeLatest(PHRActions.USER_SIGN_ACTION, handleUserAction);
    yield takeLatest(PHRActions.USER_LOGOUT, handleUserLogout);
    yield takeLatest(
        [
            PHRActions.ACTIVITY_OPENED,
            PHRActions.ACTIVITY_CLOSED,
            PHRActions.ACTIVITY_FINISHED,
            PHRActions.ACTIVITY_RESPONSES_UPLOAD_STARTED,
            PHRActions.ACTIVITY_RESPONSES_UPLOAD_FINISHED,
            PHRActions.ACTIVITY_RESPONSES_UPLOAD_FAILED,
        ],
        handleActivityEvents
    );
    yield takeEvery(PHRActions.ROUTE_VISITED, handleRouteVisited);
    yield takeLatest(PHRActions.EDEN_PHR_EVENTS, handleEdenPHREvent);
}
