// eslint-disable-next-line import/prefer-default-export
export const AUTH_ROUTES = {
    WELCOME: '/welcome',
    SIGN_IN: '/sign-in',
    SIGN_UP: '/sign-up',
    SIGN_UP_ROLE: '/sign-up/:role',
    FORGOT_PASSWORD: '/forgot-password',
    FORGOT_PASSWORD_SUCCESS: '/forgot-password/success',
    RESET_PASSWORD: '/reset-password/:uid/:token',
};
