import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { store } from '@edf-pkg/app-main';

import FullLoadingIndicator from '$app-web/components/full-loading-indicator';

// eslint-disable-next-line react/prefer-stateless-function
class SplashContainer extends Component {
    render() {
        const { initializationStatusFailed, errorButton } = this.props;
        return <FullLoadingIndicator hasError={initializationStatusFailed} errorButton={errorButton} />;
    }
}

SplashContainer.defaultProps = {
    errorButton: null,
};

SplashContainer.propTypes = {
    initializationStatusFailed: PropTypes.bool.isRequired,
    errorButton: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

const { appDuck } = store;

const mapStateToProps = function mapStateToProps(state) {
    return {
        initializationStatusFailed: appDuck.duckSelectors.initializationStatusFailedSelector(state),
    };
};

export default connect(mapStateToProps)(SplashContainer);
