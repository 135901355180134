import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const WebActivitiesBigButton = (props) => {
    const { classes, text, handleClick, ...rest } = props;
    return (
        <Button
            variant="text"
            color="inherit"
            fullWidth
            onClick={handleClick}
            classes={{
                root: classes.root,
                label: classes.label,
            }}
            {...rest}
        >
            {text}
        </Button>
    );
};

WebActivitiesBigButton.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    text: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(WebActivitiesBigButton);
