import * as logLevel from 'loglevel';
import snakecaseKeys from 'snakecase-keys';

import appDateTime from '@edf-pkg/app-date-time';
import getDeviceInfo from '@edf-pkg/app-device-info';

import client from '../client';

class Logger {
    static isProd = process.env.NODE_ENV === 'production';

    static basePayload = {
        source: 'frontend',
        name: 'frontend',
        timestamp: '',
        osVersion: '',
        deviceId: '',
        appVersion: 1,
        userId: -1,
        message: '',
        level: '',
        stackTrace: '',
        extraInfo: {},
    };

    constructor() {
        this.logLevelInstance = logLevel.getLogger('@edf-logger');
        if (Logger.isProd) {
            this.logLevelInstance.setLevel('warn', false);
        } else {
            this.logLevelInstance.setLevel('trace', false);
        }
    }

    trace(message) {
        this.logLevelInstance.trace(message);
    }

    debug(message) {
        this.logLevelInstance.debug(message);
    }

    info(message) {
        this.logLevelInstance.info(message);
    }

    warn(message) {
        this.logLevelInstance.warn(message);
    }

    error(message) {
        this.logLevelInstance.error(message);
    }

    // eslint-disable-next-line class-methods-use-this
    logToBackend(payload) {
        const deviceInfo = getDeviceInfo();
        const finalPayload = {
            ...Logger.basePayload,
            ...{
                timestamp: appDateTime().toMillis(),
                osVersion: deviceInfo.os,
            },
            ...payload,
        };

        if (finalPayload.deviceId === '') {
            finalPayload.deviceId = deviceInfo.device_id;
        }

        client.api
            .createLog(snakecaseKeys(finalPayload))
            .then()
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error);
            });
    }
}

const instance = new Logger();
export default instance;
