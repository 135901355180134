import { put } from 'redux-saga/effects';

import { APIMappedError } from '@edf-pkg/app-error';
import { t } from '@edf-pkg/app-i18n';

import * as appMainStore from '../store';

// eslint-disable-next-line import/prefer-default-export
export function* showAPIErrorSnackbar(error) {
    let errorMessage = t('generic_error_msg');
    if (error instanceof APIMappedError) {
        errorMessage = error.message;
    }
    yield put(
        appMainStore.snackbarDuck.duckActionCreators.show({
            variant: 'error',
            message: errorMessage,
        })
    );
}
