import fileServerURLsManager from './core/file-server-urls-manager';
import serverURLsManager from './core/server-urls-manager';

// Initialize
const defaultEndpoints = {
    base: '',
    app: process.env.PUBLIC_URL || '/',
    api: '/api',
    graphql: '/graphql',
    media: '/media',
    legacyDashboard: '/dashboard',
};

Object.keys(defaultEndpoints).forEach((endpointId) => {
    serverURLsManager.addEndpoint(endpointId, defaultEndpoints[endpointId]);
});

export default { server: serverURLsManager, fileServer: fileServerURLsManager };
