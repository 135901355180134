class FileServerURLsManager {
    constructor() {
        this.baseURL = process.env.REACT_APP_FILE_SERVER_BASE_URL;
    }

    getBaseURL() {
        return this.baseURL;
    }

    getFileURL(userId, fileType, fileName) {
        const path = `resp_files/${userId}/${fileType}/${fileName}`;
        return {
            url: `${this.baseURL}/media/${path}`,
            path,
        };
    }
}

const instance = new FileServerURLsManager();
export default instance;
