import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { store as mainAppStore } from '@edf-pkg/app-main';

import Loading from '$app-web/components/loading';
import asSpace from '$app-web/hoces/as-space';
import AuthModule from '$app-web/modules/auth';
import PdashModule from '$app-web/modules/pdash';
import RdashModule from '$app-web/modules/rdash';
import AuthActionProvider from '$app-web/spaces/dashboard/components/auth-action-provider';

import AccountDeletionInitiatedDialog from './components/account-deletion-initiated-dialog';
import AccountDeletionRevokedDialog from './components/account-deletion-revoked-dialog';
import * as dashboardStore from './store';

export const SPACE_ID = 'dashboard';

dashboardStore.inject();

const DashboardSpace = () => {
    const dispatch = useDispatch();
    const [t] = useTranslation();

    const isLogoutLoading = useSelector(mainAppStore.userDuck.duckSelectors.logoutStatusLoadingSelector);
    const isLogoutFailed = useSelector(mainAppStore.userDuck.duckSelectors.logoutStatusFailedSelector);

    return (
        <>
            <AuthModule />
            <Loading
                variant="modal"
                errorHasButton
                onErrorButtonClick={() => dispatch(mainAppStore.userDuck.duckActionCreators.logout())}
                errorButtonText={t('try_again')}
                loading={isLogoutLoading}
                error={isLogoutFailed}
            />
            {/* These dialogs are related to PDash. Invoked by sign-in and log out. We couldn't load these dialogs in PDash because of lazy-loading/rerouting/unmounting. */}
            <AccountDeletionInitiatedDialog />
            <AccountDeletionRevokedDialog />
            <AuthActionProvider>
                <PdashModule />
                <RdashModule />
            </AuthActionProvider>
        </>
    );
};

export default asSpace()(DashboardSpace);

export { dashboardStore };
