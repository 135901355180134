import { useCallback } from 'react';
import axios from 'axios';
import { camelCase, cloneDeep, snakeCase } from 'lodash-es';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';

import { core as appClientCore, utils as appClientUtils } from '@edf-pkg/app-client';
import { ClientError } from '@edf-pkg/app-error';
import * as appMain from '@edf-pkg/app-main';
import appURLsManager from '@edf-pkg/app-urls-manager';
import appUtils from '@edf-pkg/app-utils';

class Client {
    axiosDefaultServiceConfig = {
        timeout: 60000,
    };

    defaultServices = {
        api: { ...this.axiosDefaultServiceConfig, baseURL: appURLsManager.server.getEndpointURL('api') },
        media: { ...this.axiosDefaultServiceConfig, baseURL: appURLsManager.server.getEndpointURL('media') },
        gql: { ...this.axiosDefaultServiceConfig, baseURL: appURLsManager.server.getEndpointURL('graphql') },
        file: { ...this.axiosDefaultServiceConfig, baseURL: appURLsManager.fileServer.getBaseURL() },
    };

    services = {};

    endpoints = {};

    ERROR_CODES = appClientCore.ERROR_CODES;

    constructor() {
        this.initializeDefaultClients();
    }

    initializeDefaultClients() {
        Object.keys(this.defaultServices).forEach((defaultServiceId) => {
            this.createService(defaultServiceId, this.defaultServices[defaultServiceId]).interceptors.request.use(
                (config) => {
                    const finalConfig = { ...config };
                    if (
                        !Object.keys(config.headers)
                            .map((key) => key.toLowerCase())
                            .includes('authorization') &&
                        !appUtils.object.hasKey(config, 'auth')
                    ) {
                        const userAuthData = appMain.utils.user.getAuthData();
                        finalConfig.headers.authorization = `ApiKey ${userAuthData.username}:${userAuthData.apiKey}`;
                    }
                    // TODO-Maybe: Remove the `app` from the location.pathname
                    finalConfig.headers['x-ethica-path'] = window.location.pathname;
                    return finalConfig;
                },
                (error) => Promise.reject(error)
            );
        });
    }

    // Service manager
    createService(serviceId, axiosConfig) {
        this.services[serviceId] = axios.create(axiosConfig);
        return this.services[serviceId];
    }

    getService(serviceId) {
        if (appUtils.object.hasKey(this.services, serviceId)) {
            return this.services[serviceId];
        }
        throw new ClientError(`Trying to get missing service. Please call createService first. serviceId: ${serviceId}`);
    }

    // Endpoint manager
    static parseEndpoint(endpointDescriptor) {
        if (typeof endpointDescriptor !== 'string') {
            throw new ClientError(
                `Provided endpoint descriptor is not valid. It should be string. endpointDescriptor: ${endpointDescriptor}`
            );
        }

        const parsedEndpoint = {
            descriptor: endpointDescriptor,
            method: 'get',
        };

        const endpointDescriptorSplit = endpointDescriptor.split(' ');

        [parsedEndpoint.endpointId] = endpointDescriptorSplit;
        if (endpointDescriptorSplit.length === 2) {
            parsedEndpoint.method = endpointDescriptorSplit[0].toLowerCase();
            [, parsedEndpoint.endpointId] = endpointDescriptorSplit;
        }

        const endpointSplit = parsedEndpoint.endpointId.split('/');
        if (endpointSplit.length !== 2) {
            throw new ClientError(
                `Provided endpoint part is not valid. It should be like SERVICE/ENDPOINT_ID. endpoint: ${parsedEndpoint.descriptor}`
            );
        }
        [parsedEndpoint.serviceId, parsedEndpoint.endpointSubId] = endpointSplit;

        return parsedEndpoint;
    }

    setResponseParser(newResponseParser, { endpointId }) {
        if (newResponseParser !== undefined) {
            this.endpoints[endpointId].responseParser = newResponseParser;
        }
    }

    registerEndpoint(endpointDescriptor, URLOrGQLQueryAndVariables, options, responseParser) {
        if (!URLOrGQLQueryAndVariables) {
            throw new ClientError(
                `Endpoint URL or GQL query and variables is missing. It should be provided. URLOrGQLQueryAndVariables: ${URLOrGQLQueryAndVariables}`
            );
        }

        const { method, serviceId, endpointId, endpointSubId } = Client.parseEndpoint(endpointDescriptor);

        const endpoint = {
            method,
            serviceId,
            options,
            responseParser,
        };

        if (serviceId === 'gql') {
            endpoint.queryAndVariables = URLOrGQLQueryAndVariables;
        } else {
            endpoint.url = URLOrGQLQueryAndVariables;
        }

        this.endpoints[endpointId] = endpoint;

        let useClientQuery;
        if (options.mutate) {
            useClientQuery = (queryConfig, clientQueryOptions = {}) => {
                const { mutate: mainMutate, mutateAsync: mainMutateAsync, ...rest } = useMutation(queryConfig);

                this.setResponseParser(clientQueryOptions.responseParser, { endpointId });

                const mutate = useCallback(
                    (data, extraQueryConfig = {}) => mainMutate({ ...data, queryKey: endpointId }, extraQueryConfig),
                    [mainMutate]
                );

                const mutateAsync = useCallback(
                    (data, extraQueryConfig = {}) => mainMutateAsync({ ...data, queryKey: endpointId }, extraQueryConfig),
                    [mainMutateAsync]
                );

                return {
                    mutate,
                    mutateAsync,
                    ...rest,
                };
            };
        } else if (options.infinite) {
            useClientQuery = (data, queryConfig, clientQueryOptions = {}) => {
                this.setResponseParser(clientQueryOptions.responseParser, { endpointId });

                return useInfiniteQuery([endpointId, data], {
                    ...queryConfig,
                    ...(options.getNextPageParam ? { getNextPageParam: options.getNextPageParam } : {}),
                });
            };
        } else {
            useClientQuery = (data, queryConfig, clientQueryOptions = {}) => {
                this.setResponseParser(clientQueryOptions.responseParser, { endpointId });

                return useQuery([endpointId, data], queryConfig);
            };
        }
        Object.defineProperty(useClientQuery, 'name', { value: camelCase(`use-${endpointSubId}`) });
        Object.defineProperty(useClientQuery, 'key', { value: endpointId });

        return useClientQuery;
    }

    getEndpoint(endpointId) {
        if (!appUtils.object.hasKey(this.endpoints, endpointId)) {
            throw new ClientError(`Trying to get missing endpoint. endpointId: ${endpointId}`);
        }
        return this.endpoints[endpointId];
    }

    static getVersion(url) {
        const hasVersion = url.match(/(^[v][1-9]+)/);
        if (hasVersion && !url.includes('/matrix_reasoning') && !url.includes('/wheel_of_fortune')) {
            return hasVersion[0];
        }
        return 'unknown';
    }

    async callEndpoint(endpointId, data, pageParam, axiosConfig) {
        const {
            serviceId,
            method,
            url,
            queryAndVariables,
            options: {
                requestSchema,
                responseSchema,
                responseType,
                headers,
                customErrorsOrMapper,
                requestSchemaStripUnknown = true,
                responseSchemaStripUnknown = true,
                shouldCamelCaseKeys = true,
            },
            responseParser,
        } = this.getEndpoint(endpointId);

        try {
            const requestConfig = {
                method,
                responseType,
                headers: headers || {},
                ...axiosConfig,
            };
            if (serviceId === 'gql') {
                requestConfig.url = '';
                requestConfig.data = queryAndVariables(data, appClientUtils.gql.node.getBase64IdFromNodeId, pageParam);
            } else {
                let finalData = appUtils.object.snakeCaseNonTranslationKeys(
                    {
                        ...cloneDeep(data),
                        // BE should always depend on scrollId
                        ...(pageParam !== undefined ? { scrollId: pageParam } : {}),
                    },
                    ['image', 'OR', 'AND']
                );
                requestConfig.url = typeof url === 'function' ? url(data) : url;

                if (Object.keys(finalData).length > 0) {
                    // Normalize file instances
                    Object.keys(data).forEach((key) => {
                        if (data[key] instanceof File) {
                            finalData[snakeCase(key)] = data[key];
                        }
                    });
                    if (requestSchema) {
                        finalData = await requestSchema.validate(finalData, {
                            stripUnknown: requestSchemaStripUnknown,
                        });
                    }

                    if (method === 'get') {
                        requestConfig.params = { ...finalData };
                    } else if (method === 'post' && Client.getVersion(url) === 'unknown') {
                        requestConfig.data = appClientUtils.object.toFormData(finalData);
                    } else if (['post', 'put', 'patch', 'delete'].includes(method)) {
                        requestConfig.data = finalData;
                        requestConfig.headers['Content-Type'] = 'application/json';
                    }
                }
            }

            const response = await this.getService(serviceId).request(requestConfig);

            let finalResponse = response.data;
            if (serviceId === 'gql') {
                finalResponse = appClientUtils.gql.response.normalize(response.data.data);
            }

            if (responseSchema) {
                finalResponse = await responseSchema.validate(finalResponse, {
                    stripUnknown: responseSchemaStripUnknown,
                });
            }

            if (shouldCamelCaseKeys) {
                finalResponse = appUtils.object.camelCaseNonTranslationKeys(finalResponse);
            }

            if (responseParser) {
                return responseParser(finalResponse, data);
            }

            return finalResponse;
        } catch (error) {
            appClientCore.errorMapper(error, customErrorsOrMapper);
            return null;
        }
    }
}

const instance = new Client();
export default instance;
