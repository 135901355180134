import React from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Modal } from '@material-ui/core';

import LoadingBar from '$app-web/components/loading-bar';

import classes from './styles.module.scss';

const Loading = (props) => {
    const { t } = useTranslation();

    const {
        className,
        variant,
        backdropVariant,
        loading,
        error,
        errorText,
        showErrorText,
        errorHasButton,
        errorButtonText,
        onErrorButtonClick,
    } = props;

    const content = (
        <Grid className={classes.loadingBarWrapper} container alignItems="center" wrap="nowrap">
            <Grid item xs>
                {loading && <LoadingBar variant="loading" backdropVariant="dark" />}
                {error && !errorHasButton && <LoadingBar variant="error" errorText={errorText} showErrorText={showErrorText} />}
                {error && errorHasButton && (
                    <LoadingBar
                        variant="error-with-button"
                        errorText={errorText}
                        errorButtonText={errorButtonText || t('rdash:reload')}
                        onErrorButtonClick={onErrorButtonClick}
                        showErrorText={showErrorText}
                    />
                )}
            </Grid>
        </Grid>
    );

    const show = loading || error;
    const loadingModal = variant === 'modal' && (
        <Modal
            open={show}
            className={className}
            BackdropProps={{
                className: clsx({
                    [classes.backdropLight]: backdropVariant === 'light',
                    [classes.backdropDark]: backdropVariant === 'dark',
                }),
                style: { background: undefined }, // to disable default backdrop bgColor
            }}
        >
            {error ? (
                <div className={classes.errorWrapper}>
                    <div className={classes.error}>{content}</div>
                </div>
            ) : (
                content
            )}
        </Modal>
    );
    const loadingParentOverlay = variant === 'parent-overlay' && show && (
        <div
            className={clsx(
                classes.parentOverlay,
                {
                    [classes.backdropLight]: backdropVariant === 'light',
                    [classes.backdropDark]: backdropVariant === 'dark',
                },
                className
            )}
        >
            {content}
        </div>
    );
    const loadingInline = variant === 'inline' && (
        <div className={clsx({ [classes.hideInline]: !show }, className)}>{content}</div>
    );

    return loadingModal || loadingParentOverlay || loadingInline;
};

Loading.defaultProps = {
    className: undefined,
    variant: 'modal',
    loading: false,
    error: false,
    errorText: undefined,
    showErrorText: true,
    errorHasButton: false,
    errorButtonText: undefined,
    onErrorButtonClick: undefined,
    backdropVariant: 'light',
};

Loading.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(['modal', 'parent-overlay', 'inline']),
    backdropVariant: PropTypes.oneOf(['light', 'dark']),
    loading: PropTypes.bool,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    showErrorText: PropTypes.bool,
    errorHasButton: PropTypes.bool,
    errorButtonText: PropTypes.node,
    onErrorButtonClick: PropTypes.func,
};

export default Loading;
