import { END_OF_TIME_MS } from '$app-web/constants';

export const sessionInfo = {
    activity_id: 13848,
    device_id: 'W_DESKTOP_MICROSOFT EDGE',
    duration: null,
    expiry_time_ms: END_OF_TIME_MS,
    in_progress_info: {},
    p_time_ms: 0,
    r_time_ms: 0,
    s_time_ms: 1662549877087,
    session_uuid: '928dba63-cdaf-44d4-af02-2c36971097ab',
    status_id: 0,
    study_id: 2154,
    tl_id: 0,
    tl_session_index: -2,
    tl_type_id: 4,
    user_id: 39876,
    uuid: '928dba63-cdaf-44d4-af02-2c36971097ab',
    version: 1,
};

export const options = {
    audio_feedback: true,
    button_caption: '',
    button_icon: '',
    capture_location: false,
    criteria: '',
    desc: 'desc2',
    duration_ms: null,
    ending_text: '',
    endpoint: 'app/matrix_reasoning/',
    expiry_duration_ms: 0,
    id: 13848,
    intro_text: '',
    is_web_activity: true,
    main_rounds: 12,
    name: 'IQ2',
    per_round_duration_ms: 0,
    practice_rounds: 1,
    publish_note: null,
    published: true,
    questions: [
        {
            answer_count: 6,
            answer_images: [
                '/media/activities_resources/matrix_reasoning/img/A1a.png',
                '/media/activities_resources/matrix_reasoning/img/A1b.png',
                '/media/activities_resources/matrix_reasoning/img/A1c.png',
                '/media/activities_resources/matrix_reasoning/img/A1d.png',
                '/media/activities_resources/matrix_reasoning/img/A1e.png',
                '/media/activities_resources/matrix_reasoning/img/A1f.png',
            ],
            correct_answer: 3,
            name: 'A1',
            order: 0,
            question_image: '/media/activities_resources/matrix_reasoning/img/A1.png',
            question_inclusion: 'practice',
        },
        {
            answer_count: 6,
            answer_images: [
                '/media/activities_resources/matrix_reasoning/img/A2a.png',
                '/media/activities_resources/matrix_reasoning/img/A2b.png',
                '/media/activities_resources/matrix_reasoning/img/A2c.png',
                '/media/activities_resources/matrix_reasoning/img/A2d.png',
                '/media/activities_resources/matrix_reasoning/img/A2e.png',
                '/media/activities_resources/matrix_reasoning/img/A2f.png',
            ],
            correct_answer: 4,
            name: 'A2',
            order: 1,
            question_image: '/media/activities_resources/matrix_reasoning/img/A2.png',
            question_inclusion: 'main',
        },
        {
            answer_count: 6,
            answer_images: [
                '/media/activities_resources/matrix_reasoning/img/A11a.png',
                '/media/activities_resources/matrix_reasoning/img/A11b.png',
                '/media/activities_resources/matrix_reasoning/img/A11c.png',
                '/media/activities_resources/matrix_reasoning/img/A11d.png',
                '/media/activities_resources/matrix_reasoning/img/A11e.png',
                '/media/activities_resources/matrix_reasoning/img/A11f.png',
            ],
            correct_answer: 3,
            name: 'A11',
            order: 2,
            question_image: '/media/activities_resources/matrix_reasoning/img/A11.png',
            question_inclusion: 'main',
        },
        {
            answer_count: 6,
            answer_images: [
                '/media/activities_resources/matrix_reasoning/img/B7a.png',
                '/media/activities_resources/matrix_reasoning/img/B7b.png',
                '/media/activities_resources/matrix_reasoning/img/B7c.png',
                '/media/activities_resources/matrix_reasoning/img/B7d.png',
                '/media/activities_resources/matrix_reasoning/img/B7e.png',
                '/media/activities_resources/matrix_reasoning/img/B7f.png',
            ],
            correct_answer: 4,
            name: 'B7',
            order: 3,
            question_image: '/media/activities_resources/matrix_reasoning/img/B7.png',
            question_inclusion: 'main',
        },
        {
            answer_count: 6,
            answer_images: [
                '/media/activities_resources/matrix_reasoning/img/B12a.png',
                '/media/activities_resources/matrix_reasoning/img/B12b.png',
                '/media/activities_resources/matrix_reasoning/img/B12c.png',
                '/media/activities_resources/matrix_reasoning/img/B12d.png',
                '/media/activities_resources/matrix_reasoning/img/B12e.png',
                '/media/activities_resources/matrix_reasoning/img/B12f.png',
            ],
            correct_answer: 4,
            name: 'B12',
            order: 4,
            question_image: '/media/activities_resources/matrix_reasoning/img/B12.png',
            question_inclusion: 'main',
        },
        {
            answer_count: 8,
            answer_images: [
                '/media/activities_resources/matrix_reasoning/img/C4a.png',
                '/media/activities_resources/matrix_reasoning/img/C4b.png',
                '/media/activities_resources/matrix_reasoning/img/C4c.png',
                '/media/activities_resources/matrix_reasoning/img/C4d.png',
                '/media/activities_resources/matrix_reasoning/img/C4e.png',
                '/media/activities_resources/matrix_reasoning/img/C4f.png',
                '/media/activities_resources/matrix_reasoning/img/C4g.png',
                '/media/activities_resources/matrix_reasoning/img/C4h.png',
            ],
            correct_answer: 7,
            name: 'C4',
            order: 5,
            question_image: '/media/activities_resources/matrix_reasoning/img/C4.png',
            question_inclusion: 'main',
        },
        {
            answer_count: 8,
            answer_images: [
                '/media/activities_resources/matrix_reasoning/img/C12a.png',
                '/media/activities_resources/matrix_reasoning/img/C12b.png',
                '/media/activities_resources/matrix_reasoning/img/C12c.png',
                '/media/activities_resources/matrix_reasoning/img/C12d.png',
                '/media/activities_resources/matrix_reasoning/img/C12e.png',
                '/media/activities_resources/matrix_reasoning/img/C12f.png',
                '/media/activities_resources/matrix_reasoning/img/C12g.png',
                '/media/activities_resources/matrix_reasoning/img/C12h.png',
            ],
            correct_answer: 1,
            name: 'C12',
            order: 6,
            question_image: '/media/activities_resources/matrix_reasoning/img/C12.png',
            question_inclusion: 'main',
        },
        {
            answer_count: 8,
            answer_images: [
                '/media/activities_resources/matrix_reasoning/img/D7a.png',
                '/media/activities_resources/matrix_reasoning/img/D7b.png',
                '/media/activities_resources/matrix_reasoning/img/D7c.png',
                '/media/activities_resources/matrix_reasoning/img/D7d.png',
                '/media/activities_resources/matrix_reasoning/img/D7e.png',
                '/media/activities_resources/matrix_reasoning/img/D7f.png',
                '/media/activities_resources/matrix_reasoning/img/D7g.png',
                '/media/activities_resources/matrix_reasoning/img/D7h.png',
            ],
            correct_answer: 4,
            name: 'D7',
            order: 7,
            question_image: '/media/activities_resources/matrix_reasoning/img/D7.png',
            question_inclusion: 'main',
        },
        {
            answer_count: 8,
            answer_images: [
                '/media/activities_resources/matrix_reasoning/img/D12a.png',
                '/media/activities_resources/matrix_reasoning/img/D12b.png',
                '/media/activities_resources/matrix_reasoning/img/D12c.png',
                '/media/activities_resources/matrix_reasoning/img/D12d.png',
                '/media/activities_resources/matrix_reasoning/img/D12e.png',
                '/media/activities_resources/matrix_reasoning/img/D12f.png',
                '/media/activities_resources/matrix_reasoning/img/D12g.png',
                '/media/activities_resources/matrix_reasoning/img/D12h.png',
            ],
            correct_answer: 5,
            name: 'D12',
            order: 8,
            question_image: '/media/activities_resources/matrix_reasoning/img/D12.png',
            question_inclusion: 'main',
        },
        {
            answer_count: 8,
            answer_images: [
                '/media/activities_resources/matrix_reasoning/img/E1a.png',
                '/media/activities_resources/matrix_reasoning/img/E1b.png',
                '/media/activities_resources/matrix_reasoning/img/E1c.png',
                '/media/activities_resources/matrix_reasoning/img/E1d.png',
                '/media/activities_resources/matrix_reasoning/img/E1e.png',
                '/media/activities_resources/matrix_reasoning/img/E1f.png',
                '/media/activities_resources/matrix_reasoning/img/E1g.png',
                '/media/activities_resources/matrix_reasoning/img/E1h.png',
            ],
            correct_answer: 6,
            name: 'E1',
            order: 9,
            question_image: '/media/activities_resources/matrix_reasoning/img/E1.png',
            question_inclusion: 'main',
        },
        {
            answer_count: 8,
            answer_images: [
                '/media/activities_resources/matrix_reasoning/img/E5a.png',
                '/media/activities_resources/matrix_reasoning/img/E5b.png',
                '/media/activities_resources/matrix_reasoning/img/E5c.png',
                '/media/activities_resources/matrix_reasoning/img/E5d.png',
                '/media/activities_resources/matrix_reasoning/img/E5e.png',
                '/media/activities_resources/matrix_reasoning/img/E5f.png',
                '/media/activities_resources/matrix_reasoning/img/E5g.png',
                '/media/activities_resources/matrix_reasoning/img/E5h.png',
            ],
            correct_answer: 0,
            name: 'E5',
            order: 10,
            question_image: '/media/activities_resources/matrix_reasoning/img/E5.png',
            question_inclusion: 'main',
        },
        {
            answer_count: 8,
            answer_images: [
                '/media/activities_resources/matrix_reasoning/img/E7a.png',
                '/media/activities_resources/matrix_reasoning/img/E7b.png',
                '/media/activities_resources/matrix_reasoning/img/E7c.png',
                '/media/activities_resources/matrix_reasoning/img/E7d.png',
                '/media/activities_resources/matrix_reasoning/img/E7e.png',
                '/media/activities_resources/matrix_reasoning/img/E7f.png',
                '/media/activities_resources/matrix_reasoning/img/E7g.png',
                '/media/activities_resources/matrix_reasoning/img/E7h.png',
            ],
            correct_answer: 0,
            name: 'E7',
            order: 11,
            question_image: '/media/activities_resources/matrix_reasoning/img/E7.png',
            question_inclusion: 'main',
        },
        {
            answer_count: 8,
            answer_images: [
                '/media/activities_resources/matrix_reasoning/img/E10a.png',
                '/media/activities_resources/matrix_reasoning/img/E10b.png',
                '/media/activities_resources/matrix_reasoning/img/E10c.png',
                '/media/activities_resources/matrix_reasoning/img/E10d.png',
                '/media/activities_resources/matrix_reasoning/img/E10e.png',
                '/media/activities_resources/matrix_reasoning/img/E10f.png',
                '/media/activities_resources/matrix_reasoning/img/E10g.png',
                '/media/activities_resources/matrix_reasoning/img/E10h.png',
            ],
            correct_answer: 1,
            name: 'E10',
            order: 12,
            question_image: '/media/activities_resources/matrix_reasoning/img/E10.png',
            question_inclusion: 'main',
        },
    ],
    show_rounds: true,
    show_score: true,
    skip_round_behavior: 'disabled',
    status_id: 1,
    study_id: 2154,
    timed_out_round_behavior: 'not_answered',
    triggering_logics: [
        {
            id: 0,
            trigger_title: 'New Matric Reasoning Trigger',
            type: 'user',
        },
    ],
    type: 'matrix_reasoning',
    version: 1,
    visual_feedback: true,
};
