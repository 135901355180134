import produce from 'immer';
import { createSelector } from 'reselect';
import { getSelector, namespaceReducerActions } from 'retrack';

import { INITIALIZATION_STATUS } from '../constants';

export const DUCK_NAME = 'app';

const initialState = {
    initializationStatus: INITIALIZATION_STATUS.UNKNOWN,
};

export const duckActions = {
    INITIALIZE: 'INITIALIZE',
    SET_INITIALIZATION_STATUS: 'SET_INITIALIZATION_STATUS',
};

const reducer = produce((draft, action) => {
    switch (action.type) {
        case duckActions.SET_INITIALIZATION_STATUS:
            draft.initializationStatus = action.payload.status;
            break;
        // no default
    }
}, initialState);

namespaceReducerActions(reducer, duckActions);

export default reducer;

export const duckActionCreators = {
    initialize: function initialize() {
        return { type: duckActions.INITIALIZE };
    },
    initializationStarted: function initializationStarted() {
        return {
            type: duckActions.SET_INITIALIZATION_STATUS,
            payload: { status: INITIALIZATION_STATUS.STARTED },
        };
    },
    initializationSucceeded: function initializationSucceeded() {
        return {
            type: duckActions.SET_INITIALIZATION_STATUS,
            payload: { status: INITIALIZATION_STATUS.SUCCEEDED },
        };
    },
    initializationFailed: function initializationFailed() {
        return {
            type: duckActions.SET_INITIALIZATION_STATUS,
            payload: { status: INITIALIZATION_STATUS.FAILED },
        };
    },
};

const appSelector = getSelector(reducer);

const initializationStatusSelector = createSelector(appSelector, (appState) => appState.initializationStatus);

const initializationStatusSucceededSelector = createSelector(
    initializationStatusSelector,
    (initializationStatus) => initializationStatus === INITIALIZATION_STATUS.SUCCEEDED
);

const initializationStatusFailedSelector = createSelector(
    initializationStatusSelector,
    (initializationStatus) => initializationStatus === INITIALIZATION_STATUS.FAILED
);

export const duckSelectors = {
    appSelector,
    initializationStatusSucceededSelector,
    initializationStatusSelector,
    initializationStatusFailedSelector,
};
