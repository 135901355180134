import { createContext, useContext } from 'react';

const isDevMode = process.env.NODE_ENV !== 'production';

const EMPTY_OBJECT = {};

function createUseContext(context) {
    return () => {
        const value = useContext(context);
        if (isDevMode && value === EMPTY_OBJECT) {
            const warnMessage = context.displayName
                ? `The context consumer of ${context.displayName} must be wrapped with its corresponding Provider`
                : 'Component must be wrapped with Provider.';
            // eslint-disable-next-line no-console
            console.warn(warnMessage);
        }
        return value;
    };
}

export function contextStore(useValue, ...selectors) {
    const contexts = [];
    const hooks = [];

    const addContext = (contextName) => {
        const newContext = createContext(EMPTY_OBJECT);
        if (isDevMode && contextName !== '') {
            newContext.displayName = contextName;
        }
        contexts.push(newContext);
        hooks.push(createUseContext(newContext));
    };

    if (selectors.length > 0) {
        selectors.forEach((selector) => addContext(selector));
    } else {
        addContext(useValue.name);
    }

    const Provider = ({ children, ...props }) => {
        const store = useValue(props);

        return contexts.reduceRight((element, Context, index) => {
            const selector = selectors[index] ?? ((state) => state);

            return <Context.Provider value={selector(store)}>{element}</Context.Provider>;
        }, children);
    };

    return [Provider, ...hooks];
}
